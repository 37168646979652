import React, { Component } from "react";

import Program from "./program";
import Concert from "./concert";
import Arkiv from "./arkiv";

class ProgramOverblick extends Component {
  render() {
    let items = [];
    let program = new Program();
    let theProgram = program.getProgram();

    for (let i = 0; i < theProgram.length; i++) {
      let concert = new Concert(theProgram[i].props);
      items.push(concert.getOverview());
    }

    let dagensDatum = new Date();
    let date =
      dagensDatum.getDate() + " / " + parseInt(dagensDatum.getMonth() + 1);

    return (
      <center>
        <div className="info">
          <h1>Överblick {dagensDatum.getFullYear()}</h1>
          <br />
          <table className="programOverView">{items}</table>
          <br />
          <p>Idag är det {date}</p>
          <br />
          <br />
          <a
            style={{
              "font-size": "30px",
              border: "1px solid black",
              padding: "10px",
              "border-radius": "5px",
            }}
            href="/arkiv"
          >
            Tidigare år
          </a>
          <br />
          <br />
          <br />
        </div>
      </center>
    );
  }
}

class ArkivOverblick extends Component {
  render() {
    let items = [];
    let program = new Program();
    let theProgram = program.getProgram();

    for (let i = 0; i < theProgram.length; i++) {
      let concert = new Concert(theProgram[i].props);
      items.push(concert.getOverview());
    }

    let dagensDatum = new Date();
    let date =
      dagensDatum.getDate() + " / " + parseInt(dagensDatum.getMonth() + 1);

    return (
      <center>
        <p>Program {dagensDatum.getFullYear()}</p>
        <table className="programOverView">{items}</table>
        <br />
        <p>Idag är det: {date}</p>
        <br />
        <br />
        <br />
      </center>
    );
  }
}

export default ProgramOverblick;
