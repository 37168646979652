import React, { useState, useEffect, Fragment } from "react";
import { Container, ProgressBar, ScrollContent } from "./Styles";

const ScrollIndicator = () => {
  const [scroll, setScroll] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // New state to control visibility

  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrolled / maxHeight) * 100;

    if (scrollPercent > 7) {
      setScroll(scrollPercent);
      setIsVisible(true);
    } else if (scrollPercent <= 7) {
      setScroll(0);
    }

    // Clear existing timeout
    clearTimeout(window.fadeOutTimeout);

    // Set a new timeout
    window.fadeOutTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 700); // Start fade out after 1 second
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
      clearTimeout(window.fadeOutTimeout); // Clear timeout on unmount
    };
  }, []);

  return (
    <Fragment>
      <Container>
        <ProgressBar
          style={{ width: `${scroll}%` }}
          isVisible={isVisible}
        ></ProgressBar>
      </Container>
    </Fragment>
  );
};

export default ScrollIndicator;
