import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

class MenuBtn extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      isFetching: false,
      latest: null,
      navigation: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    // Toggle
    this.setState({
      navigation: !this.state.navigation,
    });
  }

  getBtn() {
    if (!this.state.navigation) {
      // Display the bars
      return (
        <svg
          onClick={this.handleClick}
          width="30px"
          viewBox="0 0 30 32"
          className="icon icon-bars image-hover-opacity"
          aria-hidden="false"
          role="img"
        >
          <path
            fill="black"
            className="path1"
            d="M27.429 24v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804zM27.429 14.857v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804zM27.429 5.714v2.286q0 0.464-0.339 0.804t-0.804 0.339h-25.143q-0.464 0-0.804-0.339t-0.339-0.804v-2.286q0-0.464 0.339-0.804t0.804-0.339h25.143q0.464 0 0.804 0.339t0.339 0.804z"
          ></path>
        </svg>
      );
    } else {
      // Display the close
      return (
        <svg
          onClick={this.handleClick}
          width="30px"
          viewBox="0 0 27 32"
          className="icon icon-close image-hover-opacity"
          aria-hidden="false"
          role="img"
        >
          <path
            fill="black"
            className="path1"
            d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z"
          ></path>
        </svg>
      );
    }
  }

  getNav() {
    if (this.state.navigation) {
      return (
        <div>
          <div className="nav-bar">
            <center>
              <table className="nav">
                <tbody>
                  <tr className="nav">
                    <td>
                      <Link to="/" onClick={this.handleClick}>
                        Program
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/overblick" onClick={this.handleClick}>
                        Överblick
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/arkiv" onClick={this.handleClick}>
                        Arkiv
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/kurser" onClick={this.handleClick}>
                        Kurser
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/besokare" onClick={this.handleClick}>
                        Besökare
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/hjalp" onClick={this.handleClick}>
                        Hjälpa till
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/lankar" onClick={this.handleClick}>
                        Länkar
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/hitta" onClick={this.handleClick}>
                        Hitta hit
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/kontakt" onClick={this.handleClick}>
                        Kontakt
                      </Link>
                    </td>
                  </tr>
                  <tr className="nav">
                    <td>
                      <Link to="/english" onClick={this.handleClick}>
                        English
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return (
      <div>
        <div className="menu-btn-section">
          {this.getBtn()}
          <span className="nav-btn-text">Meny</span>
        </div>
        <div className="menu-nav-section">
          <br />
          {this.getNav()}
        </div>
      </div>
    );
  }
}

export default MenuBtn;
