import React, { Component } from "react";

class Hjalpa extends Component {
  render() {
    return (
      <center>
        <center>
          <h1>Hjälpa till hos oss</h1>
        </center>
        <div
          className="info kompakt"
          style={{ textAlign: "left", width: "95%", maxWidth: "900px" }}
        >
          <center>
            <p>
              Vill du vara med i ett glatt gäng som i trivsam gemenskap formar
              verksamheten?
              <br /> Du är välkommen även om du är ny på Folkmusikkaféet.
            </p>
          </center>

          <center>
            <h2>Fredagskvällar</h2>
          </center>

          <p>
            Under fredagskvällarna behöver vi din hjälp! Du kan stödja
            föreningen genom att hjälpa till med:
          </p>

          <ul>
            <li>kaféet</li>
            <li>entrén</li>
            <li>stängning och städning</li>
          </ul>

          <p>
            Som tack <b>går du in gratis och får en gratis fika</b>. Prata med
            någon av funktionärerna på en kväll så lotsar de dig vidare, eller
            hör av dig till vår kaffégruppsansvarig på{" "}
            <a href="mailto:hjalptill@folkmusikkafeet.net">
              <img
                src="img/ikoner/epost-12.png"
                classNames="email"
                alt="e-post:"
              />{" "}
              hjalptill@folkmusikkafeet.net
            </a>
          </p>

          <p>
            <a href="medhjalpare">
              Information för medhjälpare på fredagskvällar.
            </a>
          </p>

          <center>
            <h2>Musiker och danslärare</h2>
          </center>

          <p>
            Vill du spela som pausmusiker en konsertkväll (c:a 30 min),
            inledande musiker en spel- och danskväll (60 min), till danskurs
            eller på konsert? Kan du hålla i en dansutlärning en fredag eller en
            hel helgdag? Vill du hålla i spelstuga, visstuga eller helgkurs? Har
            du andra idéer som du vill genomföra? Här är våra kontaktuppgifter:
          </p>

          <ul>
            <li>
              <b>Konsert</b>:{" "}
              <a href="mailto:artistbokare@folkmusikkafeet.net">
                <img
                  src="img/ikoner/epost-12.png"
                  classNames="email"
                  alt="e-post:"
                />{" "}
                artistbokare@folkmusikkafeet.net
              </a>
            </li>
            <li>
              <b>Paus- och introspelmän:</b> Lars-Gunnar Franzén,{" "}
              <a href="mailto:paus-intro@folkmusikkafeet.net">
                <img
                  src="img/ikoner/epost-12.png"
                  classNames="email"
                  alt="e-post:"
                />{" "}
                paus-intro@folkmusikkafeet.net
              </a>
            </li>
            <li>
              <b>Danskurser, dansstugor</b>:{" "}
              <a href="mailto:petrix@folkmusikkafeet.net">
                <img
                  src="img/ikoner/epost-12.png"
                  classNames="email"
                  alt="e-post:"
                />{" "}
                dans@folkmusikkafeet.net
              </a>
            </li>
            <li>
              <b>Spelkurser, spel- och visstugor</b>: Lars-Gunnar Franzén,{" "}
              <a href="mailto:spel-visstugor@folkmusikkafeet.net">
                <img
                  src="img/ikoner/epost-12.png"
                  classNames="email"
                  alt="e-post:"
                />{" "}
                spel-visstugor@folkmusikkafeet.net
              </a>
            </li>
            <li>
              <b>Övrigt:</b> Styrelsen,{" "}
              <a href="mailto:styrelsen@folkmusikkafeet.net">
                <img
                  src="img/ikoner/epost-12.png"
                  classNames="email"
                  alt="e-post:"
                />{" "}
                styrelsen@folkmusikkafeet.net
              </a>
            </li>
          </ul>

          <center>
            <h2>PR och hemsida</h2>
          </center>

          <p>
            Vill du hjälpa till att sprida information så att fler kan hitta
            till Folkmusikkaféet? Vi behöver hjälp med att affischera; göra
            annonser, affischer och flygblad; ta foton på Folkmusikkaféet som
            kan användas i framtida annonser, underhålla och utveckla hemsidan,
            och hitta på nya idéer.
            <br /> Är du intresserad, kontakta Samuel Lundh,{" "}
            <a href="mailto:ordforande@folkmusikkafeet.net">
              <img
                src="img/ikoner/epost-12.png"
                classNames="email"
                alt="e-post:"
              />{" "}
              ordforande@folkmusikkafeet.net
            </a>
            !
          </p>

          <p>
            Material: <a href="tryck">Trycksaker</a> &ndash; loggor, affischer
            osv.
          </p>

          <h2>Planering och styrelsearbete</h2>

          <p>
            Styrelsen väljs på årsmötet som vanligtvis infaller under första
            halvan av vårterminen. Nuvarande styrelsemedlemmar är listade i
            fliken <a href="kontakt">Kontakt</a>. Styrelsen har det övergripande
            ansvaret för att driva föreningens verksamhet, men behöver
            medlemmarnas hjälp för att det ska fungera. Styrelsen arbetar helt
            ideellt.
          </p>

          <p>Styrelsens arbetsdokument:</p>

          <ul>
            <li>
              <b>
                <a href="terminsschema">Terminsschema</a>
              </b>
              <br />
              Hur styrelsen planerar och organiserar en termin.
            </li>

            <li>
              <b>Regler för inträden</b>, rabatter, förmåner, fribiljetter,
              gratis fika och liknande:{" "}
              <a href="filer/formaner-8.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .<br /> Detta dokument innehåller samtliga rabatter. Ska du arbeta
              en fredagkväll? Då kan du använda de enklare dokumenten under
              länken "Information för medhjälpare på fredagkvällar" ovan.
            </li>

            <li>
              <b>
                <a href="https://spreadsheets3.google.com/ccc?key=tAunGZMSLLJeW358ssDeFYw">
                  Medlemsregister
                </a>
              </b>{" "}
              (kräver inloggning)
            </li>

            <li>
              <b>Styrelsevärd:</b>{" "}
              <a href="filer/styrelsevard_2022.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              ,{" "}
              <a href="filer/styrelsevard_2022.doc">
                <img src="img/ikoner/doc.png" classNames="doc" alt="doc:" />{" "}
                Word
              </a>
            </li>
          </ul>

          <p>
            Vill du veta hur du ska göra för att läsa dokumenten? Se{" "}
            <a href="google-docs-instruktioner">dessa instruktioner</a>
          </p>

          <center>
            <h2>Om föreningen</h2>
          </center>

          <p>
            Folkmusikkaféet drivs helt ideellt av Föreningen Folkmusikkaféet. Vi
            verkar för att skapa en mötesplats för levande folkmusik och dans i
            Göteborg.
          </p>

          <p>
            Information finns även i en artikel i Hus och Livsstil nr 2
            2016/2017 som kan läsas här:{" "}
            <a href="filer/fmk_hus_och_livsstil_2016.pdf">
              <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
            </a>
          </p>

          <p>
            <b>Föreningens dokument:</b>
          </p>
          <ul>
            <li>
              <big>
                <b>Stadgar:</b>
              </big>{" "}
              <a href="filer/2024/folkmusikkafeet_stadgar_2024.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              ,{" "}
              <a href="filer/2024/folkmusikkafeet_stadgar_2024.doc">
                <img src="img/ikoner/odt.png" classNames="odt" alt="odt:" />{" "}
                Word
              </a>
              .
            </li>
            <li>
              <big>
                <b>Ungdomsstrategi 2022:</b>
              </big>{" "}
              <a href="filer/FMK_VP_Ungdomsstrategi_2022.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2011:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2011.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2012:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2012.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2013:</b>
              </big>{" "}
              <a href="filer/Verksamhetsberattelse_2013.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2014:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2014.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2015:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2015.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2016:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2016.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2017:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2017.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2018:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2018.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2019:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2019.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2020:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2020.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2021:</b>
              </big>{" "}
              <a href="filer/verksamhetsberattelse_2021.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2022:</b>
              </big>{" "}
              <a href="filer/2024/FMK_VB_aret_2022.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Verksamhetsberättelse 2023:</b>
              </big>{" "}
              <a href="filer/2024/Verksamhetsberattelse_for_aret_2023.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Årsmötesprotokoll 2024:</b>
              </big>{" "}
              <a href="filer/2024/arsmote_20240301_folkmusikkafeet.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
            <li>
              <big>
                <b>Integritetspolicy:</b>
              </big>{" "}
              <a href="filer/integritetspolicy_fmk.pdf">
                <img src="img/ikoner/pdf.png" classNames="pdf" alt="pdf:" /> PDF
              </a>
              .
            </li>
          </ul>

          <p>
            Folkmusikkaféet stöds av Statens Kulturråd och Kultur Göteborg samt
            av Kulturens bildningsverksamhet.
          </p>
        </div>
        <br />
        <br />
      </center>
    );
  }
}

export default Hjalpa;
