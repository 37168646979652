import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

class Doc extends React.Component {
  componentDidMount() {
    document.title = "Folkmusikkafeet i Göteborg";
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Folkmusikkafeet i Göteborg</title>
          <meta
            name="description"
            content="Välkommen till folkmusikkaféet i Göteborg. En möteplats för levande folkmusik och dans i Göteborg - folkmusik och dans varje fredag."
          />
        </Helmet>

        <App />
      </>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Doc />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
