import React from "react";
import Program from "./components/program.jsx";
import MenuBtn from "./components/menubtn.jsx";
import Besokare from "./components/besokare.jsx";
import Kontakt from "./components/kontakt.jsx";
import English from "./components/english.jsx";
import Hitta from "./components/hitta.jsx";
import Links from "./components/links.jsx";
import Hjalpa from "./components/hjalpa.jsx";
import Medhjalpa from "./components/medhjalpare.jsx";
import TerminSchema from "./components/terminschema.jsx";
import ProgramOverblick from "./components/program-overblick.jsx";
import Botten from "./components/botten.jsx";
import Arkiv from "./components/arkiv.jsx";
import Kurser from "./components/kurser.jsx";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

const greetImg = (
  <a href="/">
    <img src="img/FMK-1536x658.png" width="100%" />
  </a>
);
export default function App() {
  return (
    <div>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400&display=swap"
        rel="stylesheet"
      />

      <Router>
        <div className="custom-header-media">
          <div id="wp-custom-header" className="wp-custom-header">
            {greetImg}
          </div>
        </div>
        <div id="header-nav-area">
          <center className="wrapper">
            <MenuBtn></MenuBtn>
          </center>
        </div>

        <Switch>
          <Route path="/program">
            <Program />
          </Route>
          <Route path="/kurser">
            <Kurser />
          </Route>
          <Route path="/besokare">
            <Besokare />
          </Route>
          <Route path="/overblick">
            <ProgramOverblick />
          </Route>
          <Route path="/arkiv">
            <Arkiv />
          </Route>
          <Route path="/hjalp">
            <Hjalpa />
          </Route>
          <Route path="/lankar">
            <Links />
          </Route>
          <Route path="/hitta">
            <Hitta />
          </Route>
          <Route path="/kontakt">
            <Kontakt />
          </Route>
          <Route path="/english">
            <English />
          </Route>
          <Route path="/medhjalpare">
            <Medhjalpa />
          </Route>
          <Route path="/terminsschema">
            <TerminSchema />
          </Route>
          <Route path="/">
            <Program />
          </Route>
        </Switch>
      </Router>
      <Botten />
    </div>
  );
}
