import React, { Component } from "react";

class TerminSchema extends Component {
  render() {
    return (
      <center>
        <div className="info" style={{ textAlign: "left" }}>
          <h1 style={{ textAlign: "center" }}>Terminsschema</h1>

          <p>
            Här är ett översiktligt schema som visar hur styrelsen planerar en
            termin.
          </p>

          <ol>
            <li>
              Artistbokaren bestämmer när terminen ska börja och sluta och om
              det ska vara något uppehåll (vanligtvis är det bara uppehåll på
              röda dagar, alltså påsk och ibland 1 maj). På vårterminen är det
              alltid årsmöte fredagen vecka 9 så då bör det vara spel- och
              danskväll.
            </li>

            <li>
              Artistbokaren bokar alla artister. Detta pågår ofta ett tag, med
              flera preliminärbokningar som bollas med styrelsen. Artisterna
              spikas med kontrakt och artistbokaren gör en budget med entrépris,
              förväntat besöksantal, övriga utgifter och intäkter. I och med
              detta är det klart vilka kvällar som är spel- och dans och vilka
              som är artistkvällar.
            </li>

            <li>
              Boka följande (allt detta kan göras parallellt men måste göras
              efter punkt 2):
              <ol>
                <li>Inledande musiker på spel- och danskvällar</li>
                <li>Pausmusiker på artistkvällar</li>
                <li>Drop-in-dansutlärning</li>
                <li>Eventuell lång danskurs</li>
                <li>Spelstugor</li>
                <li>Visstugor</li>
                <li>Tillsammansgruppen</li>
                <li>Nyckelmän</li>
                <li>Eventuella helgkurser</li>
              </ol>
              Nyckelmannaansvarige bokar nyckelmän. PR-ansvarige{" "}
              <i>koordinerar</i> allt det andra, men själva <i>bokningen</i> av
              musiker, kurser, stugor och nyckelmän görs av den styrelseledamot
              som är ansvarig för respektive område.
            </li>

            <li>
              PR-ansvarige skaffar material (beskrivande text, snygg bild, övrig
              information som ifall gruppen säljer skivor osv) från alla
              artister och alla som inleder spel- och danskväll.
            </li>

            <li>
              PR-ansvarige sammanställer allt material till det tryckta
              programmet och till hemsidan, samt skickar programmet till
              tryckning.
            </li>

            <li>
              Medlemsansvarige skickar adresser till tryckeriet så att de kan
              trycka etiketter.
            </li>

            <li>
              Ordförande skriver ett brev till medlemmarna och styrelsen
              kontrolläser brevet.
            </li>

            <li>
              Ordförande skriver ut sitt brev i ca 400-500 ex (till alla
              medlemmar som vi har adress till.)
            </li>

            <li>PR-ansvarige ser till att programmet hämtas från tryckning.</li>

            <li>
              PR-ansvarige ser till att programmet + ordförandes brev läggs i
              kuvert. Oftast träffas styrelsen med vänner och gör detta
              tillsammans.
            </li>

            <li>
              PR-ansvarige ser till att kuverten skickas till medlemmarna, samt
              att någon lägger program på bibliotek, anslagstavlor, kaféer osv.
              Skicka program till Västgötaspelmannen, som tar med programmet i
              sitt första nummer för terminen, samt till TILLT där vi är
              medlemsorganisation.
            </li>

            <li>
              Under terminen skaffar PR-ansvarige affischer från artisterna och
              tar med ca två veckor innan varje kväll. Varje kväll annonseras i
              kalendarierna i t.ex. GP, Göteborgs Fria tidning, Spionen,
              Folklorecentrum, Folkmusik och Dans, Hembygden, goteborg.com,
              Spelmannen, Folkes kalendarium i P2, Aroshambon, Sveriges spelmäns
              riksförbunds hemsida.
            </li>
          </ol>
        </div>
      </center>
    );
  }
}

export default TerminSchema;
