import styled from "styled-components";

export const Container = styled.div`
  background-color: transparent;
  height: 7px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 10px;
`;

export const ProgressBar = styled.div`
  height: 10px;
  background-color: white;
  border-radius: 10px;
  transition: opacity 1s ease-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const ScrollContent = styled.div`
  overflowy: scroll;
  height: 2000px;
`;
