import React, { Component } from "react";

class Hitta extends Component {
  render() {
    return (
      <center>
        <center>
          <h1>Hitta hit</h1>
        </center>
        <br />
        <div className="info hitta">
          <br />
          <center>
            <img
              src="img/1-500.jpg"
              className="rundad"
              style={{ maxWidth: 534, width: "95%" }}
            />
            <br />
            <br />
            <h2>Öppettider</h2>
            Fredagar kl 19:00&ndash;23:30. Se vårt{" "}
            <a href="/program">program</a> för att se vilka kvällar som gäller.
            <h2>Kollektivtrafik:</h2>
            Ta spårvagnen eller bussen till Järntorget. Se{" "}
            <a href="http://www.vasttrafik.se">Västtrafiks hemsida</a>. Från
            Järntorget följer du Södra Allégatan österut och efter 200 meter ser
            du Allégården på höger sida.
            <br />
            <br />
            <h2>Parkeringar:</h2>
            <br />
            Det går inte att parkera utanför Folkmusikkaféet, men det finns
            några betalparkeringar i närheten. Se parkeringskarta på{" "}
            <a href="http://g.co/maps/4e6u2">Google maps</a> eller{" "}
            <a href="http://kartor.eniro.se/m/9CNkF">Eniro</a>.<h2>Karta:</h2>
            <table>
              <tr>
                <td style={{ fontWeight: "normal", textAlign: "left" }}>
                  <a href="http://kartor.eniro.se/m/9CNAa">
                    <img
                      src="img/karta-eniro-150.jpg"
                      style={{ width: "150px", height: "150px" }}
                    />
                    <br />
                    eniro
                  </a>
                </td>
                <td>
                  <a href="http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Folkmusikkaf%C3%A9et&sll=57.699634,11.957209&sspn=0.009551,0.033023&ie=UTF8&t=h&z=16&iwloc=A">
                    <img
                      src="img/karta-google-150.jpg"
                      style={{ width: "150px", height: "150px" }}
                    />
                    <br />
                    google
                  </a>
                </td>
              </tr>
            </table>
            <h2>Postadress:</h2>
            Folkmusikkaféet
            <br />
            Allégården
            <br />
            Södra Allégatan 4<br />
            413 01 Göteborg
            <h2>GPS-koordinater:</h2>
            Lat N 57&deg; 41&rsquo; 58&rdquo; Lon E 11&deg; 57&rsquo; 25&rdquo;
          </center>
          <br />
          <br />
          <br />
        </div>
      </center>
    );
  }
}

export default Hitta;
