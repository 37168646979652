import React, { Component } from "react";

class Kurser extends Component {
  render() {
    return (
<center>
  <div className="info" style={{ "text-align": "left" }}>
    <center>
      <h1>Kurser på folkmusikkaféet<br/>Våren 2025</h1>
    </center>
    <br />

    
    <center>
      <h3>Spelmanslaget</h3>
      <img
        className="kursbild-mindre fade-in-fast"
        style={{ "padding-right": "40px" }}
        src="img/arkiv/jon_olov.jpg"
      />
      <img
        className="kursbild-mindre fade-in-fast"
        src="img/arkiv/dan_palmqvist_small.jpg"
      />
    </center>
    <center>
      <div className="kompakt">
        Kursen hålls fredagar kl 17:30 - 19:15 med start 17 januari och omfattar hela våren och alla med akustiska instrument är välkomna. Vi ser gärna att du har något eller några års erfarenhet på ditt instrument. Som kompmusiker ser vi helst att du är relativt självständig och kan lite grunder i folkmusik.
        <br /><br />
        Kursinstruktör: Jon-Olov Woxlin och Dan Palmqvist <br />
        <br />
        Kostnad: 600 kr (ung & stud. 350 kr) betalas in på pg 107741-1 eller Swish 1236085450. Ange namn + ”spelmanslaget”.<br />
        <br />
        <small>För mer information: Dan Palmqvist, dan.palmqst@gmail.com</small>
      </div>
    </center>
    <br />

    
    <center>
      <h3>Nybörjarkurs i dans till folkmusik</h3>
    </center>
    <center>
      <small>Sex fredagskvällar med start 24/1 och avslutning 28/2.</small>
      <br /><br />
      <div className="kompakt">
        Kursen ger grunderna i de vanligast förekommande danserna på Folkmusikkaféet och andra liknande folkmusik- och dansställen i Sverige och utomlands. I första hand pardanserna schottis, polska, slängpolska och vals samt gruppdansen engelska – men även andra inslag kan förekomma.<br /><br />
        Medtag skor som har sulor ”med glid”.<br /><br />
        Kursinstruktörer: Mats Nilsson och Ingegerd Sigfridsson <br />
        Spelman: Staffan Folestad<br /><br />
        Tider: Fredagskvällar 18:00 – 19:30.<br />
        Kursavgift: 600 kr (ung &amp; stud 300 kr).<br /><br />
        Kursen arrangeras i samarbete med Bilda. <br/><br/><b>Länk till anmälan:</b> <a href="https://www.bilda.nu/arr/1239276/nyborjarkurs-i-dans-till-folkmusik-folkmusikkafeet-allegarden-924271">https://www.bilda.nu/arr/1239276/nyborjarkurs-i-dans-till-folkmusik-folkmusikkafeet-allegarden-924271</a><br/><br/>        Frågor: Mats Nilsson, tel. 070-5577916 eller <a href="mailto:kurser@folkmusikkafeet.se">kurser@folkmusikkafeet.se</a>
      </div>
    </center>
    <br />

    
    <center>
      <h3>Fortsättningskurs för nybörjare i dans till folkmusik</h3>
    </center>
    <center>
      <small>Fem fredagskvällar med start 14/3 och avslutning 11/4.</small>
      <br /><br />
      <div className="kompakt">
        Danskurs för er som provat grunderna i polskedans och annan dans till folkmusik. Här får ni upptäcka mer av mångfalden polskor och andra bygdedanser.<br /><br />
        Medtag skor som har sulor ”med glid”.<br /><br />
        Kursinstruktörer: Ingela och Håkan Lindberg <br />
        Spelman: Björn Johnsson<br /><br />
        Tider: Fredagskvällar 18:00 – 19:30.<br />
        Kursavgift: 500 kr (ung &amp; stud 250 kr).<br /><br />
        Kursen arrangeras i samarbete med Bilda. 
<br/><br/><b>Länk till anmälan:</b> <a href="https://www.bilda.nu/arr/1239277/forsattningskurs-dans-till-folkmusik-folkmusikkafeet-allegarden-924272">https://www.bilda.nu/arr/1239277/forsattningskurs-dans-till-folkmusik-folkmusikkafeet-allegarden-924272</a><br/><br/> 

        Frågor: Håkan Lindberg, tel. 0767-133250 eller <a href="mailto:kurser@folkmusikkafeet.se">kurser@folkmusikkafeet.se</a>
      </div>
    </center>
    <br />

    
    <center>
      <h3>Tillsammansgruppen</h3>
      <img
        className="kursbild fade-in-fast"
        src="img/tillsammansgruppen.jpg"
      />
    </center>
    <center>
      <div className="kompakt">
        Genom alla tider har vi lekt på fest och till vardags, barn som vuxen. Tillsammansgruppen ger dig möjlighet att ta del av denna roliga tradition.<br />
        Stora som små är varmt välkomna att hänga med i vindlande långdanser och visor.<br />
        Det är motion för både kropp som knopp!<br /><br />
        Tider: 18:00 – 19:30 med tid för fika.<br />
        Datum: 17/1, 21/2 och 28/3<br />
        Instruktörer: Gunnel Sjögren och Gunilla Sellersjö<br /><br />
        Kostnad: Gratis för barn i sällskap med någon vuxen.
      </div>
    </center>
    <br /><br />

    <center>
      <small>Folkmusikkaféet samarbetar med och stöds av:</small>
    </center>
  </div>
</center>
    );
  }
}

export default Kurser;
