import React, { Component } from "react";
import parse from "html-react-parser";
import "./concert.css";

/* Controls wether or not to do the date-update thing */
let doUpdate = true;

class Concert extends Component {
  styles = {
    fontSize: 15,
    fontWeight: "bold",
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      latest: null,
    };
  }

  getDate() {
    if (!this.props.date) {
      /* If the date isn't specified, we just display it */
      return "";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
    var dateString = this.props.date.split("-");
    var month = parseInt(dateString[1]);

    return parseInt(dateString[2]) + " " + monthNames[month - 1];
  }

  getDateDay() {
    if (!this.props.date) {
      /* If the date isn't specified, we just display it */
      return "";
    }
    var dateString = this.props.date.split("-");
    var date = dateString[1];
    if (date[0] == "0") {
      date = date[1];
    }

    var day = parseInt(dateString[2]);

    return <p>{day + " / " + date}</p>;
  }

  getYear() {
    if (!this.props.date) {
      /* If the date isn't specified, we just display it */
      return "";
    }
    var dateString = this.props.date.split("-");
    var year = dateString[0];
    return <p>{year}</p>;
  }

  getCost() {
    if (!this.props.cost || this.props.cost == 0) {
      /* If the cost isn't specified or set to zero, specify */
      return "frivillig entréavgift";
    } else if (this.props.prebook) {
      return (
        <div>
          {this.props.cost + " / " + this.props.costYoung + " kr"}
          <br />
          {
            <a
              target="_blank"
              style={{ color: "blue" }}
              href={this.props.prebook}
            >
              OBS Förköp
            </a>
          }
        </div>
      );
    } else {
      return this.props.cost + " / " + this.props.costYoung + " kr";
    }
  }

  getTime() {
    if (!this.props.time) {
      /* If the cost isn't specified or set to zero, specify */
      return "";
    }

    return "kl " + this.props.time;
  }

  getImg() {
    if (!this.props.img) {
      /* If the cost isn't specified or set to zero, specify */
      return "";
    } else {
      return this.props.img;
    }
  }

  getImgTwo() {
    if (!this.props.imgTwo) {
      /* If the cost isn't specified or set to zero, specify */
      return "";
    } else {
      return this.props.imgTwo;
    }
  }

  getOverview() {
    if (this.props.canceled) {
      return (
        <tr style={{ height: "150px" }}>
          <td class="overviewTitle">
            <strike>{this.props.title}</strike>
            <br />
            {this.getDateDay()}
          </td>
          <td>Inställt</td>
        </tr>
      );
    }

    if (this.props.imgTwo) {
      return (
        <tr style={{ height: "150px" }}>
          <td class="overviewTitle">
            {this.props.title}
            <br />
            {this.getDateDay()}
          </td>
          <td>
            <img style={{ height: "75px" }} src={this.getImg()} />
            <img style={{ height: "75px" }} src={this.getImgTwo()} />
          </td>
        </tr>
      );
    }

    return (
      <tr style={{ height: "150px" }}>
        <td class="overviewTitle">
          {this.props.title}
          <br />
          {this.getDateDay()}
        </td>
        <td>
          <img style={{ height: "150px" }} src={this.getImg()} />
        </td>
      </tr>
    );
  }

  getArchiveOverview() {
    if (this.props.canceled) {
      return (
        <tr style={{ height: "100px" }}>
          <td>
            <center>
              <div className="archiveOverviewTable">
                {this.getYear()}
                {this.getDateDay()}
                <strike>{this.props.title}</strike>
              </div>
              <p>Inställt</p>
              <br />
              <br />
              <div className="archiveChild">{this.props.children}</div>
              <div className="padBottom" />
            </center>
          </td>
        </tr>
      );
    }

    if (this.props.imgTwo) {
      return (
        <tr style={{ height: "100px" }}>
          <td>
            <center>
              <div className="archiveOverviewTable">
                {this.getYear()}
                {this.getDateDay()}
                {this.props.title}
              </div>
              <br />
              <br />
              <img className="archiveImageDouble" src={this.getImg()} />
              <img className="archiveImageDouble" src={this.getImgTwo()} />
              <br />
              <div className="archiveChild">{this.props.children}</div>
              <div className="padBottom" />
            </center>
          </td>
        </tr>
      );
    }

    return (
      <tr style={{ height: "100px" }}>
        <td>
          <center>
            <div className="archiveOverviewTable">
              {this.getYear()}
              {this.getDateDay()}
              {this.props.title}
            </div>
            <br />
            <br />
            <img className="archiveImage" src={this.getImg()} />
            <div className="archiveChild">
              <br />
              {this.props.children}
            </div>
            <div className="padBottom" />
          </center>
        </td>
      </tr>
    );
  }

  consertEntry() {
    let img = "";
    let imageClasses = "image-hover-opacity ";
    let imageStyles = {};

    if (this.props.imgTwo) {
      imageClasses += "pImgTwo ";
    } else {
      imageClasses += "pImg ";
    }

    if (this.props.imgWidth) {
      imageStyles["width"] = this.props.imgWidth;
    }

    if (this.props.imgMaxWidth) {
      imageStyles["max-width"] = this.props.imgMaxWidth;
    }

    if (this.props.imgLink) {
      /* The image also has a link */
      img = (
        <a href={this.props.imgLink} target="_blank">
          <img
            className={imageClasses}
            src={this.getImg()}
            style={imageStyles}
          ></img>
        </a>
      );
    } else {
      img = (
        <img
          className={imageClasses}
          style={imageStyles}
          src={this.getImg()}
        ></img>
      );
    }

    if (this.props.imgCaption) {
      img = (
        <table style={{ textAlign: "center" }}>
          <tbody>
            <tr>
              <td>{img}</td>
            </tr>
            <tr>
              <td>
                <i>{this.props.imgCaption}</i>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (this.props.imgTwo) {
      if (this.props.imgTwoLink) {
        /* The image also has a link */
        img = (
          <center>
            <table className="program-tabell-bilder">
              <tbody>
                <tr>
                  <td>{img}</td>
                  <td>
                    <table style={{ textAlign: "center" }}>
                      <tbody>
                        <tr>
                          <td>
                            <a href={this.props.imgTwoLink} target="_blank">
                              <img
                                className={imageClasses}
                                style={imageStyles}
                                src={this.getImgTwo()}
                              ></img>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i>{this.props.imgTwoCaption}</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </center>
        );
      } else {
        img = (
          <center>
            <table className="program-tabell-bilder">
              <tbody>
                <tr>
                  <td>{img}</td>
                  <td>
                    <table style={{ textAlign: "center" }}>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className={imageClasses}
                              style={imageStyles}
                              src={this.getImgTwo()}
                            ></img>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i>{this.props.imgTwoCaption}</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        );
      }
    }

    return (
      <div className="pEntry">
        <div className="padBottomLight" />
        <div>{img}</div>
        <div className="pHead">
          <center>
            <div className="pTitle">{this.props.title}</div>
          </center>
          <center>
            <div className="pTime">
              {this.getDate()} {this.getTime()}
            </div>
          </center>
          <center>
            <div className="pCost">{this.getCost()}</div>
          </center>
        </div>
        <center>
          <div className="pBody">{this.props.children}</div>
        </center>
      </div>
    );
  }

  dateOK() {
    if (!doUpdate) {
      return true;
    }

    if (!this.props.date) {
      /* If the date isn't specified, we just display it */
      return true;
    }
    var dateString = this.props.date.split("-");
    dateString = dateString.join("");
    var dateInt = parseInt(dateString);

    /* Get year, month and day and compare */
    var now = new Date();
    var month = now.getUTCMonth() + 1; //months from 1-12
    var day = now.getUTCDate();
    var year = now.getUTCFullYear();

    var nowInt = year * 10000 + month * 100 + day;

    if (nowInt > dateInt) {
      /* Event has passed */
      return false;
    }

    return true;
  }

  render() {
    if (!doUpdate) {
      return this.consertEntry();
    }

    if (this.dateOK()) {
      return this.consertEntry();
    } else {
      /* Render nothing, the date has passed */
      return "";
    }
  }
}

export default Concert;
