import React, { Component } from "react";

class Links extends Component {
  render() {
    return (
      <center>
        <center>
          <h1>Länkar</h1>
        </center>
        <div className="info">
          <center>
            <h2>Mer information</h2>
          </center>

          <table className="links-table">
            <tbody>
              <tr>
                <td>
                  <a href="http://www.facebook.com/group.php?gid=8383637763">
                    Folkmusik Göteborg
                  </a>
                </td>
                <td>Facebook-gruppen för folkmusik i Göteborg.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.facebook.com/pages/Folkdans/10150154840825328">
                    {" "}
                    Folkdans
                  </a>
                </td>
                <td>Facebook-gruppen för folkdans i Sverige.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.facebook.com/pages/Folkmusiker-mot-fr%C3%A4mlingsfientlighet/153536448015133">
                    {" "}
                    Folkmusiker mot främlingsfientlighet
                  </a>
                </td>
                <td>
                  Facebook-gruppen för folkmusiker mot främlingsfientlighet.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.algonet.se/~jan_ake/">Aroshambon</a>
                </td>
                <td>
                  Folkdanshemsida med kalendarium för hela landet men tyngdpunkt
                  omkring Västmanland.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.folkdansringen.se/goteborg">
                    Folkdansringen Göteborg
                  </a>
                </td>
                <td>Paraplyorganisation för flera folkdanslag i Göteborg.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.rfod.se">
                    Riksförbundet för Folkmusik och Dans, RFoD
                  </a>
                </td>
                <td>
                  Paraplyorganisation för folkmusik och dans i Sverige.
                  Folkmusikkaféet är medlemmar i RFoD.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.spelmansforbund.org">
                    Sveriges Spelmäns Riksförbund
                  </a>
                </td>
                <td>Paraplyorganisation för folkmusik i Sverige.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.hsm.gu.se/">
                    Världsmusiklinjen, Högskolan för scen och musik
                  </a>
                </td>
                <td>
                  Göteborgs universitets utbildning för folk- och världsmusiker,
                  som Folkmusikkaféet ofta och gärna samarbetar med.
                </td>
              </tr>
            </tbody>
          </table>

          <center>
            <h2>Folkmusik och dans i Göteborg</h2>
          </center>

          <table className="links-table">
            <tbody>
              <tr>
                <td>
                  <a href="http://www.klubbankaret.se/">Klubb Ankaret</a>
                </td>
                <td>
                  Göteborgs folk- och världsmusikklubb. Konserter onsdagar i
                  udda veckor.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.facebook.com/folkmusikpub">
                    {" "}
                    Folkmusikpub
                  </a>
                </td>
                <td>
                  Folkmusikjam på puben{" "}
                  <a href="http://www.haketpub.se/">Haket</a> vid
                  Masthuggstorget i Göteborg.{" "}
                  <a href="http://kartor.eniro.se/m/a0RGL">Karta</a>
                </td>
              </tr>

              <tr>
                <td>
                  <a href="https://linktr.ee/vastkustfolk">
                    {" "}
                    Studentspelmanslaget Västkustfolk
                  </a>
                </td>
                <td>
                  Göteborgs studentspelmanslag som ofta syns och hörs på
                  Folkmusikkaféet.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://balkandansarna.se/">Balkandansarna</a>
                </td>
                <td>
                  Internationell dansklubb som tränar på måndagkvällar på
                  Gräfsnäsgården
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://bg-spelmanslag.se/">
                    Bagaregårdens spelmanslag
                  </a>
                </td>
                <td>Spelmanslag i östra Göteborg.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.polskedanslaget.se/">Polskedanslaget</a>
                </td>
                <td>
                  Polsketräning onsdagar kl 19:00 i Gräfsnäsgårdens annex.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://hisingensspelmanslag.wordpress.com">
                    Hisingens spelmanslag
                  </a>
                </td>
                <td>
                  Spelmanslag med träning på onsdagar i Gamla Kyrkbyskolan på
                  Hisingen.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.facebook.com/pages/Queerpolska-med-Ellinor-Fritz-Erik-Mägi/113438988742339">
                    {" "}
                    Queer-polska med Ellinor Fritz och Erik Mägi
                  </a>
                </td>
                <td>
                  Ellinor Fritz &amp; Erik Mägi lär ofta ut Queerpolska &ndash;
                  polska med rörliga roller &ndash; såväl på Folkmusikkaféet som
                  på festivaler.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://gnds.org/">
                    Göteborgs National Danssällskap - GNDS
                  </a>
                </td>
                <td>
                  Folkdanslag med träning varje torsdag på Gräfsnäsgården.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.naverluren.se/">
                    Folkdanslaget Näverluren
                  </a>
                </td>
                <td>
                  Folkdanslag med dansutlärning på Allégården på torsdagar. Har
                  även andra aktiviteter som fiolkurs och slöjd.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.skjortorochsarkar.se/">
                    Polskesnurren Skjortor och Särkar
                  </a>
                </td>
                <td>
                  Polsketräning onsdagar jämna veckor kl 19:00 i Gräfsnäsgården,
                  lilla salen.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.bergersjo.com/National/sv_main.jsp">
                    Folkdanssällskapet National
                  </a>
                </td>
                <td>Folkdanslag med fredagsträningar på Gräfsnäsgården.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://hem.bredband.net/fdsvictoria/">
                    Folkdanssällskapet Victoria
                  </a>
                </td>
                <td>
                  Folkdanslag med träning varje onsdag i stora salen i
                  Gräfsnäsgården.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://heimdals.com/">Heimdals bygdegille</a>
                </td>
                <td>Folkdanslag med träning på fredagar i Nordengården.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.folkdansringen.se/letsegarden/">
                    Letsegårdens Hembygdsgille
                  </a>
                </td>
                <td>
                  Folkdanslag med träning på fredagar i Gamla Kyrkbyskolan på
                  Hisingen.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.gfv.se/">Göteborgs Folkdansvänner</a>
                </td>
                <td>
                  Folkdansförening med träning på fredagar på Annedalsgården.
                </td>
              </tr>

              <tr>
                <td>Folkdanslaget Västanvind</td>
                <td>Folkdanslag med träning på tisdagar på Gräfsnäsgården.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://allmogegillet.net/">
                    Folkdanslaget Allmogegillet Göteborg
                  </a>
                </td>
                <td>Folkdanslag som dansar på Gräfsnäsgården på måndagar.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://mellgrensblandning.se/">
                    Mellgrens blandning
                  </a>
                </td>
                <td>
                  Folkdans- och folkmusikgrupp i Göteborg som bl.a. erbjuder
                  uppvisningar.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.obygdens-polskevanner.se">
                    O-bygdens polskevänner
                  </a>
                </td>
                <td>
                  Polsketräning varannan torsdag i Fräntorps Folkets Hus. På
                  hemsidan finns ett antal dansvideos väl värda att titta på.
                </td>
              </tr>
            </tbody>
          </table>

          <center>
            <h2>Föreningar nära Göteborg</h2>
          </center>

          <table className="links-table">
            <tbody>
              <tr>
                <td>Lysekils folkdansgille</td>
                <td>
                  Folkdanslag med träning på tisdagar i Ålevik utanför Lysekil.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.borasspelman.se/">Borås spelmanslag</a>
                </td>
                <td>
                  Folkdans- och spelmanslag i Borås. Arrangerar även
                  Spelmansstämman i Borås.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.folkdansringen.se/boras/">
                    Borås hembygdsgille
                  </a>
                </td>
                <td>Hembygds- och folkdansföreninge i Borås.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.freewebs.com/ljungskiledos/">
                    Ljungskile Dansare och Spelmän
                  </a>
                </td>
                <td>Folkdans- och spelmanslag i Ljungskile.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.granquist.de/uddevallaspelmanslag.html">
                    Uddevalla spelmanslag
                  </a>
                </td>
                <td>Spelmanslag i Uddevalla</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.folkmusikfestival.uddevalla.se/">
                    Uddevalla folkmusikfestival
                  </a>
                </td>
                <td>
                  Folkmusikfestival som hålls i Uddevalla i augusti varje år
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://webbservern.se/~bokenasetsadra/">
                    Bokenäsets ådra
                  </a>
                </td>
                <td>Spelmanslag i Bokenäs i mellersta Bohuslän.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.wattlebygden.nu/">
                    Wättlebygdens Spelmanslag
                  </a>
                </td>
                <td>Spelmanslag i Lerums kommun.</td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.ranrikespelman.se/">Ranrike spelmän</a>
                </td>
                <td>
                  Folkmusikgrupp från Bohuslän som bl.a. erbjuder spelningar.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.vsf.nu/">
                    Västergötlands spelmansförbund
                  </a>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.hallesakersspelmanslag.se/">
                    Hällesåkers spelmanslag
                  </a>
                </td>
                <td>
                  Spelmanslag som övar onsdagar i Gamla församlingshemmet i
                  Lindome.
                </td>
              </tr>
            </tbody>
          </table>

          <center>
            <h2>Större arrangörer längre bort</h2>
          </center>

          <table className="links-table">
            <tbody>
              <tr>
                <td>
                  <a href="http://www.vff.nu/">
                    {" "}
                    Vätterbygdens Folkmusik Förening
                  </a>
                </td>
                <td>
                  Arrangerar konsert- och danskvällar liknande Folkmusikkaféet
                  på lördagar på Gnistan i Jönköping.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.malmofolk.se/">Malmö Folk</a>
                </td>
                <td>
                  Arrangerar konsert- och danskvällar liknande Folkmusikkaféet
                  på lördagar i Malmö.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://felan.se/">Felan</a>
                </td>
                <td>
                  Arrangerar konsert- och danskvällar liknande Folkmusikkaféet
                  på lördagar i Örebro.
                </td>
              </tr>

              <tr>
                <td>
                  <a href="http://www.folkmusikhuset.se/sv/">Skeppis</a>
                </td>
                <td>
                  Arrangerar spel- och danskvällar på söndagar samt konserter på
                  fredagar i Stockholm.
                </td>
              </tr>
              <tr>
                <td>
                  <a href="http://www.stallet.st/">Stallet</a>
                </td>
                <td>
                  Scen för Folk- och världsmusik i Stockholm med arrangemang
                  flera gånger i veckan. <br />
                  Skeppis och Stallet kan vara värda att besöka om man är i
                  Stockholm.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </center>
    );
  }
}

export default Links;
