import React, { Component } from "react";
import Concert from "./concert";
import "./concert.css";

/* Here is what is displayed on top, first of all */
const openingSection = (
  <div>
    <div>
      <center>
        Vi har hittat på mycket skojiga saker genom åren och tänkte forsätta med
        det.
        <br />
        Här hittar ni gamla programpunkter från tidigare kvällar som vi anordnat
        på Folkmusikkaféet.
      </center>
    </div>
    <div className="padBottomLight"></div>
  </div>
);

/* Here the archive is inserted! */
let ArkivKonserter = [
  <Concert
    title="Spel- och danskväll"
    date="2024-09-13"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/ht2024/heldanskvall.jpg"
  >
    <div className="pBody">
      <center>
        Folkmusikkaféet inleder höstsäsongen med en spel- och danskväll – dans
        till spellista. Två grupper är bokade att inleda kvällen, sedan är det
        fritt fram att skriva upp sig på listan.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan och Ingela Lindberg lär ut grundpolska. Spelman: Björn
                Johnsson
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Hisingens spelmanslag samt Dan Olsson &amp; Lars-Gunnar Franzén
                inleder, sedan följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Skjortor och Särkar</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Fallstråk"
    date="2024-09-20"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/ht2024/Fallstrak.jpg"
  >
    <div className="pBody">
      <center>
        Fallstråk bjuder på folkmusik från Bohuslän &amp; Västergötland, och
        pärlor ur vår svenska och norska rika visskatt på fiol, nyckelharpa,
        gitarr, dragspel och sång.
        <br />
        <br />
        Mia Gunberg är från Lur och bor på Gårdemyr utanför Grebbestad. Den
        musikaliska källan strömmar från hennes hemvist i nordligaste Bohuslän.
        <br />
        <br />
        Billy Lätt är från Skultorp, har bott i över 30 år i Telemark i Norge
        och bor numera på Gårdemyr utanför Grebbestad. Han är riksspelman på
        västgötalåtar.
        <br />
        <br />
        Björn Restin är multimusikern från Nossebro där han fortfarande bor och
        jobbar. Björn har utvecklat sin egen spelstil på dragspelet där han
        utnyttjar instrumentets hela register. Han är bred musikaliskt och
        behärskar både jazz, klassiskt och folkmusik.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                första gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoonderwaldt lär ut schottis.
                Spelmän: Karin Westerståhl och Lennart Ehn.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Mikael Rittri lär ut kärleksvisor</small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Fallstråk</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Stordanskväll: Mats Berglund, Mattias Helje"
    date="2024-09-27"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/ht2024/Mats_Berglund.jpg"
    imgTwo="img/program/ht2024/Mattias_Helje_2-min.jpg"
  >
    <div className="pBody">
      <center>
        På en stordanskväll är det dans hela kvällen – ingen konsert!
        <br />
        <br />
        Mattias Helje - Springlekarnas värsting! Sedan tonåren har Mattias ägnat
        sig åt västerdalmusiken. Tidigt lärde han sig låtar hos Kalle Almlöf och
        har sedan utvecklat ett eget låtspel som gjort honom till en mycket
        uppskattad dansspelman.
        <br />
        <br />
        Jössehärspolskans värsting Mats Berglund från Köla i västra Värmland har
        i många år räknats som en av landets främsta spelmän. Karakteristiskt
        för Mats musik är hans koppling till att spela till dans.
        <br />
        <br />
        Att Mattias och Mats möts i samspel under en Stordanskväll på Allegården
        är helt naturligt. Bägge två har i sitt musikanteri letat efter ett
        äldre spel där insikten i äldre spel och dans i gränslandet mot Norge
        finns som ett eko från äldre tid. Förutom springlekar och
        jössehäradspolskor blir det även finnskogspols, schottis/rejländer, vals
        och halling.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoonderwaldt lär ut bakmes och
                polska från övre Klarälvsdalen. Spelman: Joar Skorpen.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Mats Berglund &amp; Mattias Helje (solospel och duospel)
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Näverluren</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Anna-Karin Andersson &amp; John-Francis Goodacre"
    date="2024-10-04"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/ht2024/andersson_goodacre.jpg"
    imgLink="https://www.instagram.com/goodacre_andersson/"
  >
    <div className="pBody">
      <center>
        Ett möte mellan hardingfela och viola d’amore, mellan svenskt och
        norskt, mellan musiker och dansare.
        <br />
        <br />
        Anna-Karin Andersson (hardingfela, fiol) och John-Francis Goodacre
        (viola d’amore, fiol) är två spelmän som har hittat sitt musikaliska hem
        bland de vildvuxna speltraditionerna från Västerdalarna, Värmland och
        närliggande Norge.
        <br />
        <br />
        De bjuder in dansare och lyssnare till en stor klangvärld där gamla
        låtar ges nytt liv i lekfullt och nyfiket samspel. Duon träffades första
        gången på Ransäterstämman och upptäckte en delad passion för riviga
        danslåtar med inspiration från deras respektive läromästare Mats Edén
        och Mats Berglund.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Emelie Ström-Mattsson och Daniel Wallenius lär ut rørospols.
                Spelmän: Karin Westerståhl och Lennart Ehn.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Lars-Gunnar Franzén lär ut låtar från Värmland och
                Västerdalarna.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Anna-Karin Andersson &amp; John F. Goodacre</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Anna-Karin Andersson &amp; John F. Goodacre</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Minja Klevebrant</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Kören Amanda"
    date="2024-10-11"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    img="img/program/ht2024/Amanda_11_okt.jpg"
    imgLink="https://amanda.nu/"
  >
    <div className="pBody">
      <center>
        Sångensemblen Amanda är en unik sammansättning av folk- och
        världsmusiker, skådespelare, läkare, systemanalytiker och en konstnär
        som kör taxi. Vi rör oss fritt mellan olika konstnärliga uttryck och
        inkluderar både egna verk och kända stycken som vi tolkar på vårt sätt.
        Repertoaren inkluderar rock, pop, folkmusik, klassiskt och ballader.
        Efter 40 år fortsätter vi utmana gränserna för vad körsång innebär.
        <br />
        <br />
        Efter Amandas konsert spelar Höstrusk och Tagel &amp; Co till dans.
        <br />
        <br />
        <b>OBS!</b> Den här konserten kan förbokas via Billetto, länk finns här:{" "}
        <a href="https://billetto.se/e/konsert-och-danskvall-pa-folkmusikkafeet-koren-amanda-biljetter-1065119?utm_source=organiser&utm_medium=share&utm_campaign=copy_link&utm_content=1">
          Förbokning
        </a>
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Mats Nilsson och Ingegerd Sigfridsson lär ut slängpolska.
                Spelman: Staffan Folestad.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Kenneth Dalbris lär ut visor från Västsverige.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Amanda</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Höstrusk och Tagel &amp; Co</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Kråkstråk</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Glenntown festival: Inver"
    date="2024-10-18"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/ht2024/231012Inver0204_photo_Ard_Jongsma_-min.jpg"
    imgLink="https://inver.dk/"
  >
    <div className="pBody">
      <center>
        Den här kvällen ingår i Glenntown festival. Inver spelar traditionell
        och nyskriven folkmusik med rötter i de keltiska traditionerna från
        Irland och Skottland.
        <br />
        <br />
        Kevin Lees (fiol), Rune Barslund (irländska flöjter &amp; dragspel) och
        Simon Nyberg (gitarr) spelar med respekt för traditionerna och samtidigt
        med en vilja att utmana och förnya dem. Detta kommer särskilt till
        uttryck i trions egna kompositioner och fantasifulla arrangemang som ger
        gruppen ett unikt och samtida sound.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                The Royal Scottish Country Dance Society Göteborg lär ut skotska
                danser.{" "}
                <a href="https://rscdsgothenburg.se/">
                  Länk till skottedansarna
                </a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Inver</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Inver</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Kråkstråk</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Karin med vänner</small>
        <br />
        <br />
        <small>
          <i>
            Den här kvällen är ett samarbete med{" "}
            <a href="https://www.timsig.se">TIMSIG</a>
          </i>
        </small>
        <br />
        <br />
        <img
          src="img/program/ht2024/logo22.png"
          alt="TIMSIG"
          style={{ width: "100%" }}
          onClick={() => window.open("https://www.timsig.se/")}
        />
      </center>
    </div>
  </Concert>,

  <Concert
    title="Kulturnatta: Heldanskväll"
    date="2024-10-25"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/ht2024/heldanskvall.jpg"
  >
    <div className="pBody">
      <center>
        En helkväll för alla dansare. Triol, Västkustfolk och Kristians kapell
        kommer att spela till dans två halvtimmar var. Vi kan räkna med att det
        blir mycket polskor men också några gammaldanser.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                sista gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Balkandansarna lär ut danser från Balkan. Kopanitsa spelar.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Robert Qvist lär ut visor från Hälsingland.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Triol, Västkustfolk och Kristians kapell.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Göran Håkansson &amp; Claes Hallgren"
    date="2024-11-01"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/ht2024/HakanssonHallgren-min.jpg"
  >
    <div className="pBody">
      <center>
        Den här kvällen ligger fokus på värmländsk musik, med några utflykter
        till Røros, Jämtland/Härjedalen och Västerdalarna. Göran och Claes har
        spelat som duo sedan de deltog i Ransäterskurserna för 50 år sedan. De
        är båda riksspelmän med låtar från övre Fryksdalen och med läromästaren
        Olof Johansson, mer känd som ”Olle på Halla”, som förebild.
        <br />
        <br />
        Med åren så har Västerdalsmusiken med Lejsme Pers musik samt
        Jämtland/Härjedalen och Røros blivit en viktig del i deras repertoar.
        Båda spelmännen är utpräglade dansspelmän och upplever samspelet mellan
        dansare och spelmän som något stort.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Malte Hermansson och Kerstin Harvenberg lär ut finnskogspols.
                Spelman: Joar Skorpen.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Hans Grönlund lär ut låtar från östra Värmland.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Göran Håkansson &amp; Claes Hallgren</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Göran Håkansson &amp; Claes Hallgren</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Borås spelmanslag</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Studenter på Högskolan för scen och musik"
    date="2024-11-08"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/ht2024/HSM_2024_b-min.jpg"
    imgLink="https://www.gu.se/scen-musik/mer-om-kandidat-samtida-traditionsmusik"
  >
    <div className="pBody">
      <center>
        På utbildningen i samtida traditionsmusik (tidigare
        världsmusikprogrammet) ägnar sig studenterna både åt fördjupning i olika
        former av traditionell musik, att hitta vägar att överbrygga gränserna
        mellan dessa traditioner och skapa nya musikaliska samarbeten.
        <br />
        <br />
        En färgstark grupp individer från olika delar av världen möts och hittar
        tillsammans nya uttryck i sin musik, vilket kommer att vara uppenbart i
        denna konsert. Vare sig du vill lyssna till musik för att beröras på
        djupet eller för att ryckas med av danslust i kroppen så kommer denna
        upplevelse att vara för dig!
        <br />
        <br />
        Detta är studenternas egna konsert, och vad som helst kan hända! Efter
        konserten bjuder studenterna in till traditionsenlig danskväll, där
        folklig dansmusik både från när och fjärran brukar dyka upp.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoonderwaldt lär ut
                schottisvariationer. Spelmän: Håkan och Ingela Lindberg.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Studenter på HSM</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Studenter på HSM</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Bagaregårdens spelmanslag</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="ENKEL"
    date="2024-11-15"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    img="img/program/ht2024/ENKEL_15_Nov_Photo_Jussi_Ojala-min.jpg"
    imgLink="https://www.enkelband.com/band.html"
  >
    <div className="pBody">
      <center>
        ENKEL är ett traditionellt musikband från Finland som består av två
        durspel, kantele, viola och fyra röster; fyra personliga, unika och
        folkliga kvinnor med sina instrument, som delar en stor passion för
        traditionell musik och social folkdans och berättar historier genom
        instrumentala arrangemang.
        <br />
        <br />
        Deras debutalbum Pappilan hääyö (Wedding Night at the Vicarage) släpptes
        2016. Båda första albumen belönades som Årets finska folkmusikalbum och
        har fått positiv feedback internationellt. Sedan debuten 2014 har ENKEL
        uppträtt aktivt i Europa. I ENKEL ingår Leija Lautamaja (durspel, röst),
        Miia Palomäki (durspel, röst), Maija Pokela (kantele, röst) och Iida
        Savolainen (viola, röst).
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Jimmy Persson och Ingegerd Hägnesten lär ut humppa. Spelmän:
                Sune Löfström, Inger Blomstrand och Ingela Linderå.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Ann-Britt Lindhult lär ut visor från Västergötland.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: ENKEL</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: GNDS</small>
        <br />
        <small>Den här kvällen är ett samarbete med World sessions</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spel- och danskväll"
    date="2024-11-22"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/arkiv/spel_och_dans.jpg"
  >
    <div className="pBody">
      <center>
        Kristin &quot;Fornfela&quot; Svensson inleder dansspelet. Hon har även
        en visstuga i början av kvällen, och en viskurs på lördagen (se{" "}
        <a href="/kurser">kursinfo</a>).
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Kristin &quot;Fornfela&quot; Svensson lär ut visor efter
                Sven-Ingvar Heij och andra spelmän från Västergötland (se{" "}
                <a href="/kurser">kursinfo</a>).
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Kristin &quot;Fornfela&quot; Svensson, Tageltrång och Lei-la
                inleder, sedan följer öppen spellista.
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Folk Youth Minifestival #5
      <br />
      Tonskalv och Flach | Østerby
    </div>
    date="2024-11-29"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    imgTwo="img/program/ht2024/Flach_Osterby_29_11-min.jpg"
    img="img/program/ht2024/TONSKALV_29_11_photo_Amanda_Lindgren-min.jpg"
  >
    <div className="pBody">
      <center>
        Välkomna till Folk Youth Minifestival! Folkmusik och dans av och med
        unga personer.
        <br />
        <br />
        Tonskalv är en kvartett som gärna tar ett kliv bort från det finstämda
        för att istället låta stråkarna knarra lite extra. Med sin unika
        sättning tolkar de den svenska folkmusiken på ett nyfiket och modernt
        sätt, sida vid sida med sitt egenkomponerade material. Blicken har de
        riktat mot sina kvinnliga företrädare vars historier de ger liv och
        röst. Genom fri improvisation, detaljrika arrangemang,
        historieberättande och tätt samspel tas lyssnaren med på en resa mellan
        förtvivlan och eufori. Död, hunger och favoritpolskor. Bas, fiol och
        flöjt.
        <br />
        <br />
        Alice Evensen Landström (fiol, altfiol, sång), Alvina Herbinger Rygne
        (fiol), Daniella Eriksson Dahlström (elbas) och Daniela Söderlund
        (tvärflöjt, sång).
        <br />
        <br />
        Flach | Østerby: Träd in i en värld av musikalisk symbios, där toner,
        harmonier och övertoner smälter samman i små instrumentala berättelser.
        Med accordion, nyckelharpa och hardingfele som uttryckssätt bjuder Anna
        och Henriette in dig till deras nordiska ljuduniversum av originalmusik.
        Att skriva ny musik med grund i det traditionella har de båda gjort i
        många år, och det har varit en drivkraft och en viktig del av deras
        musik - både tillsammans och var för sig. Flach | Østerby är aktuella
        hösten 2024 med sitt debutalbum som duo.
        <br />
        <br />
        Anna Østerby (accordion) och Henriette Flach (nyckelharpa och
        hardingfele).
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>19:00-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                för vana och ovana dansare med Folkdanssällskapet Nationals
                Ungdomslag
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Flach | Østerby</td>
          </tr>
          <tr>
            <td>20:50-21:30</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small> till Stina Huldén (ovanvåningen)</small>
            </td>
          </tr>
          <tr>
            <td>21:30-22:20</td>
            <td>Tonskalv</td>
          </tr>
          <tr>
            <td>22:20-23:10</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>till Artisterna med vänner</small>
            </td>
          </tr>
          <tr>
            <td>23:10-00:00</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>till Tonskalv</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Kafévärd denna afton: Folk Youth och Folkdanssällskapets Nationals
          ungdomslag
        </small>
        <br />
        <small>
          Den här kvällen arrangeras i samarbete med Bilda och Folk Youth VG.
        </small>
        <br />
        <br />
        <img
          src="img/arkiv/FY_VG_color.png"
          style={{ width: "30%" }}
          onClick={() =>
            window.open("https://www.goteborgfolkfestival.com/folkyouth")
          }
        />
      </center>
    </div>
  </Concert>,

  <Concert
    title="Glöggkompaniet"
    date="2024-12-06"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    img="img/program/ht2024/gloggkompaniet_foto_Josefina_Edensvard.jpg"
    imgLink="https://gloggkompaniet.se/"
  >
    <div className="pBody">
      <center>
        Ta del av en stämningsfull julkonsert som sprider glädje och ljus!
        Glöggkompaniet är en hejdundrandes högtidsorkester som spelar
        traditionella och välkända julvisor i folklig ton. Med sången i fokus
        och med rötterna i nordisk och skotsk folkmusik gör Glöggkompaniet
        vemodiga, dansanta och medryckande tolkningar av vintermörkrets
        låtskatter.
        <br />
        <br />
        Petrix Hessel (sång &amp; cittra), Vendela Höök (sång &amp; fiol), Thor
        Ahlgren (sång, lutor, durspel, säckpipor och flöjter), Emma Engström
        (sång &amp; piano), Erik Bengtsson (sång &amp; kontrabas) och Magnus
        Heebøll (sång &amp; slagverk).
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Mats Nilsson och Ingegerd Sigfridsson lär ut vals. Spelman:
                Staffan Folestad.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Glöggkompaniet</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Glöggkompaniet</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Mams &amp; Paps</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Mams &amp; Paps</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spel- och danskväll"
    date="2024-01-19"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans_1.jpg"
  >
    <div className="pBody">
      <center>
        Välkomna på Spel- och danskväll. På spel- och danskvällar är det dans
        till spellista. 1-3 grupper bokas för att inleda kvällen, därefter är
        det fritt fram att skriva upp sig på listan.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan och Ingela Lindberg lär ut polska från Bingsjö. Spelman:
                Björn Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Mikael Rittri lär ut visor om älskog</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Triol och Kråkstråk inleder, sedan följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Kafévärd denna afton: Folkmusikkaféets styrelse med vänner
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Storis Limpan Band"
    date="2024-01-26"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/vt2024/StorisLimpanBand.jpg"
  >
    <div className="pBody">
      <center>
        Möt Magnus ”Storis” Holmström från Bullmark i Västerbotten, trippel
        världsmästare på Nyckelharpa, och gitarristen Tomas ”Limpan” Lindberg
        från Heby i Uppland, känd från folkmusikgruppen Draupner, i en sprakande
        konsert.
        <br />
        <br />I 20år har Storis & Limpan turnérat ihop i bland annat USA,
        Canada, Island, Tyskland och Turkiet, Tanzania och så klart i Sverige.
        De har gjort allt från Bregottreklamer, via Melodifestivalen, till
        gästmusiker i metalbandet HammerFall.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Maud Söderstedt och Lars-Gunnar Franzén lär ut schottis.
                Spelmän: Karin Westerståhl och Lennart Ehn.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Velizara Karaivanova lär ut bulgarisk folksång</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Storis &amp; Limpan</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar McRill</small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <small>Storis &amp; Limpan Band spelar till dans.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Kafévärd denna afton: Folkmusikkaféets styrelse med vänner
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      Project kvinnolåt,
      <br />
      <small>Anna Rynefors & Miriam Andersén</small>
    </div>
    date="2024-02-02"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    img="img/program/vt2024/Kvinnolat.jpeg"
  >
    <div className="pBody">
      <center>
        Anna Rynefors & Miriam Andersén har känt varann i tjugo år och står
        bägge med ett ben i medeltidsmusiken och folkmusiken. De älskar att
        utforska nya områden i gränslandet mellan dessa genrer och bjuder denna
        kväll på en berättarföreställning med projektet Kvinnolåt i vilket de
        skapat debutalbumet Skånska Spelkvinnor.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                första gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan och Ingela Lindberg lär ut Sønderhoning. Spelman: Björn
                Johnsson.
              </small>
            </td>
          </tr>

          <tr>
            <td>20:00-21:00</td>
            <td>Kvinnolåt med Anna Rynefors &amp; Miriam Andersén</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                I pausen spelar Dan Olsson &amp; Lars-Gunnar Franzén
              </small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Anna Rynefors &amp; Miriam Andersén spelar till dans
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Bagaregårdens spelmanslag</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Boda Mollkapell"
    date="2024-02-09"
    imgMaxWidth="95%"
    cost={180}
    costYoung={150}
    img="img/program/vt2024/MollkapelletMora.jpg"
  >
    <div className="pBody">
      <center>
        Folkmusiken, med fokus på låtar från Boda, har alltid varit med
        medlemmarna i folkmusikgruppen Boda mollkapell genom livet. De har inte
        musiken som yrke men på fritiden spelar de och lever i folkmusiken. Boda
        mollkapell är kända för att spela mjuka, fina bodapolskor.
        <br />
        <br />
        Tätorten Boda har en stor folkmusikskatt med många glömda låtar som här
        fångats upp av ensemblen och börjat spelas igen. Kapellet består denna
        kväll av: Knapp Britta, Lars Ljunggren, Jon Holmén och Knapp Karin på
        fioler samt Christina Ljunggren på cittra
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                andra gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoondervaldt lär ut polska från
                Boda. Spelmän: Eva Blanck och Jan Nilsson
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Boda mollkapell lär ut låtar från Boda. Nyfiken på vilka låtar
                som kommer spelas? Kolla in vilka låtar som kommer spelas här{" "}
                <a href="https://drive.google.com/drive/folders/10lemvZSG--_kJWkDSMHxh5oOVAMa-L_r?usp=sharing">
                  via denna länk!
                </a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Boda Molkapell</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Eva Blanck &amp; Jan Nilsson</small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Boda Molkapell spelar till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Anders Norudde & Karin Wallin"
    date="2024-02-16"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/vt2024/Norudde_Wallin.jpeg"
  >
    <div className="pBody">
      <center>
        Anders från Värmland och Karin från Skåne. Två rutinerade folkmusiker
        med största förkärlek till det råa klangfulla och omstämda möts här med
        stort sväng och kraftfullt dansdriv i låtar från Värmland, Närke och
        Skåne.
        <br />
        <br />
        Anders Norudde, uppväxt i Degerfors kom i kontakt med folkmusiken 1978
        och bytte då ut elgitarren mot en fiol. Tillägnade sig efterhand flera
        folkmusikinstrument såsom svensk säckpipa, moraharpa, stråkharpa,
        vallhorn, sälgflöjt mfl. Startade gruppen Blå Bergens Borduner 1984, och
        några år senare gruppen Hedningarna.
        <br />
        <br />
        Karin Wallin, uppväxt i Helsingborg bland dansande, dansforskande och
        sjungande släktingar. 1975 väcktes det stora intresset för att spela
        till dans, skånska låtar till den skånska folkliga danstraditionen som
        far Börje Wallin forskade fram. Här gällde det att få till dansrytm,
        sväng och driv som passade dansarna och gärna då med platt stall och
        omständ fiol för att kunna spela dubbelsträngat och få fram den stora
        klangen som spelmän gjorde förr.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                tredje gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Daniel Wallenius och Emelie Ström-Mattsson lär ut slängpolska.
                Spelmän: Karin Westerståhl och Lennart Ehn
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Lars-Gunnar Franzén lär ut låtar efter Lejsme Per</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Anders Norudde &amp; Karin Wallin</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Borås spelmanslag</small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Anders Norudde &amp; Karin Wallin spelar till dans.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Hisingens spelmanslag</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Anders Almlöf & Gustav Melén"
    date="2024-02-23"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/vt2024/AndersoGustaf.jpeg"
  >
    <div className="pBody">
      <center>
        Anders Almlöf  har sin musikaliska bakgrund i Malungstrakten. Han blev
        riksspelman 1999 för utmärkt och traditionsrikt spel av låtar från
        Västerdalarna. Spelman och smed!
        <br />
        <br />
        Gustaf Melén är uppväxt i Hedemora, nu bosatt i Rättvikstrakten. En
        mångsysslare av den gamla stammen med såväl låtspel, snickeri och
        musikundervisning på repertoaren.    <br />
        <br />           De är spelkamrater sedan många år,  spelar gärna
        västerdalslåtar, men eftersom de nu bor i Rättvikstrakten spelar de
        också  låtar därifrån. Naturlig inspiration har de fått  från Kalle
        Almlöf, Jonny Soling och Pers Hans.
        <br />
        <br />
        Vi förväntar oss bra fart på dansgolvet, hög stämning!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                fjärde och sista gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoondervaldt lär ut slängpolska från
                Malung efter Gucku Erik (Malungspolska 1). Spelman: Björn
                Johnsson
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Fred Sörensson lär ut irländska låtar</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Anders Almlöf &amp; Gustaf Melén</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Anders Almlöf &amp; Gustaf Melén spelar till dans.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: GNDS</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Heldanskväll,
      <br />
      <small>Tagel & Co, Urbanders och Höstrusk</small>
    </div>
    date="2024-03-01"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans2.jpg"
  >
    <div className="pBody">
      <center>
        En helkväll för alla dansare. Tagel & Co, Urbanders och Höstrusk kommer
        att spela till dans två halvtimmar var. Vi kan räkna med att det blir
        mycket polskor men också några gammaldanser.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>17:30-19:00</td>
            <td>Årsmöte</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Tagel &amp; Co, Urbanders och Höstrusk</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Balkandansarna</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Göteborg Folk Festival,
      <br />
      <small>Woodlands & Bäckafall</small>
    </div>
    date="2024-03-08"
    cost={180}
    costYoung={150}
    imgMaxWidth="95%"
    img="img/program/vt2024/WoodlandsBackafall.jpg"
  >
    <div className="pBody">
      <center>
        Woodlands/Bäckafall är en powerkvartett som slår ihop den svenska
        midsommaren med den irländska puben. Tillsammans tar de sig an och
        blåser nytt liv i den traditionella låtskatten från Skottland och
        Sverige på harpa, gitarr, fiol, nyckelharpa och sång. Sånger på
        gaeliska, franska, polska och engelska vävs lekfullt ihop med ösiga
        reels, smäktande valser och mystiska ballader i en indragande
        helhetsupplevelse.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoonderwaldt lär ut
                schottisvariationer. Spelmän: Håkan och Ingela Lindberg.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Woodlands/Bäckafall</td>
          </tr>
          <tr>
            <td>21:00-24:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Västkustfolk, FMK:s spelmanslag och Woodlands &amp; Bäckafall
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Folk Youth</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Ranrike spelmän"
    date="2024-03-15"
    imgMaxWidth="95%"
    cost={160}
    costYoung={130}
    img="img/program/vt2024/RanrikeSpelman.JPG"
  >
    <div className="pBody">
      <center>
        Ranrike spelmän är ett spelmanslag som funnits i över 20 år som spelar
        bohuslänsk folkmusik och har gett ut 2 album. Konserten bjuder på låtar
        ur den bohuslänska låtskatten på instrument som fiol, dragspel,
        nyckelharpa, bas och klarinett. Därefter blir det dans med en blandning
        av schottis, vals, hambo mm, samt rytmiska bohuspolskor, där det också
        stundtals märks inslag i polskorna från gränsen västerut.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <small>
                se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan och Ingela Lindberg lär ut vals. Spelman: Björn Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Robert Qvist lär ut visor om singelskap - "Jag vill alls ingen
                fästeman hava”
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Ranrike spelmän</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Dan Palmqvist &amp; Daniel Austern</small>
            </td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Ranrike spelmän spelar till dans.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Kafévärd denna afton: Folkmusikkaféets styrelse med vänner
        </small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Stordanskväll,
      <br />
      Fredrik Lundberg & Jonas Hjalmarsson
      <br />
      samt Pelle Björnlert
    </div>
    date="2024-03-22"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2024/jonas_fredrik.jpg"
    imgTwo="img/program/vt2024/PelleBjornlert.jpeg"
  >
    <div className="pBody">
      <center>
        På en stordanskväll är det dans hela kvällen – ingen konsert.
        <br />
        <br />
        När natten har övergått i gryning, i en dansloge på en spelmansstämma -
        där trivs dom som bäst! Jonas Hjalmarsson och Fredrik Lundberg har
        spelat länge tillsammans och dansspelet är det centrala. Repertoaren
        hämtar dom i Dalarna och Värmland hos Gössa Anders, Ole Hjorth, Lejsme
        Per, Gunnar Orre, Magnus Olsson bland många andra. En stor del av
        repertoaren är polskor, men även gammeldansens valser, schottisar och
        polkor står högt i kurs.
        <br />
        <br />
        Pelle Björnlert från Vråka i nordöstra Småland spelar företrädesvis
        musik som har sina rötter i den småländska och östgötska
        folkmusiktraditionen. Pelle har stort fokus på låtarnas ursprungliga
        funktion som bruks- och dansmusik och har under lång tid utvecklat sitt
        låtspel i nära kommunikation med dansen. Tidigt skapade han sig ett namn
        i kraft av ett personligt spelsätt med en smittande dansrytm och har
        länge varit en förebild för många spelmän från södra Sverige.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoondervaldt lär ut polska från Övre
                Klarälvsdalen. Joar Skorpen spelar.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Hans Grönlund lär ut låtar från norra och östra Värmland
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Jonas Hjalmarsson &amp; Fredrik Lundberg + Pelle Björnlert
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Karin med vänner</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Thuva Härdelin,
      <br />
      <small>Föreställning "Helena Allena" + dans</small>
    </div>
    date="2024-04-05"
    cost={160}
    costYoung={85}
    imgMaxWidth="95%"
    img="img/program/vt2024/Thuva_foto_Madeleine_Lind.jpg"
  >
    <div className="pBody">
      <center>
        Berättelsen om Helena Allena är en sann berättelse. Eller så har den
        endast glimtar av sanning. Helenas sanning. Hörsägen blandas med fakta
        och kryddas med myter och fantasi, så som många berättelser inom
        folkkulturen sedan länge berättats. Detsamma kan sägas om traditionell
        folkmusik.
        <br />
        <br />I mötet med samtida musik influeras, berikas och utvecklas den och
        det är inte alltid lätt att urskilja de olika influenserna från
        varandra. Under dansspelet kommer Thuva Härdelin och Fredy Samuel Lundh
        att avlösa varandra.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <small>
                se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ann-Britt Lindhult lär ut visor från Västergötland efter
                Sven-Ingvar Heij
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan och Ingela Lindberg lär ut snoepolska. Spelman: Björn
                Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Thuva Härdelin</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Kristians kapell</small>
            </td>
          </tr>

          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Thuva Härdelin och Fredy Samuel Lundh.</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Spel- och danskväll,
      <br />
      <small>Västkustfolk och Torgny Lundberg</small>
    </div>
    date="2024-04-12"
    imgMaxWidth="95%"
    img="img/program/vt2024/Vastkustfolk.jpeg"
  >
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              Spelmanslaget (se <a href="/kurser">kursinfo</a>)
            </small>
          </td>
        </tr>

        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              Peter Nordqvist och Alice Nordqvist lär ut Rørospols. Spelman:
              Joar Skorpen.
            </small>
          </td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              Västkustfolk och Torgny Lundberg inleder, sedan följer öppen
              spellista
            </small>
          </td>
        </tr>
      </table>
      <br />
      <small>Kafévärd denna afton: Skjortor och Särkar</small>
    </center>
  </Concert>,

  <Concert
    title=<div>
      Folk Youth,
      <br />
      <small>Västra Låtverkstan</small>
    </div>
    date="2024-04-19"
    cost={180}
    costYoung={95}
    imgMaxWidth="95%"
    img="img/program/vt2024/VastraLatverkstan.jpg"
  >
    <div className="pBody">
      <center>
        Ungdomar från hela regionen bjuder på tretakt och rockiga riff i en
        medryckande blandning! Publiken får höra en svängig och inspirerande
        konsert med unga folkmusiker som framför folkmusik på nya och kreativa
        sätt.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Simon Ekenberg från Västra Låtverkstan lär ut västgötska
                favoritlåtar
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Västra låtverkstan</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Västra låtverkstan, Jenny Gustafsson och Anders Ådin
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Folk Youth VG</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spelmansstämma"
    date="2024-04-26"
    imgMaxWidth="95%"
    img="img/arkiv/stordanskväll.png"
  >
    <div className="pBody">
      <center>
        Välkommen på spelmansstämma, vårens final på Folkmusikkaféet!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>

          <tr>
            <td>19:00-20:00</td>
            <td>
              Allspel i stora salen under ledning av Dan Palmqvist. Här kan du
              ladda ned årets utökade allspelshäfte:{" "}
              <a href="filer/Allspelslatar_FMK_2024.pdf">Allspelslåtar 2024</a>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Dans efter lista i stora salen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                FMK:s spelmanslag, Tageltrång, Lei-la och Mams &amp; Paps
                inleder. Fortsatt buskspel i resten av huset hela kvällen.
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Mams &amp; Paps</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spel- och danskväll"
    date="2023-09-15"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans_1.jpg"
  >
    <table className="program-tabell">
      <tr>
        <td>17:30-19:15</td>
        <td>Spelmanslaget</td>
      </tr>
      <tr>
        <td>18:00-19:30</td>
        <td>Tillsammansgruppen</td>
      </tr>
      <tr>
        <td>18:30-19:30</td>
        <td>Dansstuga</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <small>
            Håkan & Ingela Lindberg lär ut grundpolska. Spelman: Björn Johnsson
          </small>
        </td>
      </tr>
      <tr>
        <td>20:00-23:00</td>
        <td>Spel till dans</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <small>
            Kråkstråk och Världsmusikprogrammet på HSM inleder, sedan följer
            öppen spellista
          </small>
        </td>
      </tr>
    </table>
    <br />

    <small>Kafévärd denna afton: FMK:s styrelse med vänner</small>
  </Concert>,
  <Concert
    title="MP3"
    date="2023-09-22"
    imgMaxWidth="95%"
    imgLink="https://miamarine.se/index.php/en/band2/mp3"
    cost={180}
    costYoung={95}
    img="img/arkiv/MP3_PR_5_2022.jpg"
  >
    <div className="pBody">
      <center>
        Marine/Pérez Trio, bandet som älskar samspelet, dansspelet och säväl de
        grooviga gammelpolskorna som den nyskrivna traditionsmusiken, firar hela
        20 år som band 2023! Den eldiga, kraftfulla musiken frán västra Sverige
        är deras signum.
        <br />
        <br />
        Låtarna har en fot stadigt planterad i traditionen, medan den andra
        ständigt tassar runt bland andra intryck och genrer tills en alldeles
        egen MP3-blandning uppstår, och efter en konsert lämnas ingen oberörd.
        Tre skivor har de i bagaget, med en fjärde pà väg under jubileumsåret.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist o Marie Schonderwaldt lär ut polska från övre
                Klarälvsdalen. Joar Skorpen spelar.
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <small>Lars-Gunnar Franzén lär ut låtar efter Lejsme Per</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>MP3</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Fredy Samuel Lundh</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Klintetten"
    date="2023-09-29"
    imgMaxWidth="95%"
    imgLink="https://klintetten.spelman.se/"
    cost={160}
    costYoung={85}
    img="img/arkiv/Klintetten.jpg"
  >
    <div className="pBody">
      <center>
        är en östgötsk spelgrupp som har spelat i ca 30 år. Repertoaren består
        av traditionell folkmusik från olika delar av Sverige, främst polskor
        men också gammeldans. De spelar till dans och gör också dans- och
        spelföreställningar, konserter och kurser i dans och spel. Både musiken
        och dansen spelar huvudrollen. Klintetten består av ca 15 medlemmar,
        spelmän och dansare, och instrumentsättningen är fioler, altfiol,
        dragspel, cittra och bas. Flera av medlemmarna i gruppen är förutom
        spelmän också duktiga dansare.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga med Klintetten</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Stig och Helen Eriksson lär ut hamburska och gammalkilspolska,
                båda från Östergötland
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga med Klintetten</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Elias Samuelsson lär ut någon slängpolska och hälsingelåt
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Musik- och dansföreställning</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Med Klintetten följt av spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är Näverluren</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Ayaam"
    date="2023-10-06"
    imgMaxWidth="95%"
    imgLink="https://www.facebook.com/aayaam.music/"
    cost={160}
    costYoung={85}
    img="img/arkiv/Prabhat_Das.jpg"
  >
    <div className="pBody">
      <center>
        Aayaam är ett band som föddes på musikhögskolan i Göteborg. Där
        träffades Prabhat Das från Delhi, Shafeeq Alsadi från Palestina, Joan
        Peiro Azna från Spanien och Jonas Maciulis från Sverige. Från första ton
        hittade de gemensamma nämnare i sina traditioner. Musiken som ljöd
        skapade en ny musikalisk dimension.
        <br />
        <br />
        Under danspasset kommer danser från Katalonien att läras ut.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Svenska vänföreningen Folkmusik i Kurdistan lär ut kurdisk
                folkmusik
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Aayaam</td>
          </tr>

          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Bagaregårdens spelmanslag spelar dels i pausen direkt efter konserten,
          dels efter Aayaams dansspel.
        </small>
        <br />
        <small>Kafévärd denna afton är Karin med vänner</small>
        <br />
        <small>Den här kvällen är ett samarbete med World sessions</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Folk Youth Minifestival"
    date="2023-10-13"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/arkiv/FY_VG.png"
  >
    <div className="pBody">
      <center>
        Välkomna till Folk Youth Minifestival!
        <br />
        Vi kommer bjuda på konserter, dans, kurs och massa kul.
        <br />
        Mer information om kvällen kommer, så håll till godo!
        <br />
        <br />
        <b>Pomåna</b>
        <br />
        <br />
        Pomåna är ett göteborgsbaserat folkmusikband som består av Alma Weiser
        (SE) på sång och dragspel, Nora Mauritzson (SE) på slagverk och sång,
        och Andreas Krambias (CY) på bouzouki, laouto och oud. De möttes 2022 på
        Högskolan för scen och musik. Med nyfikenhet och spelglädje tar trion
        sig an en repertoar av traditionell musik från Norden och östra
        Medelhavet, och skapar egna uttrycksfulla arrangemang. Pomåna bjuder på
        en lekfull blandning av virvlande rytmer och vackra melodier.
        <br />
        <br />
        <b>Tvesôvla</b>
        <br />
        <br />
        Tvesôvla är en rykande färsk kvartett som oblygt tar för sig av den
        nordiska folkmusiken. De plockar guldkorn ur spelmanstradition,
        vistradition, gamla nothäften och egna kompositioner, och arrangerar det
        sedan burdust och med finess, explosivt och innerligt, med solsken och
        svärta. Varför skulle man behöva välja det ena över det andra? Tvesôvlad
        musik helt enkelt. I Tvesôvla ingår Olof Bergström, fiol, Fritz
        Dahlström, gitarr, Erika Wikström, sång och harmonium samt Adrian H.
        Bånga, fiol.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kursinfo</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>19:00-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                för vana och ovana dansare med Folkdanssällskapet Nationals
                Ungdomslag
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert - Pomåna</td>
          </tr>
          <tr>
            <td>20:50-21:30</td>
            <td>Dans (ovanvåningen)</td>
          </tr>
          <tr>
            <td>21:30-22:20</td>
            <td>Konsert - Tvesôvla</td>
          </tr>
          <tr>
            <td>22:20-23:10</td>
            <td>Dans - Pomåna</td>
          </tr>
          <tr>
            <td>23:10-00:00</td>
            <td>Dans - Tvesôvla</td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är Folk Youth</small>
        <br />
        <br />
        <img
          style={{ maxWidth: "30%" }}
          src="img/arkiv/folkyou.png"
          alt="folkyou"
        ></img>
        <br />
        <img
          style={{ maxWidth: "30%" }}
          src="img/arkiv/bilda_svart.png"
          alt="bilda_svart"
        ></img>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Olof Kennemark och Pers Alexander Olsson"
    date="2023-10-20"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/arkiv/Olof_Alex_press.jpg"
  >
    <div className="pBody">
      <center>
        Olof Kennemark och Pers Alexander Olsson, två unga spelmän med stark
        förankring i den svenska spelmansmusiken. Nu aktuella med sin första
        skiva ”Som förr”! När de för första gången träffades och tog en låt ihop
        var det uppenbart att det var starten på ett samspel som skulle vara
        länge.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Håkan & Ingela Lindberg lär ut rättvikspolska. Spelman: Björn
                Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Mikael Rittri lär ut visor i schottistakt</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Olof Kennemark & Pers Alexander Olsson</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>I pausen spelar Västkustsallad</small>
        <br />
        <small>Kafévärd denna afton är Hisingens spelmanslag</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Heldanskväll: Triol / Höstrusk / Tagel & Co"
    date="2023-10-27"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans2.jpg"
  >
    <div className="pBody">
      <center>
        Under Kulturnatta bjuder vi in till en helkväll för alla dansare. Triol,
        Höstrusk och Tagel & Co kommer att spela till dans två halvtimmar var.
        Vi kan räkna med att det blir mycket polskor men också några
        gammaldanser.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Balkandansarna lär ut danser från Balkan. Kopanitsa spelar.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ann-Britt Lindhult lär ut låtar från Skåne och Västergötland
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Triol, Höstrusk och Tagel & Co spelar till dans</td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är FMK:s styrelse med vänner</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Världsmusiklinjen på Högskolan för scen och musik"
    date="2023-11-03"
    imgMaxWidth="95%"
    img="img/arkiv/hsm-small.jpg"
  >
    <div className="pBody">
      <center>
        På Världsmusiklinjen möts en färgstark grupp individer med brett
        musikaliskt uttryck från olika delar av världen. Studenterna har fått
        möjligheten att tänka utanför sin egen musikaliska box och hitta nya
        sätt att uttrycka sig på, vilket kommer att vara uppenbart i denna
        konsert. Vare sig du vill lyssna till musik för att beröras på djupet
        eller för att ryckas med av danslust i kroppen så kommer denna
        upplevelse vara för dig!
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Mats Nilsson o Ingegerd Sigfridsson lär ut slängpolska. Spelman:
                Staffan Folestad
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Robert Qvist lär ut visor med temat "snygga män"</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Världsmusiklinjen på HSM</td>
          </tr>

          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är GNDS</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Ornungaprojektet - Jenny Gustafsson & Hans Kennemark m.fl."
    date="2023-11-10"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/arkiv/Ornungaprojektet.jpg"
  >
    <div className="pBody">
      <center>
        Jenny Gustafsson och Hans Kennemark presenterar den fascinerande musiken
        från Ornunga. Med sig har dom vännerna Stefan Wingefors, Emma Johansson
        och Harald Kennemark. Under kvällen gästas de dessutom av Lisa Moberg
        med folkmusikensembler från Hvitfeldtska gymnasiet.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Malte Hermansson o Kerstin Harvenberg lär ut schottis. Spelman:
                Björn Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Malin Klittvall lär ut låtar från Bohuslän</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Ornungaprojektet</td>
          </tr>

          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>I pausen spelar Västkustfolk</small>
        <br />
        <small>Kafévärd denna afton är Balkandansarna</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Bokenäsets Ådra"
    date="2023-11-17"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/arkiv/Bokenasets_adra.jpg"
  >
    <div className="pBody">
      <center>
        Bokenäsets ådra är en kulturförening som varit aktiv sedan 1977 och
        bland annat mottagit Uddevalla Kommuns Kulturpris för sitt arbete. De
        har under årens lopp har de sysslat med dans, spel, sång, forskning,
        gamla mattraditioner och hantverk från Bohuslän samt producerat flera CD
        och vishäften. Till Folkmusikkaféet tar de med sin kulturskatt och ger
        oss en kväll full med spel, sång och dans!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Maud Söderstedt lär ut vals</small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Bokenäsets Ådra</small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Bokenäsets Ådra</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Bokenäsets Ådra</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>I pausen spelar Lars-Gunnar Franzén</small>
        <br />
        <small>Kafévärd denna afton är Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Stordanskväll - Daniel Petterson & Isa Holmgren"
    date="2023-11-24"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/arkiv/daniel-nyckel.jpg"
    imgTwo="img/arkiv/Isa_Holmgren.jpg"
  >
    <div className="pBody">
      <center>
        På en stordanskväll är det dans hela kvällen - ingen konsert! Två
        artister turas om med att spela (och tralla) så att det blir dansmusik
        utan avbrott i tre timmar
        <br />
        <br />
        <b>Daniel Petterson</b>
        <br />
        <br />
        Daniel Pettersson har under hela sitt yrkesverksamma liv som folkmusiker
        har Daniel jobbat med att levandegöra arkivmaterial och sprida den
        traditionella musiken och kulturen. Intresset för den äldre
        traditionsmusiken från Västerbotten och södra Lappland har gått som en
        röd tråd genom Daniels musikskapande. Det äldre skandinaviska
        tonspråket, nyckelharpans bordunklang och låtarnas funktion som
        dansmusik utgör grunden i Daniels musicerande.
        <br />
        <br />
        <b>Isa Holmgren</b>
        <br />
        <br />
        Isa Holmgren är en svensk vokalist baserad i Oslo med rötter i den
        traditionella folkmusiken från Sverige och Norge. Som soloartist har hon
        fördjupat sig i dansmusiken från Västra Värmland och Finnskogen med
        särskilt fokus på den asymmetriska polstraditionen och samspelet mellan
        dansare och dansmusiker. Hennes första soloskiva “Efter Eda” släpptes på
        Ransäterstämman 2019 och fick ett varmt mottagande.
        <br />
        <br />
        Dagen därpå, lördag 25/11, har även Isa vis- & trallkurs som ni kan
        anmäla er till. Se <a href="/kurser">kurser</a> för mer information!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs (se kursinfo)</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist & Marie Schoonderwaldt lär ut senpolska från
                Haverö. Spelmän: Karin Westerståhl och Lennart Ehn
              </small>
            </td>
          </tr>
          <tr>
            <td>
              <s>18:30-19:30</s>
            </td>
            <td>
              <s>Visstuga</s>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Kenneth Dalbris lär ut folkvisor från Västsverige. OBS: Denna
                visstuga är tyvärr INSTÄLLD.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Daniel Pettersson och Isa Holmgren</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>spelar/trallar till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är FMK:s styrelse med vänner</small>
        <br />
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spel- och danskväll"
    date="2023-12-01"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans_1.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs (se kursinfo)</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Eva Björnefors o Kent Molin lär ut Löuvnässchottis. Spelman:
                Karin Hed.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans.</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Lei-la och Tageltrång inleder, sedan följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton är Skjortor och Särkar</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Kören YMNA + Mia Marine"
    date="2023-12-08"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/arkiv/YMNA.jpg"
    imgTwo="img/arkiv/mia_marine-small.jpg"
  >
    <div className="pBody">
      <center>
        Damkören Ymna under ledning av Veronica Wåhlberg har en unik klang samt
        en vilja att berätta och beröra. Repertoaren är bred och spänner över
        flera genrer och epoker, med fokus på kvinnors musik och berättelser
        genom historien. Som publik bjuds du på folkliga julsånger, polskor och
        stjärnspelsvisor.
        <br />
        Kvällen ackompanjeras av den framstående efterlängtade Mia Marine på
        fiol. Hon spelar med YMNA till konserten och sedan solo till dans.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs (se kursinfo)</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Malte Hermansson & Kerstin Harvenberg lär ut grundpolska.
                Spelmän: Karin Westerståhl & Lennart Ehn
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Kören Ymna och Mia Marine</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>
              <small>Spel till dans</small>
            </td>
          </tr>
        </table>
        <br />
        <small>I pausen spelar Mams & Paps</small>
        <br />
        <small>Kafévärd denna afton är Mams & Paps</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel och danskväll"
    date="2023-01-20"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Under ledning av Gunnel Sjögren och Gunilla Sellersjö, se{" "}
                <a href="/kurser">kurser</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Nybörjardansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Maud Söderstedt. Spelman: Björn Johnsson</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Triol inleder, sedan följer öppen spellista</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Polskedanslaget som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Vågspel"
    date="2023-01-27"
    imgMaxWidth="95%"
    imgLink="https://www.vagspel.com/"
    cost={160}
    costYoung={85}
    img="img/program/vagspel.jpg"
  >
    <div className="pBody">
      <center>
        Alice Klint och Kristin Kennemark växte upp på Västgötaslätten, på samma
        gata och med samma förväntningar på livet. Folkmusik var startpunkten
        för en livslång vänskap och ett musikaliskt samarbete: Vågspel.
        <br />
        <br />
        Med rötterna stadigt i den västgötska låtskatten spelar Alice Klint och
        Kristin Kennemark både egenskrivna melodier och i tradition. Ett
        kraftfullt berättande och ett kokande samspel gör att dansen aldrig är
        långt borta. Vågspel är duon som tar partypolskan till en ny nivå!
        <br />
        <br />
        Alice Klint och Kristin Kennemark har båda varsin kandidatexamen i
        klassisk musik, vilket har bidragit till duons höga tekniska nivå samt
        skapat en frihet i både fiolspel och komponerande. Vågspels musik är
        rytmisk och melodiös, innovativ och lekfull. Kompositionerna har en egen
        spännande färg och speglar ofta sin samtid – både dess sorger och
        lyckorus. Vänskapen musikerna emellan är grunden i det sammansvetsade
        stämspelet. Tillsammans vill de ta sig an de stora känslorna!
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Prova på dans under ledning av Maud Söderstedt. Spelman Lars
                Gunnar Franzén
              </small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Vågspel</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Kråkstråk</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Bagaregårdens spelmansslag som tar hand om caféet denna
          kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Dalslands spelmansorkester"
    date="2023-02-03"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/dalslandsspelmansorkester.jpg"
  >
    <div className="pBody">
      <center>
        Dalslandsspelmansorkester är en del av Dalslands Spelmansförbund och
        består av runt 15 medlemmar, mest fioler, men även blåsinstrument,
        nyckelharpa, gitarr och bas. Orkestern har funnits i många år, alltid
        under musikalisk ledning av riksspelmannen Alban Faust. <br />
        <br />
        Man har sina självklara rötter i Dalsland men unnar sig musikaliska
        utflykter till Småland, Värmland, Norge, Finland och Ukraina, Deras
        spelningar har bland annat fört dem till Norge och Tyskland. Viktigast
        är spelglädjen, men likaväl spännande låtar, arrangemang, noggrannhet
        och sväng! En orkester som definitivt är värd att upplevas! <br />
        <br />
        På Folkmusikcafeet den 3 Februari 2023 förstärks orkestern även med
        riksspelmannen Tomas Fredriksson. Alban och Tomas kommer också att
        spelar ett pass under kvällen som riksspelmansduo Faust & Fredriksson.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Fösta tillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Dans i Bingsjö tradition under ledning av Peter Nordqvist
              </small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Dalslands spelmansorkester</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Hisingens spelmanslag</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Skjortor och Särkar som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Sångsystrar"
    date="2023-02-10"
    imgMaxWidth="95%"
    imgLink="https://open.spotify.com/album/53OUgFbmBJnnQXqxQyGYz8"
    cost={180}
    costYoung={95}
    img="img/program/ulrikaboden.jpg"
  >
    <div className="pBody">
      <center>
        Sångsystrar låter den flerstämmiga sången stå i fokus och repertoaren är
        hämtad ur våra traditionsarkiv, där sjungande systrar och bröder varit
        föregångare. Sofia Sandén från Dalarna och Ulrika Bodén från
        Ångermanland hör till två av Sveriges mest välkända folksångerskor och
        deras karaktäristiska stämsång har hörts både i duo och i populära
        gruppen Ranarim. Rösterna flätar och slingrar sig obehindrat genom
        vackert ornamenterade folksånger. Klangerna är både kärva och ljuva, med
        särskild kärlek till rytmen och de berättande texterna. Patrik
        Grundström från Härnösand skänker djup och driv till musiken med
        kontrabas och akustisk bas.
        <br />
        <br />
        Sångsystrar är skivaktuella med sitt första album som duo, som de
        släppte i maj tillsammans med basisten Patrik Grundström och gästerna
        Petter Berndalen slagverk, Daniel Ek gitarrer och Niklas Roswall
        nyckelharpa.
        <br />
        <br />
        Ulrika Bodén sång, dulcimer, autoharp
        <br />
        Sofia Sandén sång, femsträngad viola d'amore, tumpiano
        <br />
        Patrik Grundström kontrabas, akustisk bas
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Långdans och balladdans med Tommy Tannerud & Biörn Landahl
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Under ledning av Annalie Westerlund</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Ulrika Bodén & Sofia Sandén - Sångsystrar</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Annelie Westerlund</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Balkandansarna som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Karlsson & Rubinsztein"
    date="2023-02-17"
    imgMaxWidth="95%"
    imgLink="https://www.youtube.com/watch?v=KR1-WaMMS2A"
    cost={160}
    costYoung={85}
    img="img/program/rubinsztein_karlsson.jpg"
  >
    <div className="pBody">
      <center>
        Rubinsztein/Karlsson är duon som drivs av sin starka längtan efter att
        få folk att vilja dansa! Med en naturlig råhet och varm känslighet tar
        de sig an den traditionella musiken från Värmland och gränstrakterna
        till Norge på ett personligt och självklart sätt. Anna Rubinsztein och
        Anna Karlsson presenterades för varandra sent en natt på Ransäterstämman
        utanför södra logen 2012. Sedan dess har de följts åt på sin stig genom
        musiken där spelmän och förebilder från tidigare generationer inspirerat
        och visat vägen framåt. Rubinsztein/Karlsson släpper nu debutalbumet
        ”Värmland”. 14 guldkorn som pendlar mellan vemod och eufori på
        värmländska.
        <br />
        <br />
        Förbered er på ett ordentligt drag under galoscherna!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Dans i tradition från Malung under ledning av Håkan & Ingela
                Lindberg. Spelman: Björn Johnsson
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Hans Grönlund lär ut låtar från Värmland</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Karlsson & Rubinsztein</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Dan Olsson & Lars-Gunnar Franzén</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Heldanskväll"
    date="2023-02-24"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans2.jpg"
  >
    <div className="pBody">
      <center>
        <p>
          Välkomna till en helkväll för alla dansare. Vi har bokat tre av de
          bästa amatörgrupperna i Göteborg för att spela till dans från kl 20.00
          till kl 23.00. Tagel & Co, Urbanders och Höstrusk spelar två
          halvtimmar var. Vi kan räkna med att det blir mycket polskor men också
          några gammaldanser.
        </p>

        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>
              Nybörjarkurs i dans, se <a href="/kurser">kurser</a>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Under ledning av Gunnel Sjögren och Gunilla Sellersjö, se{" "}
                <a href="/kurser">kurser</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Under ledning av Ann-Britt Lindhult</small>
            </td>
          </tr>
          <tr>
            <td>20:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Tagel & Co, Urbanders och Höstrusk</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Karin med vänner som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Trio Törn"
    date="2023-03-03"
    imgMaxWidth="95%"
    imgLink="https://www.triotorn.se"
    cost={180}
    costYoung={95}
    img="img/program/trio_torn.jpg"
  >
    <div className="pBody">
      <center>
        Med sättningen fiol, cello och nyckelharpa experimenterar Trio Törn
        ständigt med instrumentens möjligheter och provar traditionens gränser.
        När trion sätter en ton uppstår en blandning av färgstarka melodier,
        fängslande harmonier, ohämmat sväng och innerlighet – allt i en levande
        enhet. Med egna kompositioner, starkt förankrade i den nordiska
        folkmusiken är Trio Törn ett kammarmusikaliskt möte där finurliga
        valser, svängiga schottisar och tunga polskor kläs i väl uttänkta
        arrangemang och där samspelet och instrumentens potential får stå i
        centrum. Trio Törn släppte sitt första album ”Debut”sommaren 2018.
        Skivan utsågs i topp fem listan över främsta folkmusikutgivningar 2018
        av tidningen Lira. Hösten 2018 mottog de Kungliga Musikaliska akademiens
        ensemblestipendium. Trio Törn är nu aktuella med sitt nya album
        ”Painting with Polska” som släpptes sommaren 2022.
        <br />
        <br />
        Olof Kennemark - Fiol
        <br />
        Klara Källström - Cello
        <br />
        Petrus Dillner - Nyckelharpa
        <br />
        <br />
        <br />
        <small>
          <i>
            ”Denna kombination av otroligt begåvade musiker har resulterat i
            kompositioner som inte är av denna värld.”
          </i>{" "}
          - Musikmagasinet Lira
        </small>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:00-19:30</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Sista kurstillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Hambovarianter under ledning av Kerstin Harvenberg & Malte
                Hermansson
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Visstuga under ledning av Robert Qvist</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Trio Törn</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Västkustfolk</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Polskedanslaget som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Hilleviensemblen"
    date="2023-03-10"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/hillieviensemblen.jpg"
  >
    <div className="pBody">
      <center>
        I september 1916 arrangerades en spelmanstävling i Sundsvalls
        Stadshussalong. Bland de tävlande stack ett namn ut - Hillevi Öberg, 18
        år och den enda kvinnliga fiolspelaren. Hon imponerade stort på juryn
        och hennes kraftfulla spel gav henne en tredjeplats. 100 år senare såg
        folkmusikern Emma Ahlberg Ek en notis om detta i ett arkiv och bestämde
        sig för att ta reda på vem Hillevi egentligen var. Hon upptäckte fler
        och fler ledtrådar som visade en färgstark kvinna med ett spännande
        levnadsöde.
        <br />
        <br />
        I denna fängslande musik- och berättarföreställning får publiken följa
        Hillevi på hennes resa i musikens tjänst, från bondehemmet i Borgsjö och
        vidare ut i världen med fiolen som ledstjärna. Hennes historia och egna
        polskor varvas med Emmas nyskrivna musik, allting framfört och berättat
        av Hilleviensemblen.
        <br />
        <br />
        Emma Ahlberg Ek - femsträngad fiol
        <br />
        Patrik Grundström - kontrabas
        <br />
        Valter Kinbom - slagverk
        <br />
        Leif Ottosson - dragspel
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Spelstuga under ledning av Malin Klittvall</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Hillevieensemblen</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar McRill</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Vi tackar GNDS som tar hand om caféet denna kvällen</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      Stordanskväll
      <br />
      <small>
        Jonas Åkerlund & William Olsson
        <br />+ Täpp Ida & Täpp Jenny
      </small>
    </div>
    date="2023-03-17"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/program/william_jonas.jpeg"
    imgTwo="img/program/ida_jenny.jpg"
  >
    <div className="pBody">
      <center>
        Varmt välkommen till stordanskväll hos oss! På en stordanskväll är det
        dans hela kvällen - ingen konsert!
        <br />
        <br />
        Kvällen till ära bjuds det på skogslekar, vandringslåtar och
        godvädersvalser med William Olsson och Jonas Åkerlund. Låtar från
        Värmland och angränsande trakter på fiol, 1800-talsklarinett, durspel,
        säckpipa och flöjt.
        <br />
        <br />
        Vi får även höra Täpp Ida Almlöf och Täpp Jenny Nylander som är systrar
        från Nedre Gärdsjö utanför Rättvik. Systrarna spelar framför allt låtar
        från Rättvikstrakten där Bingsjölåtarna ligger varmast om hjärtat.
        Systrarna har hämtat mycket inspiration från möten och samspel med
        storspelmannen Päckos Gustaf. De spelade mycket med Gustaf under
        90-talet vilket har präglat deras musik och spelstil. Ida och Jenny för
        nu bingsjötraditionen vidare.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Visor under ledning av Mikael Rittri</small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Under ledning av Gunnel Sjögren och Gunilla Sellersjö, se{" "}
                <a href="/kurser">kurser</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Dans i tradition från Rättvik under ledning av Håkan & Ingela
                Lindberg. Spelman: Björn Johnsson
              </small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Jonas Åkerlund & William Olsson</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Täpp Ida & Täpp Jenny</small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Skjortor och Särkar som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Bäckafall"
    date="2023-03-24"
    imgMaxWidth="95%"
    imgLink="https://www.backafallmusic.com/"
    cost={160}
    costYoung={85}
    img="img/program/backafall.jpg"
  >
    <div className="pBody">
      <center>
        Bäckafall låter folkmusik från Sveriges urskogar och Irlands rökiga
        pubar möta modern folkmusik med nya och traditionella klanger. En
        musikalisk berättelse där två människors liv av berg och dalar möter
        varann i ständigt samspel. Duon bjuder på en lek där livets kontraster
        står i fokus. <br />
        <br />
        Hampus Grönberg - Gitarr
        <br />
        Fredy Samuel Lundh - Nyckelharpa
        <br />
        <i>Med gäster</i>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>under ledning av Balkandansarna</small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Kenneth Dalbris lär ut visor från Västsverige</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Bäckafall</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Vi tackar Näverluren som tar hand om caféet denna kvällen</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      Folk Youth Minifestival #3
      <br />
      <small>Wood/Mänd/Moberg + The Hobs</small>
    </div>
    date="2023-03-31"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/program/FY_VG_minifestival3.png"
  >
    <div className="pBody">
      <center>
        Vi öppnar upp dörrarna till Folk Youth's tredje minifestival i samarbete
        med oss i Folkmusikkaféet. Folk Youth Västra Götaland (FY VG) är en
        förening som arrangerar folkmusik, dans och slöjdprojekt av och med unga
        personer. Vi lever genom folkkultur!
        <br />
        <br />
        <b>Wood / Mänd / Moberg</b>
        <br />
        <br />
        Vad kan elbasen vara i svensk folkmusik? Det är frågan som basisten
        Magnus Wood nyfiket utforskar och besvarar tillsammans med violinisterna
        Lisa Moberg och Regina Mänd i trion Wood/Mänd/Moberg. Med sättningen
        elbas och två fioler bjuds lyssnaren in i ett musikaliskt universum där
        det traditionella låtspelet står i fokus, men där instrumenteringen och
        de musikaliska rollerna vidgar och fördjupar traditionen som vi känner
        den.
        <br />
        <br />
        Musiken är rak, jordnära och levande. Ett omsorgsfullt melodispel på
        elbas varvas med lekfullt ackompanjemang, som tillsammans med den breda
        klangen av två fioler i stämspel skapar en musikalisk helhet som tillför
        något helt nytt till den svenska folkmusikscenen.
        <br />
        <br />
        <b>The Hobs (FI)</b>
        <br />
        <br />
        Denna folkmusiktrio består av Henrica Westerholm (fiol), Oscar Grönroos
        (fiol, keyboard) och Benjamin Backman (gitarr, mandolin). Bandet
        bildades år 2017 och har sedan dess släppt sitt debutalbum ”E Ni Me?” år
        2020 tack vare Svenska Litteratursällskapet i Finland / Finlands Svenska
        Folkmusikinstituts serie ”Folkmusik från Finlands svenskbygder”.
        <br />
        <br />
        The Hobs spelar folkmusik på ett nyskapt vis, med inslag av modernare
        musikformer blandat med medlemmarnas delade intresse för den
        traditionella finlandssvenska och nordiska folkmusiken.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>19:00-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Slängpolska under ledning av Petrix Hessel</small>
            </td>
          </tr>
          <tr>
            <td>20:50-21:30</td>
            <td>Dans (ovanvåningen)</td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Wood / Mänd / Moberg (SE, ES, DK)</td>
          </tr>
          <tr>
            <td>21:30-22:20</td>
            <td>The Hobs (FI)</td>
          </tr>

          <tr>
            <td>22:20-23:10</td>
            <td>Dans - Wood / Mänd / Moberg</td>
          </tr>
          <tr>
            <td>23:10-00:00</td>
            <td>Dans - The Hobs</td>
          </tr>
        </table>
        <br />
        <br />
        <small>
          Evenemanget sker med stöd av:
          <br />
          Folkmusikkaféet
          <br />
          Bilda
          <br />
          <br />
          Kvällen kommer fotograferas. Bilderna kommer användas i PR-syfte för
          Folkmusikkaféet. Om du inte vill synas kontakta:
          fmk@folkmusikkafeet.se
          <br />
          Vi tackar FOLK YOUTH som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Duo Cavez/Paulson"
    date="2023-04-14"
    imgMaxWidth="95%"
    imgLink="http://www.duocavezpaulson.com/"
    cost={160}
    costYoung={85}
    img="img/program/cavez_paulson.JPG"
  >
    <div className="pBody">
      <center>
        Välkommen till ett musikaliskt möte mellan den belgiska accordeonisten
        Sophie Cavez och den svenska nyckelharpisten Josefina Paulson, som
        spelar låtar influerade av såväl deras traditionella dialekter som deras
        egna musikaliska personligheter. I händerna på Sophie Cavez och Josefina
        Paulson är första prioritet alltid den pulserande rytmen och varje ton
        blir svängorienterad. Med de rika övertonerna från nyckelharpans
        resonanssträngar som smälter samman med de varma ackorden och kraftfulla
        baslinjerna från Sophies dragspel skapar de ljudlandskap och en trädgård
        av musik, både för dina öron att finna en fridfull vila i och glädjen
        och utrymmet att bjuda upp till dans.
        <br />
        <br />
        Sophie och Josefina har spelat mycket tillsammans i kvartetten SIRUS som
        började som ett samarbete mellan två starka duos, Cavez/Montanaro och
        Paulson/Åkerlund. Efter att ha släppt sitt album Lummen har medlemmarna
        i kvartetten tagit nya vägar och Sophie och Josefina bestämde sig för
        att utforska möjligheterna att smälta samman sina två röster. De speglar
        livet, som mödrar, musiker och människor, i nya kompositioner för detta
        projekt, och de bjuder in dig att följa med dem på deras resa.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Schottisvariationer under ledning av Peter Nordqvist
              </small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>under ledning av Lars-Gunnar Franzén</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Sofia Cavez & Josefina Paulson</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Folkmusikkaféets egna spelmanslag</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Bazooka Sub Trio"
    date="2023-04-21"
    imgMaxWidth="95%"
    imgLink="https://www.youtube.com/watch?v=rinlmg0Dldc"
    cost={180}
    costYoung={95}
    img="img/program/bazooka_sub_trio.jpg"
  >
    <div className="pBody">
      <center>
        Bazooka Sub Trio består av tre etablerade flöjtister från folk- och
        kammarmusikscenen som möts i en värld av väsiga skogsflöjter från äldre
        tiders musiktradition, blandat med nya och ännu outforskade tongångar.
        Musiken framförs på folkliga pipor och obskyra basblockflöjter - en bred
        repertoar av folkmusik i egna arrangemang, nyskriven såväl som
        traditionell.
        <br />
        <br />
        Musiken ramas in av en teatral storytelling, där komik och allvar varvas
        om vartannat.
        <br />
        <br />
        Kristine West, blockflöjter & pipor
        <br />
        Kim Persson, blockflöjter, pipor, subkontrabasflöjt och mandola
        <br />
        Göran Månsson, blockflöjter, pipor, subkontrabasflöjt & slagverk
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelstuga i kurdisk folkmusik under ledning av Svenska
                vänföreningen Folkmusik i Kurdistan
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Bazooka Sub Trio</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Kristians kapell</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2023-04-28"
    imgMaxWidth="95%"
    img="img/arkiv/stordanskväll.png"
  >
    <br />

    <div className="pBody">
      <center>
        Kom och spela och dansa!
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>19:00-20:00</td>
            <td>Allspel i stora salen</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Kolla in vår samling av allspelslåtar här:
                <br />{" "}
                <a href="filer/allspel_kombinerad_2023.pdf">Allspelslåtar</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                efter lista i stora salen. Folkmusikkaféets spelmanslag,
                Tageltrång, Lei-la och Mams & Paps inleder. Fortsatt buskspel i
                resten av huset.
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>
          Vi tackar Mams o Paps som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="GBG FOLK FESTIVAL"
    date="2022-09-02"
    cost={200}
    costYoung={100}
    prebook="https://billetto.se/e/fredag-goteborg-folk-festival-biljetter-682949"
    imgMaxWidth="95%"
    imgLink="https://www.goteborgfolkfestival.com/"
    img="img/arkiv/dram.jpg"
    imgTwo="img/arkiv/VastraLatverkstan.jpg"
    imgTwoLink="https://www.goteborgfolkfestival.com/"
  >
    <div className="pBody">
      <center>
        Göteborg Folk Festival startade 2019 och är en tre dagar lång festival
        med folkmusik från hela världen. Festivalen erbjuder något för alla –
        för utöver en mängd olika konserter kan man även ta del av spännande
        workshops, spel till dans, jamsessions, artistintervjuer, god mat och
        dryck, låt- och danskurser, panelsamtal och barnföreställningar.
        <br />
        <br />
        Festivalen inleds traditionsenligt på Folkmusikkaféet Allégården med
        konserter, kurser och dans på nordisk tema, för att sedan sprida sig
        vidare med folkmusik från hela världen på scener, caféer och pubar runt
        Stigberstorget.
        <br />
        <br />
        <b>Västra Låtverkstan</b>
        <br />
        Ungdomar från hela regionen bjuder på tretakt och rockiga riff i en
        medryckande blandning!
        <br />
        Publiken får höra en svängig och inspirerande konsert med unga spelmän
        som framför folkmusik på nya och kreativa sätt.
        <br />
        <br />
        <b>Dråm</b>
        <br />
        Dråm utgörs av de båda riksspelmännen Anna Rynefors och Erik Ask-Upmark.
        På ett stort instrumentarium som omfattar bl.a. nyckelharpa, rebec,
        många olika säckpipor, slagverk och keltisk harpa framför de folkmusik
        och historisk musik. Tillsammans har de uppträtt med konserter och på
        festivaler över hela världen och har gjort sig ett namn som förespråkare
        och kritikerhyllade utövare av folk- och bordunmusik i olika former.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Minja Klevebrant håller spelstuga i Gotlandslåtar</small>
            </td>
          </tr>

          <tr>
            <td>18:30-19:30</td>
            <td>Nybörjardansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                En hälsingepolska med Kenneth Henriksson och Marie Hurtig
              </small>
            </td>
          </tr>

          <tr>
            <td>19:30-20:30</td>
            <td>Konsert - Dråm</td>
          </tr>

          <tr>
            <td>20:30-21:00</td>
            <td>Dans (ovanvåningen)</td>
          </tr>

          <tr>
            <td>21:00-22:00</td>
            <td>Konsert - Västra Låtverkstan</td>
          </tr>

          <tr>
            <td>22:00-24:00</td>
            <td>Dans med Dråm / Västra Låtverkstan</td>
          </tr>
        </table>
        <br />
        <small>Hemsida för festivalen: </small>
        <a href="https://www.goteborgfolkfestival.com/">
          Göteborg folk festival
        </a>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel och dans"
    date="2022-09-16"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans_1.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Nybörjardansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Mats Nilsson</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Folkmusikkaféets spelmanslag och Triol inleder, sedan följer
                öppen spellista
              </small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Systerpolskan"
    date="2022-09-23"
    imgMaxWidth="95%"
    imgLink="https://www.facebook.com/systerpolskan/"
    cost={180}
    costYoung={95}
    img="img/arkiv/Systerpolskan.jpg"
  >
    <div className="pBody">
      <center>
        Systerpolskan är en folkmusikgrupp med några av landets främsta spelmän
        som bildades på initiativ av Benny Andersson. Han bjöd in dem till sin
        studio på Skeppsholmen hösten 2015 och ett år senare släpptes albumet
        ”Randig kjortel”. Benny Andersson har producerat skivan och är
        medmusikant på flera spår. Albumet mottogs med lysande recensioner, som
        t.ex:
        <br />
        <br />
        <i>
          "När man lyssnar öppnas spjäll i själen. Det blåser in syre och det
          känns som ens öron upplever musik för första gången.”
        </i>
        (Jan Gradvall, DI)
        <br />
        <br />
        Systerpolskan har hörts på radio och TV och framträtt på Allsång på
        Skansen (2016 och 2018), Gokväll, Nyhetsmorgon, Moraeus med mera (2016)
        och Folk & Världsmusikgalan. De är idag efterfrågade på folkmusikscener
        runt om i Sverige.
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Låtar efter Lejsme Per med Lars-Gunnar Franzén</small>
            </td>
          </tr>
          <tr>
            <td>18:15-19:50</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Fösta tillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Bondpolska Viksta med Håkan och Ingela Lindberg</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Systerpolskan</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Västkustsallad</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Trio Rop"
    date="2022-09-30"
    imgMaxWidth="95%"
    img="img/arkiv/trio_rop.jpg"
    imgLink="https://www.triorop.com/"
    cost={160}
    costYoung={85}
  >
    <div className="pBody">
      <center>
        Genom en brinnande vilja att berätta om den lilla människan och allt det
        där som känns så stort, tar Trio Rop avstamp i folkligt berättande och
        folklig vokal tradition. En tradition där man förr förde vidare
        berättelser med sång för att komma ihåg och dela händelseförlopp med
        andra.
        <br />
        <br />I den andan söker Trio Rop i sitt skrivande efter de
        allmänmänskliga formuleringarna för att synliggöra berättelser de saknar
        i folktraditionen. Berättelser om de avvikande, om samkönad kärlek och
        starka vänskapliga band. Med sina nytänkande kompositioner tar sångarna
        Elin Buxfeldt, Petrix Hessel och Sofia Källman oss med till en helt
        stilla plats med motljus och vackra skuggningar.
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>"Jämmer och elände" med Robert Qvist</small>
            </td>
          </tr>

          <tr>
            <td>18:15-19:50</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Andra tillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Trio Rop</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Fredy Samuel Lundh</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Grit Quartet"
    date="2022-10-07"
    imgMaxWidth="95%"
    img="img/arkiv/Grit_Quartet.jpg"
    imgLink="https://oskarreuter.com/gritquartet"
    cost={180}
    costYoung={95}
  >
    <div className="pBody">
      <center>
        Grit Quartet består av <b>Jonathan Larsson</b> (dragspel),{" "}
        <b>Guro Kvifte Nesheim</b> (hardanger d’more), <b>Oskar Reuter</b>{" "}
        (nyckelharpa) och <b>Anna Larsson</b> (gitarr, sång).
        <br />
        <br />
        Oskar och Jonathan har flera gånger blivit hyllade för sina begåvade
        sätt att skriva ny musik. Efter att Anna och Guro medverkat på Oskar &
        Jonathans duoskiva, Panna mot Panna som släpptes under våren 2019 valde
        de att fortsätta som kvartett och Grit Quartet var bildat.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Visor från Västsverige med Kenneth Dalbris</small>
            </td>
          </tr>

          <tr>
            <td>18:15-19:50</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Tredje tillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Grit Quartet</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      Irländsk kväll
      <br />
      <small>i samarbete med TIMSIG</small>
    </div>
    date="2022-10-14"
    imgLink="https://www.timsig.se/"
    imgMaxWidth="95%"
    img="img/arkiv/catherinemachalegunnarsson.jpg"
    imgTwo="img/arkiv/plastic_paddy.jpg"
    cost={160}
    costYoung={85}
  >
    <div className="pBody">
      <center>
        I samarbete med TIMSIG och festivalen Glenntown arrangerar vi en
        irländsk kväll. Plastic Paddy, med hemvist i Dalarna, spelar irländsk
        musik sedan mer än 30 år med spelningar på festivaler och pubar, i
        konsertlokaler och bystugor. Spel, sångstugor samt dans kommer erbjudas.
        <br />
        <br />
        Catherine MacHale Gunnarson komemr att leda sångkursen. Catherine växte
        upp i ett musikaliskt hem i Cork och hon studerade sång på Cork School
        of Music. Vid sidan av sången började hon undervisa i gaeliska, det
        irländska språket, och 2012 flyttade hon till Sverige. Catherine kommer
        att lära ut sånger på gaeliska och engelska. Sångare på alla nivåer är
        välkomna och ingen föranmälan krävs.
        <br />
        <br />
        Callern Andrew Scott kommer att leda dansen efter konserten. Andrew
        Scott kommer ursprungligen från England, men har bott länge i Sverige.
        Tillsammans med Plastic Paddy från Falun kommer han leda dansgolvet
        genom olika danser som reels, jigs och hornpipes. Dansen lärs ut
        simultant, är lätt att följa och alla kan vara med.
        <br />
        <br />
        Välkommen till en höjdarkväll med dans i irländska tecken!
        <br />
        <br />
        <br />
        Läs mer om Glenntown här:{" "}
        <a href="https://www.glenntown.se/">Glenntown</a>
        <br />
        och här kan ni läsa mer om TIMSIG:{" "}
        <a href="https://www.timsig.se/">TIMSIG</a>
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:15-19:50</td>
            <td>Nybörjarkurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Fjärde tillfället, se <a href="/kurser">kurssidan</a>
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Sångstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Sånger på gaeliska och engelska med Catherine MacHale
                Gunnarsson. Sångare på alla nivåer är välkomna och ingen
                föranmälan krävs.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelsstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Irländska låtar med Fred Sörensson</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Plastic Paddy</td>
          </tr>
          <tr>
            <td>21:30-23:00</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Med Andre Scott, Sveriges bästa caller för irländsk Caeli dans.
                Dansen lärs ut simultant, är lätt att följa och alla kan vara
                med.
              </small>
              <br />
              <br />
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="ETHNO ON THE ROAD"
    date="2022-10-21"
    imgMaxWidth="95%"
    img="img/arkiv/eotr.jpg"
    cost={160}
    costYoung={85}
  >
    <div className="pBody">
      <center>
        Efter två års uppehåll är Ethno on the road tillbaka med ett nytt
        fantastiskt band!
        <br />
        <br />
        Ethno Sweden är sannolikt världens största internationell folkmusikläger
        för unga musiker. I år är det 33 år sedan lägret startades på Falu
        Folkmusikfestival i Dalarna, sedan dess har Ethno spridit sig och finns
        idag i över 40 länder över hela världen.
        <br />
        <br />
        Ethno on the road är turnéversionen av Ethno som i höst ger sig ut på en
        tre veckor lång turné i Sverige. Årets band har internationell tyngd med
        fem deltagare från tre kontinenter. Under ledning av två skickliga och
        erfarna mentorer, Nina Perez och Peter Rousu, får nu dessa unga musiker
        möjligheten att mötas igen för att tillsammans skapa en alldeles unik
        musikupplevelse. De kommer att ta dig med på en magisk musikalsik resa,
        världen runt på bara en timme. Spelglädje som smittar, en lyckokick att
        bära med sig ut i höstmörkret, detta vill du inte missa!
        <br />
        <br />
        Medverkande:
        <br />
        Iren Barnum, USA (flöjt/sång)
        <br />
        Petra Nordin, Sverige (fiol)
        <br />
        Velizara Karaivanova, Bulgarien (sång)
        <br />
        Antonio Vásquez från Chile (stränginstrument)
        <br />
        Iwan Audran, Frankrike (slagverk)
        <br />
        <br />
        Mentorer:
        <br />
        Nina Perez (fiol)
        <br />
        Peter Rousu (bas/gitarr)
        <br />
        <br />
        Efter konserten med Ethno on the Road spelar FMK:s spelmanslag till
        dans, från kl 21.45.
        <br />
        <br />
        Före konserten blir det spelstuga med Svenska vänföreningen folkmusik i
        Kurdistan kl 18.45-19.45. Alla instrument och röster är välkomna. Ta
        chansen att få spela och sjunga en gammal kurdisk traditionell låt som
        handlar om kärlek med mycket känslor. Låten är en väldigt populär och
        spelas i många olika sammanhang.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18.45-19.45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spela och sång i kurdisk tradition med Svenska vänföreningen
                folkmusik i Kurdistan
              </small>
            </td>
          </tr>

          <tr>
            <td>20:00-21:30</td>
            <td>Konsert med Ethno on the Road</td>
          </tr>

          <tr>
            <td>21:45-23:00</td>
            <td>FMK:s spelmansslag spelar till dans</td>
          </tr>
        </table>
        <br />
        <small>
          Ethno on the road arrangeras av Folkmusikens hus i samarbete med och
          med stöd av Rättviks kommun, studieförbundet Bilda, Sveriges Spelmäns
          Riksförbund, Musik i Dalarna, Region Gävleborg, Musik vid Siljan, Folk
          You, Dalarnas Hemslöjdsförbund, Jeunesses Musicales International och
          Dalarnas läns landsting.
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      KULTURNATTA
      <br />
      <small>Heldanskväll</small>
    </div>
    date="2022-10-28"
    imgMaxWidth="95%"
    img="img/arkiv/vastkustfolk.jpg"
  >
    <div className="pBody">
      Välkomna till en helkväll för alla dansare. Höstrusk, Tagel & Co och
      Västkustfolk kommer att spela till dans två halvtimmar var. Vi kan räkna
      med att det blir mycket polskor men också några gammaldanser.
      <br />
      <br />
      Västkustfolk vann VM för studentspelmanslag på Norrköpings
      folkmusikfestival! Se bilden.
      <br />
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Tillsammansgruppen</td>
        </tr>

        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Balkandansarna (musik: Kopanitsa)</small>
          </td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Spelstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Minja Klevebrant lär ut Gotlandslåtar</small>
          </td>
        </tr>

        <tr>
          <td>20:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Höstrusk, Triol och Västkustfolk</small>
          </td>
        </tr>
      </table>
    </div>
  </Concert>,
  <Concert
    title="Folk Youth Minifestival"
    date="2022-11-04"
    imgMaxWidth="95%"
    img="img/arkiv/FY_VG_stringflip.png"
    cost={180}
    costYoung={150}
  >
    <div className="pBody">
      <center>
        Vi öppnar upp dörrarna till Folk Youth's andra minifestival i samarbete
        med oss i Folkmusikkaféet.
        <br />
        <br />
        Folk Youth Västra Götaland (FY VG) arrangerar folkmusik, dans och
        slöjdprojekt av och med unga personer.
        <br />
        <br />
        Vi lever genom folkkultur!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>19:00-19:45</td>
            <td>Dansstuga (ovanvåningen)</td>
          </tr>
          <tr>
            <td>21:00-21:45</td>
            <td>Dans (ovanvåningen)</td>
          </tr>

          <tr>
            <td>21:30-22:15</td>
            <td>Konsert</td>
          </tr>
          <tr>
            <td></td>
            <td>Stringflip</td>
          </tr>
          <tr>
            <td>22:45-00:00</td>
            <td>Dans</td>
          </tr>

          <tr>
            <td>23:00-23:59</td>
            <td>Dans till artisterna/öppen spellista</td>
          </tr>
        </table>
        <br />
        Evenemanget sker med stöd av:
        <br />
        Folkmusikkaféet
        <br />
        Göteborg Stad
        <br />
        Kultur Ungdom
        <br />
        Folk You
        <br />
        Bilda
        <br />
        <br />
        <small>
          Kvällen kommer fotograferas. Bilderna kommer användas i PR-syfte för
          Folkmusikkaféet. Om du inte vill synas kontakta:
          fmk@folkmusikkafeet.se
        </small>
        <br />
        <br />
        <img
          style={{ "max-width": "30%" }}
          src="img/andra/planeta-logo.png"
        ></img>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Världsmusiklinjen på Högskolan för scen och musik"
    date="2022-11-11"
    imgMaxWidth="95%"
    img="img/arkiv/hsm-small.jpg"
  >
    <div className="pBody">
      <center>
        På Världsmusiklinjen möts en färgstark grupp individer med brett
        musikaliskt uttryck från olika delar av världen. Studenterna har fått
        möjligheten att tänka utanför sin egen musikaliska box och hitta nya
        sätt att uttrycka sig på, vilket kommer att vara uppenbart i denna
        konsert. Vare sig du vill lyssna till musik för att beröras på djupet
        eller för att ryckas med av danslust i kroppen så kommer denna
        upplevelse vara för dig!
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Kumulativa visor med Mikael Rittri</small>
            </td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Dansstuga i engelska med Mats Nilsson</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Högskolan för scen och musik</td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Stordanskväll"
    date="2022-11-18"
    imgMaxWidth="95%"
    img="img/arkiv/goran_claes.jpg"
    imgCaption="Claes Hallgren & Göran Håkansson"
    imgTwo="img/arkiv/dan&lg.jpg"
    imgTwoCaption="Lars-Gunnar Franzén & Dan Olsson"
    cost={180}
    costYoung={95}
  >
    <div className="pBody">
      <center>
        På en stordanskväll är det dans hela kvällen - ingen konsert!
        <br />
        (den som inte dansar kan givetvis sitta bredvid dansgolvet och lyssna.
        Ofta spelar spelmännen bättre till dans än på konsert)
        <br />
        <br />
        Den här kvällen ligger fokus på värmländsk musik, med några utflykter
        till Røros och Västerdalarna. Göran och Claes har spelat som duo sedan
        de deltog i Ransäterskurserna för 50 år sedan. Dan och Lars-Gunnar kom
        igång något senare och har spelat som duo i 28 år. Förutom duospel så
        blir också några låtar med alla fyra under kvällen. Spelmännen kommer
        att avlösa varandra så att det blir dans utan avbrott från kl 20 till kl
        23.
        <br />
        <br />
        <small>
          Göran, Claes och Dan är riksspelmän.
          <br />
          Lars-Gunnars instrument är inte godkänt på Zornmärkesuppspelningar.
        </small>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Visor från Västergötland med Kristin Fornfela Svensson
              </small>
            </td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Värmlandspolska med Peter Nordqvist</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Göran Håkansson & Claes Hallgren</td>
          </tr>
          <tr>
            <td></td>
            <td>Dan Olsson & Lars-Gunnar Franzén</td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Mats Berglund trio"
    date="2022-11-25"
    imgMaxWidth="95%"
    imgLink="https://matsberglund.se/mats-berglund-trio/"
    img="img/arkiv/matsberglund-trio.png"
    cost={180}
    costYoung={95}
  >
    <div className="pBody">
      <center>
        Mats Berglund har i många år varit en av de mest säregna rösterna på den
        svenska folkmusikscenen. Som dansspelman har han gjort sig oumbärlig som
        en av den värmländska musikens viktigaste ambassadörer. Med sin
        personliga spelstil är han idag en av landets mest igenkännbara spelmän.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Låtar från Värmland med Hans Grönlund</small>
            </td>
          </tr>

          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Finnskogspols med Håkan och Ingela Lindberg</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Mats Berglund Trio</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar DANNITO (Dan, Ann, Anni & Torbjörn)</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel och dans"
    date="2022-12-02"
    imgMaxWidth="95%"
    img="img/arkiv/spel_och_dans2.jpg"
  >
    <div className="pBody">
      <center>
        Kom och dansa!
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Dansstuga Rörospols med Peter Nordqvist</small>
            </td>
          </tr>

          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Tageltrång + Lei-la + Kristians kapell + Mams & Paps, sedan
                följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      <strike>Heldanskväll</strike> - tyvärr inställd
    </div>
    date="2022-01-21"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody"></div>
  </Concert>,
  <Concert
    title="Två otrevliga flickor"
    date="2022-01-28"
    time="20:00"
    cost={160}
    costYoung={85}
    img="img/arkiv/otrevliga-flickor.jpg"
    imgLink="https://tvaotrevligaflickor.wixsite.com/tvaotrevligaflickor/om"
  >
    <div className="pBody">
      <p>
        Två Otrevliga Flickor är systerduon från Delsbo, skriet från vildmarken,
        folkmusikfrälsningen från ovan som kommit för att på allehanda
        instrument sätta kul samt roligt på agendan. Polskor om dåliga
        julaftnar, powerballader om altanutbyggnad, saxofoner, klarinetter,
        backing tracks och familjeanekdoter utgör några av byggstenarna i
        systrarna Anderssons show.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Konsert: Två otrevliga flickor</td>
          </tr>
          {/*  <tr>
  <td>I pausen</td>
  <td><small>I pausen spelar Dan Olsson och Lars-Gunnar Franzén</small></td>
  </tr>
*/}
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Jonas Åkerlund & William Olsson"
    date="2022-02-04"
    time="20:00"
    cost={160}
    costYoung={85}
    img="img/arkiv/william-jonas.jpeg"
  >
    <div className="pBody">
      <p>
        Skogslekar, vandringslåtar och godvädersvalser med William Olsson och
        Jonas Åkerlund. Låtar från Värmland och angränsande trakter på fiol,
        1800-talsklarinett, durspel, säckpipa och flöjt.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Jonas Åkerlund & William Olsson</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Dan Olsson och Lars-Gunnar Franzén</small>
            </td>
          </tr>
        </table>
      </center>
      <br />
      <center>
        <small>
          Vi tackar Polskedanslaget som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title=<div>
      <small>i samarbete med World sessions:</small>
      <br /> Arsen Petrosyan Quartet
    </div>
    date="2022-02-11"
    time="20:00"
    cost={190}
    costYoung={130}
    imgMaxWidth="95%"
    img="img/arkiv/Arsen_Quartet.jpg"
  >
    <br />
    <center>
      <b>In english/på engelska</b>
      <br />
    </center>
    <br />
    The Arsen Petrosyan Quartet plays Armenian Classical Traditional and Sacred
    Music, which spans different eras and genres within Armenia's historical
    timeline. The instruments are Duduk, Santur, Qanun, Percussion. Our program
    includes music from Grigor Narekatsi (10th century), Simeon Yerevantsi (18th
    century), Sayat-Nova (18th century), Komitas (20th century) among others.
    This concert/tour is around his new album 'Hokin Janapar'. 'Hokin Janapar'
    means 'My Soul's Journey' in Armenian, is Arsen Petrosyan's nostalgic
    exploration into music that has stirred his soul, which also reflects the
    continued odyssey of the Armenian nation. The pieces on this album aren't
    just songs, rather, they are a document of a nation and culture that refuses
    to die.
    <br />
    <br />
    <center>
      <b>Sammanfattning på svenska</b>
    </center>
    <br />
    Arsen Petrosyan Quartet spelar klassisk, tradionell, Armensk och sakral
    musik. De spelar musik från olika eror och genrer inom Armeniens historiska
    tidslinje. Denna konsert handlar om deras senaste album 'Hokin Janapar'.
    Detta album är en nostalgisk utforskning av musik som dokumenterar en nation
    och en kultur som vägrar att dö.
    <br />
    <br />
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>20:00-22:00</td>
            <td>Arsen Petrosyan Quartet</td>
          </tr>
        </table>
      </center>
      <br />
      <center>
        <b>OBS Förköp</b>
        <br />
        <br />
        Förköpsbiljetter finns inte längre att tillgå (de har alla bokats upp)
        men du är välkommen att köpa din biljett väl på plats på Folkmusikkaféet
        i entrén en timme innan konsert.
        <br />
        <br />
        <small>
          Facebook evenemanget:{" "}
          <a href="https://fb.me/e/1994F4bPF">https://fb.me/e/1994F4bPF</a>
          <br />
        </small>
      </center>
      <br />
      <center>
        <small>
          <i>Denna kväll är en konsertkväll utan dans</i>
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Jenny Gustafsson & Hans Kennemark"
    date="2022-02-18"
    time="20:00"
    cost={160}
    costYoung={85}
    imgMaxWidth="95%"
    img="img/arkiv/JennyHans.jpeg"
    imgLink="http://www.gustafssonkennemark.se"
  >
    <div className="pBody">
      <p>
        Jenny Gustafsson och Hans Kennemark kommer och spelar och då blir det
        traditionella låtar från Västergötland med den energi och de
        framimproviserade arrangemang som bara den säregna publikkontakten på
        Allégården kan frambringa. Hans och Jenny har hjärtat fullt med
        berättelser om alla de legendariska spelmänn de trakterar låtar av. Det
        blir en sen kväll, omistlig!
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Jenny Gustafsson & Hans Kennemark</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Tagel & Co</small>
            </td>
          </tr>
        </table>
      </center>
      <br />
      <center>
        <small>Vi tackar Näverluren som tar hand om caféet denna kväll</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Tuultenpesä"
    date="2022-02-25"
    time="20:00"
    cost={180}
    costYoung={95}
    img="img/arkiv/tultenpesa.jpg"
    imgLink="http://simonson.nu/en/groups/tuultenpesa/"
  >
    <div className="pBody">
      <center>
        <b>Vindarnas möte</b>
      </center>

      <p>
        Tuultenpesä utvecklar och njuter av de färger och uttrycksmöjligheter
        som olika flöjter kan erbjuda. Med sina olika traditioner och unika
        bakgrunder som musiker och kompositörer korsbefruktar och skapar de nya
        sound och formspråk. Ett möte mellan ålderdomligt och modernt, tradition
        och förnyelse.
        <br />
        <br />
        Med repetitiv rytmik, modala melodier och flera melodiska lager skapar
        de med små medel stor variation och nya klanger. Ur den nordiska
        traditionen används polskor, hallingar, runosånger och annat.
        Minimalistisk musik från modern västerländsk konstmusik och olika andra
        folkmusiktraditioner är också en viktig inspirationskälla.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Visor från Dellenbygden med Robert Qvist</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Tuultenpesä</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel och dans"
    date="2022-03-04"
    time="20:00"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>17:30</td>
            <td>Årsmöte</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Kråkstråk, Dan Olsson och Lars-Gunnar Franzén och Triol inleder,
                sedan följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Ola Bäckström och Per Gudmundson"
    date="2022-03-11"
    time="20:00"
    cost={160}
    costYoung={85}
    img="img/arkiv/ola_solo.jpg"
    imgTwo="img/arkiv/per_solo.jpeg"
  >
    <div className="pBody">
      <p>
        Per Gudmundson har ett kraftfullt och känslosamt spelsätt, och spelar
        både solo och med gruppen Frifot. Pers musikaliska hemvist och
        kraftkälla är fioltraditionen i Rättvik. Han kan också sägas vara den
        som blåste liv i den slumrande traditionen på svensk säckpipa för snart
        två decennier sedan. Per Gudmundson arbetar på Folkmusiken Hus i
        Rättvik, och han försöker också hinna med att lära ut mellan varven. Ola
        Bäckström är också fiolspelman. Han syns på scen främst i grupperna Swåp
        och Triptyk, men liksom Per spelar han ofta och gärna solo eller i duo
        med andra spelmän. Det är Ola som är kraften bakom projektet Ungt Folk,
        där unga människor som spelar svensk folkmusik finner varandra i
        nätverk. Ola arbetar även som pedagog.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Lekstugestämmas bästa 8 marslåtar med Tove Glad</small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Peter Nordqvist, bodapolska</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Ola Bäckström & Per Gudmundson</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar I pausen spelar Västkustsallad</small>
            </td>
          </tr>
        </table>
      </center>
      <br />
      <center>
        <small>
          Vi tackar Skjortor och Särkar som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Stordanskväll, Mats Berglund & Zara Helje"
    date="2022-03-18"
    time="20:00"
    cost={180}
    costYoung={95}
    imgTwo="img/arkiv/ZaraHelje.jpg"
    img="img/arkiv/MatsBerglund.jpeg"
  >
    <div className="pBody">
      <p>
        Välkommen till en helkväll för alla dansare. Ingen konsert - dans från
        20:00 till 23:00 (den som inte dansar kan givetvis sitta bredvid
        dansgolvet och lyssna. Ofta spelar spelmännen bättre till dans än på
        konsert).
        <br />
        <br />
        Zara Helje med rötterna i Göteborg men numera spelledare i Malungs
        spelmanslag och naturaliserad dalkulla spelar låtar från västerdalarna.
        Mats Berglund från västra Värmland spelar låtar just därifrån. Det blir
        svårt att sitta still när det vankas halling!
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Låtar från östra och norra Värmland med Hans Grönlund
              </small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>med Mats Nilsson, värmlandspolska</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Mats Berglund & Zara Helje</td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel och dans (& viskurs med Maria Röjås)"
    date="2022-03-25"
    time="20:00"
    img="img/arkiv/mariarojas.jpg"
    imgTwo="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga med Maria Röjås</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Anmälanslänk:{" "}
                <a href="https://forms.gle/UiMFeyaDys6EYdHY9">
                  https://forms.gle/UiMFeyaDys6EYdHY9
                </a>
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Dans till spellista.</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Maria Röjås (som också har viskurs denna helg), Höstrusk och
                Västkustfolk inleder, sedan följer öppen spellista
              </small>
            </td>
          </tr>
        </table>
      </center>
      <center>
        <br />
        <a
          href="https://www.regiondalarna.se/malungsfolkhogskola/"
          target="_blank"
        >
          <img
            src="img/arkiv/logo_malungfh.png"
            style={{ "max-width": "50%" }}
          ></img>
        </a>
        <br />
        <br />
        <small>I samarbete med Malungs folkhögskola</small>
        <br />
      </center>
    </div>
  </Concert>,
  <Concert
    title="Kongero"
    date="2022-04-01"
    time="20:00"
    cost={180}
    costYoung={95}
    imgMaxWidth="95%"
    imgLink="https://kongero.se/epk/"
    img="img/arkiv/kongero.jpg"
  >
    <div className="pBody">
      <p>
        Kongero tar lyssnaren på en musikalisk, historisk och allmänmänsklig
        resa med nordiska folksånger som grund. Rafflande historier med rötter i
        medeltiden varvas med svängig dansmusik och sång.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Polkor ur Skogstorpatraditionen med Torbjörn Olofsson
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Kongero</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Gôtt</small>
            </td>
          </tr>
        </table>
      </center>
      <br />
      <center>
        <small>
          Vi tackar Polskedanslaget som tar hand om caféet denna kvällen
        </small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Folk Youth Minifestival #1"
    date="2022-04-08"
    time="20:00"
    cost={160}
    costYoung={85}
    imgMaxWidth="95%"
    img="img/arkiv/FY_VG_minifestival.jpg"
  >
    <div className="pBody">
      Välkomna till den nystartade ungdomsföreningen Folk Youths första
      minifestival på Folkmusikkaféet! Kvällen bjuder på konserter med tre unga
      fantastiska band:
      <br />
      <br />
      Västanvinden, Erland Westerström och Lovis Jacobsson bjuder på svängig
      folkmusik på munspel och cittern. De har ett lekfullt och lyhört samspel
      där dansen är ständigt närvarande i musiken. Erland och Lovis utgör två
      tredjedelar av trion Västanvinden som släppte sitt debutalbum i höstas.
      Som duo fokuserar de på Jämtländsk och Sörmländsk tradition.
      <br />
      <br />
      Kuling, västsvensk folkmusik med mycket blås!
      <br />
      <br />
      Isak Eriksson & Magdalena Eriksson, ”Valfrid Rönnlund - ett
      spelmansporträtt” I ett stort projekt har de blåst liv i en nästan utdöd
      folkmusikskatt i Ångermanland som i över 70 års tid förvaltades och
      utformades av bonden och spelman- nen Valfrid Rönnlund i Rö, ett par mil
      norr om Härnösand.
      <br />
      <br />
      <center>
        <small>Under kvällen arrangeras även dans</small>
      </center>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-18:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Festivalpolska med Petrix Hessel</small>
            </td>
          </tr>
          <tr>
            <td>19:00-20:00</td>
            <td>Konsert</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Magdalena Eriksson och Isak Eriksson</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>till Västanvinden duo</small>
            </td>
          </tr>
          <tr>
            <td>20:30-21:30</td>
            <td>Konsert - Kuling</td>
          </tr>
          <tr>
            <td>21:30-22:00</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>till Magdalena Eriksson och Isak Eriksson</small>
            </td>
          </tr>
          <tr>
            <td>22:00-23:00</td>
            <td>Konsert - Vänstanvinden duo</td>
          </tr>
          <tr>
            <td>23:00-00:00</td>
            <td>Dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>till artiserna/öppen spellista</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <center>
      <small>Vi tackar Folk Youth som tar hand om caféet denna kvällen</small>
    </center>
    <br />
    <center>
      <br />
      Arrangeras med stöd av:
      <br />
      <a href="https://www.folkyou.se/" target="_blank">
        <img src="img/arkiv/folkyou.png" style={{ "max-width": "50%" }}></img>
      </a>
    </center>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2022-04-22"
    imgMaxWidth="95%"
    img="img/arkiv/spelmansstaemma-small.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>19:30-20:00</td>
            <td>Allspel i stora salen</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                efter lista i stora salen. Mams & Paps, Tageltrång, Lei-la och
                FMK:s spelmanslag inleder. Fortsatt buskspel i resten av huset.
              </small>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <center>
      <small>Vi tackar Mams & Paps som tar hand om caféet denna kvällen</small>
    </center>
  </Concert>,
  <Concert
    title="Ellika Frisell & Emma Reid"
    date="2022-04-29"
    time="20:00"
    cost={160}
    costYoung={85}
    imgMaxWidth="95%"
    img="img/arkiv/Ellika_Emma.jpg"
    imgLink="http://www.emmaellika.com/om-oss-3/"
  >
    <div className="pBody">
      <p>
        Två spelmän med musiken från Bingsjö som specialitet. En musik med
        tuggmotstånd, vild, vacker och egensinnig. Och Orsapolskorna, låtar som
        pratar, och svänger hemlighetsfullt.
        <br />
        Emma och Ellika är spelmän med bredd och nyfikenhet, aktiva i många
        projekt och grupper i folk och världsmusiken. I denna duo öser de ur sin
        favoritrepertoar, till konsert och dans, i ett intensivt samspel för
        öron och ögon.
      </p>
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                med Lars-Gunnar Franzén, låtar från Västerdalarna och Värmland
              </small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                med Ingela och Håkan Lindberg, Orsapolska. Spelman, Björn
                Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Elika Frisell & Emma Reid</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Kristians kapell</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    date="2021-09-24"
    time="20:00"
    cost={180}
    costYoung={95}
    title="Ralsgård & Tullberg kvartett"
    img="img/arkiv/RT4_photo_Bojlund.jpg"
    imgLink="https://ralsgardtullberg.blogspot.com/"
  >
    <div>
      <p>
        Genom sin debutskiva (2010) återetablerade Ralsgård & Tullberg
        traversflöjten i den svenska folkmusiken. Med sin andra skiva (2014)
        utvecklade de flöjtens position i musiken genom en rad samarbeten med
        några av Sveriges främsta folkmusiker.
        <br />
        Dessa samarbeten ledde fram till bildandet av kvartetten där Ralsgård &
        Tullbergs traversflöjter sammanstrålar med nyckelharpa (Niklas Roswall)
        och cello (Alexandra Nilsson).
        <br />
        <br />
        Kvartetten gör det möjligt att realisera en större musikalisk vision där
        de två traversflöjternas variationsrika klanger möter den spänstiga
        virtuositeten hos strängmusikerna. Repertoaren består av traditionella
        och nyskrivna låtar. Uttrycken spänner över avskalade och intima
        vismelodier till flerstämmiga arrangemang där instrumentens möjligheter
        utnyttjas fullt ut.
        <br />
        <br />
        <center>
          <small>
            <i>
              Konserten kommer även livesändas, men ej sparas, på vår{" "}
              <a href="https://www.facebook.com/folkmusikkafeet">
                facebook-sida
              </a>
            </i>
          </small>
        </center>
      </p>
    </div>
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Konsert: Ralsgård & Tullberg kvartett</td>
        </tr>
        {/*  <tr>
  <td>I pausen</td>
  <td><small>I pausen spelar Dan Olsson och Lars-Gunnar Franzén</small></td>
  </tr>
*/}
      </table>
    </center>
  </Concert>,
  <Concert
    title="Spel- och danskväll [INSTÄLLD]"
    date="2021-09-17"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>
              <strike>Dans till spellista</strike> Inställt
            </td>
          </tr>
        </table>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2021-12-03"
    img="img/arkiv/spel_och_dans2-small.jpg"
  >
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:00-19:45</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Dans till spellista</td>
          </tr>
          <tr>
            <td></td>
            <td className="liten-text">
              Spellistan inleds med <b>Tageltrång</b>, <b>Kråkstråk</b> sedan{" "}
              <b>Mams & Paps</b>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <br />
    <center>Skjortor o Särkar har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-10-08"
    time="20:00"
    title="232 Strängar"
    cost={180}
    costYoung={95}
    img="img/arkiv/232-strangar_foto.jpg"
    imgLink="https://www.232strangar.com/"
  >
    <div>
      <p>
        <center>Kom och fira skivsläpp!</center>
        <br />
        I 232 strängars musik samsas tradition och improvisation i valser,
        polskor och slängpolskor som med full fart drar fram och tar publiken
        och dansgolvet med sig. Med fiol, piano, kohorn och röster möts de två
        musikerna Frida Höfling och Emma Engström i ett tätt och dansant samspel
        där det är nära till skratt och glädje, nära till funderingar och
        eftertänksamhet.
        <br />
        <br />
        Stämningen i den nordiska folkmusiken är hjärtat i musiken och dansen är
        drivkraften och här berättas historier mellan strängarnas klanger och
        kohornets vemodiga rop och råa styrka. Efter ett kritikerrosat
        debutalbum är nu deras andra album Svunnet ute och denna konsert blir en
        av de allra första releasekonserterna - så kom och fira skivsläpp med
        232 Strängar!
      </p>
    </div>
    <div className="pBody">
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Delsbostintan - och andra hälsingestintor, med Robert Qvist
              </small>
            </td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Polska för nybörjare under ledning av Maud Söderstedt och Mats
                Nilsson
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Konsert och dans: 232 Strängar</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>I pausen spelar Västkustsallad</small>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <br />
    <center>Mams o Paps har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-11-26"
    time="20:00"
    title="Högskolan för scen och musik"
    img="img/arkiv/VMHSM-small.jpg"
  >
    <div>
      <p>
        På{" "}
        <a href="https://www.gu.se/studera/hitta-utbildning/konstnarligt-kandidatprogram-i-musik-inriktning-varldsmusik-k1vmu">
          världsmusikprogrammet
        </a>{" "}
        i Göteborg möts en färgstark grupp individer med brett musikaliskt
        uttryck från olika delar av världen. Studenterna har fått möjligheten
        att tänka utanför sin egen musikaliska box och hitta nya sätt att
        uttrycka sig på, vilket kommer att vara uppenbart i denna konsert.
        <br />
        <br />
        Vare sig du vill lyssna till musik för att beröras på djupet eller för
        att ryckas med av danslust i kroppen så kommer denna upplevelse vara för
        dig!
      </p>
    </div>
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>under ledning av Mats Nilsson, slängpolska</small>
          </td>
        </tr>
        <tr>
          <td>20:00</td>
          <td>Konsert</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Världsmusikprogrammet, Högskolan för scen och musik</small>
          </td>
        </tr>
      </table>
    </center>
    <br />
    <center>Balkandansarna har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-10-15"
    time="20:00"
    title="McGlade’s House"
    cost={180}
    costYoung={95}
    img="img/arkiv/mcGladesHouse.jpeg"
    imgLink="https://www.timsig.se/"
  >
    <div className="pBody">
      <p>
        McGlade’s House består av den Irländsk-Norska sångerskan Karina och
        multi-instrumentalisten Serge.
        <br />
        De har båda två spelat i många år med olika välkända irländska band i
        Skandinavien, så som bland annat Tara och the Humdingers. De har också
        uppträd på alla relevanta festivaler i Skandinavien, samt på Shetlands,
        i Frankrike och på Irland.
        <br />
        <br />
        <small>
          <b>Karina McGlade-Grando</b>: sång, gitar, bodhran <br />
          <b>Serge McGlade-Grando</b>: mandolin, banjo, whistles, low whistles
          <br />
          <br />
          <center>Gästartister för kvällen:</center>
          <b>Lasse Jödahl</b> på bouzouki som är välkänd i den Göteborgska
          Irländska musikmiljön.
          <br />
          <b>Daniel Källgren</b> på fiol som är med i västkustbandet Alfheim.
          <br />
        </small>
      </p>
      <center>
        <p>
          Denna kväll anordnas av föreningen{" "}
          <a href="https://www.timsig.se/">TIMSIG</a> som en av konserterna
          under festivalen <a href="https://www.glenntown.se/">Glenntown</a> i
          Göteborg
        </p>
      </center>
    </div>
    <center>
      <table className="program-tabell">
        <tr>
          <td>18:15-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Irländsk set dance med Clement McKay</small>
          </td>
        </tr>
        <tr>
          <td>18:30-19:45</td>
          <td>Sångstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Sångstuga med Catherine MacHale Gunnarsson</small>
          </td>
        </tr>
        <tr>
          <td>18:30-19:45</td>
          <td>Låtstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Låtstuga med Fred Sörensson</small>
          </td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: McGlade’s House</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              <b>Biljetter krävs</b>.
            </small>
          </td>
        </tr>
        <tr>
          <td>21:10-22:00</td>
          <td>Céilí dance</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              Spel till Céilí dance under ledning av Clement McKay.{" "}
              <b>Biljetter krävs</b>.
            </small>
          </td>
        </tr>
      </table>
    </center>
    <br />
    <center>GNDS har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-09-03"
    time="18:00"
    title="GÖTEBORG FOLK FESTIVAL 2021"
    cost={200}
    costYoung={100}
    img="img/arkiv/Hedin_och_Meidell.jpeg"
    imgLink="https://www.hedinmeidell.com/"
    imgTwo="img/arkiv/vastra_latverkstan-groupa.jpg"
    imgTwoLink="https://groupa.se/"
  >
    <div>
      <br />
      <center>
        <b>Meidell & Hedin</b>
      </center>
      <br />
      Idas barockviolin och Johans olika nyckelharpor skapar ett resonant
      klangvärld när denna duo rör sig genom låttraditionens skiftande former.
      Musiken tar avstamp i 1700-talet, men sträcker sig ända in i vår tid. Den
      formas av Idas många spelsätt och influenser från när och fjärran, och av
      Johans unika sätt att ackompanjera och tänja på gränserna för
      nyckelharpans möjligheter. En musikalisk upplevelse där gamla låtar får
      nytt liv i täta arrangemang och ett innerligt samspel. Ida och Johan är
      också skickliga dansmusiker som gärna fortsätter en konsertkväll med sin
      dansrepertoar där det finns en dansintresserad publik.
      <br />
      <br />
      <center>
        <b>Västra låtverkstan</b>
      </center>
      <br />
      Västra låtverkstan är en ideell förening som huvudsakligen arbetar med
      ungdomskurser i folkmusik. De erbjuder en folkmusikkurs för ungdomar
      mellan 14-20 år där de träffas fyra helger under ett år och ger sig sedan
      ut på en turné.
      <br />
      <br />
      <center>
        <b>Groupa 40 år med Tina Quartey och Rickard Åström</b>
      </center>
      <br />
      Med sin helt unika form av nordisk folkmusik räknas Groupa till en av
      genrens absoluta supergrupper och har sedan 1981 stått i bräschen för den
      progressiva nordiska folkmusiken. I år firar Groupa 40 år på scen och har
      bjudit in nya och gamla gäster att spela under jubileumsturnén. Under
      Göteborg Folk Festival får vi besök av inga mindre än Tina Quartey och
      Rickard Åström som bland annat medverkade på den grammisvinnande plattan
      Imeland, 1994.
      <br />
      <br />
      <center>
        <small>
          Boka biljetter{" "}
          <a href="https://www.goteborgfolkfestival.com/f%C3%B6rk%C3%B6p-h%C3%A4r?fbclid=IwAR2KwVDJQ9k98O-juQOI_9boeXeS8xvWOz_FInnjMYyR3NiHmj7bI8jQCZM">
            här
          </a>
        </small>
      </center>
    </div>
    <center>
      <br />
      <table className="program-tabell">
        <tr>
          <td>18:00-18:45</td>
          <td>Låtstuga</td>
        </tr>
        <tr>
          <td>19:00</td>
          <td>Meidell & Hedin</td>
        </tr>
        <tr></tr>
        <tr>
          <td>20:30</td>
          <td>Västra Låtverkstan</td>
        </tr>
        <tr>
          <td>22:00</td>
          <td>Groupa 40 År med Tina Quartey och Rickard Åström</td>
        </tr>
      </table>
    </center>
  </Concert>,
  <Concert
    date="2021-11-19"
    time="20:00"
    title="Stordanskväll med Karin Wallin & Stina Brandin"
    cost={160}
    costYoung={85}
    img="img/arkiv/stina_brandin-small.jpg"
    imgTwo="img/arkiv/karin_wallin.jpg"
  >
    <center>
      <p>
        <b>Karin Wallin</b>
        <br />
        Uppvuxen bland spelande, sjungande och dansande släktingar började Karin
        Wallins intresse för folkmusiken tidigt. Sin främsta övning fick hon
        under sin fars, Börje Wallins, danskurser runt om i Norden då hon flera
        timmar i sträck bjöd på solospel och växte in i rollen som dansspelman.
        <br />
        <br />
        <b>Stina Brandin</b>
        <br />
        Stina kommer från en spelmansfamilj i det som hon själv kallar
        folkmusikens epicentrum; Boda Kyrkby i Dalarna. Hon har alltid sett sig
        själv som solospelman, och med Bodapolska som sin första och största
        kärlek. Drillar uppåt, drillar neråt, förslag, uppstråk, nerstråk- allt
        med stor bestämdhet. Envisheten och den klara viljan skiner igenom och
        kan höras i musiken.
      </p>
      <br />
      <center>
        Musiken direktsänds också på vårt{" "}
        <a href="https://fb.me/e/15W2jpAFt">Facebook event</a>. Vi värnar om
        dansarnas integritet och filmar därför inte de som dansar på plats.
      </center>
      <br />
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>under ledning av Mats Nilsson, menuett</small>
          </td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Karin Wallin & Stina Brandin</small>
          </td>
        </tr>
      </table>
      <br />
      <center>Skjortor o Särkar har hand om caféet denna kväll</center>
    </center>
  </Concert>,
  <Concert
    title="GKN5 + Fru Skagerrak"
    cost={180}
    costYoung={95}
    date="2021-10-22"
    time="19:30"
    img="img/arkiv/gkn5.jpg"
    imgLink="https://www.gurokviftenesheim.no/projects/gkn5"
    imgTwo="img/arkiv/fruskagerrak.jpg"
    imgTwoLink="https://fruskagerrak.com/"
  >
    <p>
      Fru Skagerraks musik tar dig med på en resa genom Skandinavien; från
      lågland till bergen, från långsamma melodier till brusande polskor, i dur
      och i moll, och allt däremellan. Fru Skagerrak består av tre musiker –
      Maja Kjær Jacobsen från Danmark, Elise Wessel Hildrum från Norge och Anna
      Lindblad från Sverige. Trion sammanför de olika traditionerna från vart
      och ett av deras hemland som omger Skagerrak och genom en repertoar av
      traditionella låtar, nya kompositioner och sånger på sina respektive språk
      uttrycker de släktskap och kärlek till deras gemensamma arv.
      <br />
      <br />
      GKN 5 (Guro Kvifte Nesheim kvintett) spelar låtar av spelmannen Salve
      Austenå från Tovdal i Åmli kommun i Norge samt egna låtar. De undersöker
      hur man kan bevara solokaraktären av Hardingfelaspelet i samspel. Deras
      sound kan beskrivas som en orkestrerad Hardingfela och grundtanken är att
      förstärka det som redan finns i låtarna.
      <br />
      <br />
      <small>
        GKN5 är:
        <br />
        <b>Guro Kvifte Nesheim</b> − hardingfele/hardanger d’amore
        <br />
        <b>Thomas Eriksson</b> − cittern
        <br />
        <b>Anna Malmström</b> − Klarinett/ basklarinett
        <br />
        <b>Jens Linell</b> − Slagverk
        <br />
        <b>Anna Gustavsson</b> − Nyckelharpa
        <br />
      </small>
    </p>
    <p>
      <b style={{ color: "red" }}>OBS!</b> Den här kvällen har vi en extra
      konsert kl 19.30. Därför börjar spelstugan redan kl 18.20.
    </p>
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:00-19:45</td>
          <td>Tillsammansgruppen</td>
        </tr>
        <tr>
          <td>18:20-19:20</td>
          <td>Spelstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Polkor ur Skogstorpatraditionen med Torbjörn Olofsson</small>
          </td>
        </tr>
        <tr>
          <td>19:30</td>
          <td>Fru Skagerrak</td>
        </tr>
        <tr>
          <td>20:15</td>
          <td>Konsert och dans: GKN5</td>
        </tr>
        <tr>
          <td>I pausen</td>
          <td>
            <small>
              I pausen spelar <b>Gôtt</b>
            </small>
          </td>
        </tr>
      </table>
      <p>
        Arrangemanget sker i samarbete med{" "}
        <a href="https://worldsessions.se/">World Sessions</a>, med stöd av
        Musik över Kattegatt och{" "}
        <a href="https://www.mcv.se/">Musikcentrum Väst.</a>
      </p>
    </center>
    <br />
    <center>Näverluren har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    title="Erika & Cecilia"
    cost={160}
    costYoung={85}
    time="20:00"
    date="2021-10-01"
    img="img/arkiv/erika_och_cecilia.jpg"
    imgLink="https://www.erikaochcecilia.se/"
  >
    <div className="pBody">
      Erika, fiol, och Cecilia, nyckelharpa, träffades 2002 och har båda sina
      rötter i den uppländska folkmusiken. Tillsammans har de gett ut fyra
      kritikerrosade CD, ”Pärlor” 2004, ”Örhängen” 2007, ”Polska till
      Vendelsjön” 2015 och nu senast "Allegro Violento" 2019, samt turnerat
      flitigt i Sverige och utomlands. Spelplatserna har varierat från bland
      annat festivalscener till radio- och TV-framträdanden. Duon har också
      samarbetat med musiker som Malena Ernman och Benny Andersson och ingår
      båda i gruppen <i>Systerpolskan</i>.
      <br />
      <br />
      Om sin musik säger Erika och Cecilia:
      <br />
      <br />
      <i>
        ”Vi strävar efter ett dynamiskt och tätt sammansvetsat samspel som
        bottnar i traditionen men där vi ständigt utmanar varandra till att
        skapa ett eget formspråk.”
      </i>
      <br />
      <center>
        <small>
          <br />
          <i>
            Konserten kommer även livesändas på vår{" "}
            <a href="https://www.facebook.com/folkmusikkafeet">facebook-sida</a>
          </i>
        </small>
      </center>{" "}
      <center>
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelmanslaget</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Låtar från Värmland och Västerdalarna med Lars-Gunnar Franzén
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Konsert och dans: Erika & Cecilia</td>
          </tr>
          <tr>
            <td>I pausen</td>
            <td>
              <small>
                I pausen spelar <b>Bagaregårdens spelmanslag</b>
              </small>
            </td>
          </tr>
        </table>
      </center>
    </div>

    <br />
  </Concert>,
  <Concert
    date="2021-11-12"
    time="20:00"
    title="Kolonien"
    cost={180}
    costYoung={95}
    img="img/arkiv/Kolonien.jpg"
    imgLink="https://www.kolonien.nu/"
  >
    <div>
      Familjekvartetten Kolonien jobbar nu på sitt tredje album som släpps 2021
      på bolaget <a href="https://supertraditional.com/">Supertraditional</a> i
      Skandinavien och internationellt på USA-baserade bolaget Cumbancha.
      Albumet är en personlig berättelse om 10 år tillsammans som band,
      svårigheter, och glädjen i att vara ett kollektiv och växa upp tillsammans
      men också en frustration över samhällets allt större fokus på individen
      och frågor om hur vi ska möta vår tids stora utmaningar.
      <br />
      <br />
      <i>
        "Kolonien skapar en egen värld på folkmusikkartan. En generös värld av
        nu och då, av lek och allvar” ”Vill mer med sin musik än att bara
        berätta en historia. Den här gruppen har en åsikt."
      </i>{" "}
      - Juryn, Folk- och Världsmusikgalan
      <br />
      <br />
      <small>
        Efter konsert och pausmusik spelar Kolonien 30 minuter till dans, och
        sedan blir det dans till föranmäld spellista. Föranmälan görs till
        li.samo@hotmail.com
      </small>
      <br />
    </div>
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              under ledning av Peter Nordqvist och Marie Schoonderwaldt, spelman
              Björn Jonsson, Polska från södra Dalarna
            </small>
          </td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Konsert och dans: Kolonien</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              I pausen spelar <b>Kalaspaff med Per</b>
            </small>
          </td>
        </tr>
      </table>
    </center>
    <br />
    <center>Magnus med vänner har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-11-05"
    time="20:00"
    title="Northern Resonance"
    cost={160}
    costYoung={85}
    img="img/arkiv/Northern_Resonance.jpg"
    imgLink="https://www.northernresonance.se/"
  >
    <div>
      I strävan efter det innersta uttrycket kombineras de unika egenskaperna av
      viola d’amoren, hardangerfelan och nyckelharpan. Northern Resonance är
      mötet där instrumentens potential och traditionernas gränser prövas för
      att bryta ny musikalisk mark.
      <br />
      <br />
      Med resonanssträngarna som den gemensamma nämnaren skapas en ljudbild som
      fritt rör sig mellan subtila detaljer och storslagna klanger. Northern
      Resonance består av de tre väletablerade musikerna Anna Ekborg, Jerker
      Hans-Ers och Petrus Dillner. Deras olika bakgrunder inom folkmusiken
      resulterar i briljerande kompositioner som på ett medryckande sätt fångar
      lyssnarens fulla fokus.
      <br />
      <br />
    </div>
    <center>
      <small>
        Denna kväll anordnas tillsammans med <br />
        <a href="https://rfod.se/">Riksförbunden för Folkmusik & Dans</a>
        <br />
        och är en del av <a href="https://planeta.se">Planetafestivalen</a>.
      </small>
      <br />
    </center>
    <center>
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>
              under ledning av Håkan och Ingela Lindberg, spelman Björn Jonsson,
              Norskleitjen
            </small>
          </td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Konsert och dans: Northern Resonance</td>
        </tr>
        <tr>
          <td>I pausen</td>
          <td>
            <small>
              I pausen spelar <b>Kristians Kapell</b>
            </small>
          </td>
        </tr>
      </table>
      <br />
      <small>
        <center>
          <a href="https://planeta.se">
            <img
              src="img/andra/planeta-logo.png"
              style={{ "max-width": "30%" }}
            ></img>
          </a>
        </center>
      </small>
    </center>
    <br />
    <center>Polskedanslaget har hand om caféet denna kväll</center>
  </Concert>,
  <Concert
    date="2021-10-29"
    time="20:00"
    title="Kulturnatta: Heldanskväll"
    img="img/arkiv/kulturnatta.png"
  >
    <center>
      <p>
        Välkomna till en helkväll för alla dansare. Vi har bokat tre av de bästa
        amatörgrupperna i Göteborg för att spela till dans från kl 20.00 till kl
        23.00. Triol, Höstrusk och Tagel & Co spelar två halvtimmar var. Vi kan
        räkna med att det blir mycket polskor men också några gammaldanser.
      </p>

      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelmanslaget</td>
        </tr>
        <tr>
          <td>18:45-19:45</td>
          <td>Dansstuga med Balkandansarna</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Danser från Balkan. Kopanitsa spelar.</small>
          </td>
        </tr>
        <tr>
          <td>20:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <small>Höstrusk, Tagel & Co och Triol</small>
          </td>
        </tr>
      </table>
    </center>
    <br />
    <center>Hisingens spelmansslag har hand om caféet denna kväll</center>
  </Concert>,
  <Concert date="2021-03-09" time="20:00" title="AXVY" img="img/axvy.jpg">
    <div>
      AXVY innehar ett brett spektrum av instrument och ljud som lägger en grund
      för en alldeles egen ljudbild där energin och innerligheten står i fokus.
      <br />
      <br />
      Bandmedlemmarna tillhör några av de mest ledande musikerna för sin
      generation och har trots sin unga ålder setts under lång tid på
      folkmusikscenen i grupper som Northern Resonance, Tailcoat, Lekarerätten,
      HRD, m.fl.
      <br />
      Den självbetitlade debutskivan släpptes 2019, inspelad i en stuga mitt i
      Hälsingland och starkt inspirerad av den närliggande naturens djupa skogar
      och sjöar.
      <br />
      <br />
      I kontrast till stugan i skogen så spelades singlarna ’’Höstdans’’ och
      ’’Älven/Lämnar Stan’’ in mitt i centrala Stockholm och släpptes 2020 med
      en bredare och djupare ljudbild.
      <br />
      <br />
      Konserten på Allégården blir AXVYs första framträdande på 1,5 år.
      Ersättare för Jerker blir Anna Ekborg från Northern Resonance på
      hardingfela och viola d’amore.
      <br />
    </div>
  </Concert>,
  <Concert date="2021-02-26" title="Trio Rop" img="img/Trio_Rop.jpg">
    <div>
      <center>Trio Rop är ett möte.</center>
      <br />
      Tre unika röster möts i ett rum där tiden står still. Genom samklang,
      dissonans, svängiga polskor och finstämd textläsning tar de publiken till
      en plats med motljus och vackra skuggningar. Gammal folktradition som
      berättar om urtida sägner får nytt liv i mötet med Trio Rops nyskrivna
      material.
      <br />
      <br />
      Med kraft och lekfullhet, viskningar och rop skapar de tillsammans ett
      unikt ljudlandskap med täta klanger där berättandet får stå i centrum.
      <br />
      <br />
      2:a april 2019 släppte Trio Rop sin debut-EP “<i>Hon kisar mot mig</i>”
      och kommer denna vinter spela in sin första fullängdsskiva.
    </div>
  </Concert>,
  <Concert
    date="2021-03-20"
    time="20:00"
    title="Anna & Ale Möller"
    img="img/Ale_&_Anna1.jpg"
  >
    <div>
      Fiol och mandola i efterlängtat möte Duon bjuder på favoriter ur
      låtrepertoaren, inte minst en drös spelade och ospelade Möller-låtar. Anna
      Möller är folkmusiker, kompositör och fiolspelman. De senaste åren har hon
      etablerat sig som en självklar del av den nya generationens folkmusiker
      genom projekt som Kolonien, storbandet Världens Band samt det nystartade
      skivbolaget Supertraditional.
      <br />
      <br />
      Ale Möller har länge varit en centralgestalt på den skandinaviska folk-
      och världsmusikscenen. Med folkmusiken som trampolin har han, med ett
      stort internationellt nätverk, gjort sig känd för nyskapande möten över
      kulturgränserna.
      <br />
      ”Jag och farsan brukar ju mest jamma hemma i köket”, säger Anna.{" "}
      <i>
        ”Så det känns väldigt kul att nu få kliva upp på en scen tillsammans vi
        två!”
      </i>
      <br />
      <br />
      Hålls på Facebook via denna länk:
      <br />
      <a href="https://www.facebook.com/events/222184279620168">
        https://www.facebook.com/events/222184279620168
      </a>
      <br />
      <br />
      Konserten ges i samarbete med Stallet
      <br />
    </div>
  </Concert>,
  <Concert
    date="2021-04-05"
    time="20:00"
    title="Grit Quartet"
    img="img/Grit_Quartet_bild_redigerad.jpg"
  >
    <div>
      <center>Välkommna på vår fredagskonsert!</center>
      <br />
      Denna kväll sänder vi live från inget mindre än Sockerbruket Arena. Vi
      tackar Nikolas Gialtrinos för denna möjlighet!
      <br />
      <br />
      Grit Quartet består av <b>Jonathan Larsson</b> (dragspel),{" "}
      <b>Guro Kvifte Nesheim</b> (hardanger d’more), <b>Oskar Reuter</b>{" "}
      (nyckelharpa) och <b>Anna Larsson</b> (gitarr, sång).
      <br />
      Oskar och Jonathan har flera gånger blivit hyllade för sina begåvade sätt
      att skriva ny musik. Efter att Anna och Guro medverkat på Oskar &
      Jonathans duoskiva, Panna mot Panna som släpptes under våren 2019 valde de
      att fortsätta som kvartett och Grit Quartet var bildat.
      <br />
      <br />
      Även om bandet är helt nystartat har man tidigare kunnat höra medlemmarna
      i andra konstellationer såsom Goodland trio, Kongero, GKN5, Västanå
      teater, Kultiration med flera.
      <br />
      <br />
      Citat ur Lira våren 2019: "
      <i>
        Tillsammans med en rad utsökt talangfulla gästmusiker skapar de
        nyskriven, glad och medryckande musik. Samspelet går smittande lätt, så
        att den som dansar gör det på lika lätta fötter
      </i>
      ".
      <br />
    </div>
  </Concert>,
  <Concert
    date="2021-04-23"
    time="20:00"
    title="Karlsson & Rubinsztein"
    img="img/Rubinsztein_Karlsson_1.jpg"
  >
    <div>
      Anna Karlsson och Anna Rubinsztein är duon med stark förkärlek för låtar
      från Värmland. De har följts åt sedan 2012 och spelat ihop i många olika
      format och konstellationer, men med sin kärlek till gränstraktens musik
      som ledstjärna. Efter många år med låtar efter sina förebilder har de
      under det gångna året grävt i arkiv och notböcker. Till Göteborg kommer de
      med en repertoar bestående av gamla favoritlåtar och nyfunna guldkorn.
      <br />
      <br />
      <b>Anna Karlsson</b> - Viola D’amore och moraharpa
      <br />
      <b>Anna Rubinsztein</b> - Viola D’amore
      <br />
      <br />
    </div>
  </Concert>,
  <Concert
    title="Rå: Cecilia Moore, Anna Frykstam och Christine Tilby"
    date="2020-01-24"
    img="img/arkiv/raa-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Karin & co
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Delsbostintans visor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Robert Qvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Slängpolska på fläck </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Frida Johansson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Rå: Cecilia Moore, Anna Frykstam och Christine Tilby{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          RÅ består av tre musiker från tre olika och rika folkmusiktraditioner:
          Jylland i Danmark, Skåne och Västerbotten - som möts och skapar en
          bubblande, sprudlande och färgsprakande repertoar av nordisk folkmusik
          som den aldrig har hörts förr.
          <br />
          Christine Tilby, fiol
          <br />
          Anna Frykstam, klarinett
          <br />
          Cecilia Moore, saxofon
          <br />
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Dan Olsson och Lars-Gunnar Franzén</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Elmøe & Hoffman"
    date="2020-01-31"
    img="img/arkiv/Elmoe_och_Hoffman-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs internationella folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Trisspolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar från Västerdalarna och Värmland{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lars-Gunnar Franzén</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Elmøe & Hoffman </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Med kärlek till akustiska instrument bjuder Elmøe & Hoffmann lyssnaren
          med in i deras eget äventyrliga universum inspirerad av filmmusik,
          kammarmusik och traditionella skandinaviska danser. Emma Kragh-Elmøe:
          fiol, Villads Hoffmann: cittern samt Julian Jørgensen: keyboards.
          Albin Ekman gästar under dansspelet.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kalaspaff med Per</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2020-02-07"
    img="img/arkiv/spel_och_dans1-small.png"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Dansstuga: Trinnpolska - snabb polska på fläck{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mats Nilsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Höstrusk, följt av Triol</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Ulrika Bodén, Daniel Ek och Niklas Roswall"
    date="2020-02-14"
    img="img/arkiv/ulrika_daniel_och_niklas-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.naverluren.se/">Folkdanslaget Näverluren</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Håkan och Ingela Lindberg. Polskekurs för vana dansare
        (kursstart). Mer information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Dansteknik polskeomdansning </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Visor om kråkfåglar </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Ulrika Bodén, Daniel Ek och Niklas Roswall{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Ulrika Bodén har de ångermanländska visorna nära hjärtat. Med sitt
        uttrycksfulla sångsätt räknas hon till en av landets främsta
        folksångerskor. Med sig har hon favoritmusikerna Daniel Ek på harpgitarr
        och Niklas Roswall på nyckelharpa. Tillsammans är de en väl
        sammansvetsad trio. Konserten gör avstamp i prisade albumet "Te berga
        blå", en personlig och passionerad hyllning till vallmusiken.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bockeluras</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="D'Amore. Pelle Björnlert med vänner"
    date="2020-02-21"
    img="img/arkiv/damore-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Schottis </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Daniel Wallenius och Siri Berg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Håkan och Ingela Lindberg. Polskekurs för vana dansare.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar efter Skräddarn August Johansson från Sibbajorden i
        Bergum{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Torbjörn Olofsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: D'Amore. Pelle Björnlert med vänner{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        D’amore, av kärlek. Musik av Pelle Björnlert – "Traditionen att ge bort
        låtar tycker jag är fin". Många av kompositionerna är dedicerade till
        någon person eller plats och alla är gjorda med kärlek.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Svenning och Gustav Widell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll. Årsmöte"
    date="2020-02-28"
    img="img/arkiv/snabb_dans.png"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Dansstuga: Trinnpolska - snabb polska på fläck{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mats Nilsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Höstrusk, följt av Triol</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Beata Bermuda"
    date="2020-03-06"
    img="img/arkiv/beata_bermuda-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Håkan och Ingela Lindberg. Polskekurs för vana dansare.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Polska för nybörjare </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Visstuga: Visor ur Eva Tjörnebos visskatt{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Anni Olofsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Beata Bermuda </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Beata Bermuda har etablerat sig om en frisk fläkt på den svenska
        folkmusikscenen. Deras sprudlande liveframträdanden har blivit väl
        mottagna både i Europa och USA. Egenkomponerad musik på fiol, sång och
        klockspel med grunden i skandinavisk folkmusik och drag av indiepop har
        gett Beata Bermuda deras egna unika röst – en röst som talar om feminism
        och tolerans.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Västkustsallad</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,

  <Concert
    title="Stordanskväll med Emma Reid och Ellika Frisell samt Hadrian Prett"
    date="2020-03-13"
    canceled
  ></Concert>,
  <Concert
    title="Alban Faust och Dalslands spelmansorkester"
    date="2020-03-13"
    canceled
  ></Concert>,
  <Concert title="Sirus" date="2020-03-27" canceled></Concert>,
  <Concert title="Spel- och danskväll" date="2020-04-03" canceled></Concert>,
  <Concert title="Anna & Ale Möller" date="2020-04-17" canceled></Concert>,
  <Concert
    title="Spelmansstämma och danskväll"
    date="2020-04-24"
    canceled
  ></Concert>,
  <Concert
    title="Viskurs med Maria Röjås"
    date="2020-04-25"
    canceled
  ></Concert>,

  <Concert
    title="Lyset i forsen"
    date="2020-09-25"
    img="img/arkiv/lyset_i_forsen-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Konsert: Lyset i forsen </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Lyset i Forsens musik beskrivs som uppkäftig, intelligent och vacker,
        och började gro i Dalarnas låtspelstradition. Därifrån har den tagit nya
        nyanser som ger dynamik åt gamla synsätt och bjuder upp fantasin.
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Emma Reid & Mia Marine"
    date="2020-10-02"
    img="img/arkiv/mia_marine-small.jpg"
    imgTwo="img/arkiv/emma_reid-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Konsert: Emma Reid och Mia Marine </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Emma Reid spelar gärna låtar från Dalarna. Mia Marine från Arvika med
          sina femsträngande fioler spelar gärna dansmusik med härlig känsla,
          men nu får ni sitta still!
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Jaerv" date="2020-10-16" img="img/arkiv/jaerv-small.jpg">
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Konsert: Jaerv </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Med flera utlandsturnéer i bagaget och som en av huvudakterna på SVT:s
        årliga midsommarkonsert 2020 är JAERV redo att inta scenen på Allégården
        igen. Mycket sång, finstämd tramporgel och maffigt percussion utlovas.
        <br />
        Joel Hagen - Flöjt, whistles, sopransax, sång
        <br />
        Markus Gustavsson – Fiol, sång
        <br />
        Anders Bergsten – Nyckelharpa, bas, sång <br />
        Harald Nilsson – Gitarr, sång
        <br />
        Tobias Hedlund – Slagverk, tramporgel, sång
        <br />
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Duo Disparat"
    date="2020-10-23"
    img="img/arkiv/duo_disparat-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Konsert: Duo Disparat </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Bordunmusikern Anders Norudde är känd av många från gruppen Hedningarna
        men han har även gjort sig ett namn som en spännande låtkompositör. Han
        bildar, tillsammans med speldamen med rötter från Delsbo, Johanna
        Karlsson, en duo med egensinniga låtar som spelas på femsträngade
        fioler, omstämda fioler och moraharpa. Det blir en uppsjö av vackra
        klanger.
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Mats Berglund & Zara Helje"
    date="2020-10-30"
    img="img/arkiv/mats_berglund-small.jpg"
    imgTwo="img/arkiv/zara_helje-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">
        Konsert: Solokonserter med Zara Helje och Mats Berglund{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Zara Helje med rötterna i Göteborg men numera spelledare i Malungs
        spelmanslag och naturaliserad dalkulla spelar låtar från västerdalarna.
        Mats Berglund från västra värmland spelar låtar just därifrån. Det blir
        svårt att sitta still när det vankas halling! <br />
        <br />
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Northern Resonance" date="2020-11-06" canceled>
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Konsert: Northern Resonance [INSTÄLLT] </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <br />
        <b>INSTÄLLT!</b>
        Tyvärr är kvällens konsert med Northern Resonance inställd pga sjukdom i
        bandet.
        <br />
        /Styrelsen
        <br />
        <br />
        "Det skimrar och gnistrar om de tre klangkropparna när de i samförstånd
        slingrar sig till en mäktig, överjordisk varelse. Lägg Northern
        Resonance på minnet! Något nytt har kommit för att stanna!" - Mikael
        Marin (Väsen)
        <br />
        <br />
        I strävan efter det innersta uttrycket kombineras de unika egenskaperna
        av viola d’amoren, hardangerfelan och nyckelharpan. Northern Resonance
        är mötet där instrumentens potential och traditionernas gränser prövas
        för att bryta ny musikalisk mark.
        <br />
        Med resonanssträngarna som den gemensamma nämnaren skapas en ljudbild
        som fritt rör sig mellan subtila detaljer och storslagna klanger.
        <br />
        Northern Resonance är:
        <br />
        Jerker Hans-Ers: Hardingfela
        <br />
        Anna Ekborg: Viola d'amore
        <br />
        Petrus Dillner: Nyckelharpa
        <br />
        <br />
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Ralsgård & Tullberg" date="2020-11-13" canceled>
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">
        Konsert: Ralsgård och Tullberg [INSTÄLLT]{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <br />
        <b>INSTÄLLT!</b>
        <br />
        <br />
        Tråkigt nog får vi ställa in även nu på fredag. Styrelsen har beslutat
        att det är det bästa alternativet nu när sjukdomsfallen ökar igen.
        <br />
        Vi längtar efter er alla!
        <br />
        <br />
        Genom sin debutskiva (2010) återetablerade Ralsgård & Tullberg
        traversflöjten i den svenska folkmusiken. Med sin andra skiva (2014)
        utvecklade de flöjtens position i musiken genom en rad samarbeten med
        några av Sveriges främsta folkmusiker. Dessa samarbeten ledde fram till
        bildandet av kvartetten där Ralsgård & Tullbergs traversflöjter
        sammanstrålar med nyckelharpa (Niklas Roswall) och cello (Alexandra
        Nilsson). Kvartetten gör det möjligt att realisera en större musikalisk
        vision där de två traversflöjternas variationsrika klanger möter den
        spänstiga virtuositeten hos strängmusikerna. Repertoaren består av
        traditionella och nyskrivna låtar. Uttrycken spänner över avskalade och
        intima vismelodier till flerstämmiga arrangemang där instrumentens
        möjligheter utnyttjas fullt ut.
        <br />
        <br />
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Världsmusiklinjen på Högskolan för scen och musik i Göteborg"
    date="2020-11-27"
    img="img/arkiv/VMHSM-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00</div>
      <div className="pSlotBigR">Kaféet öppnar </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">
        Konsert: Världsmusiklinjen på Högskolan för scen och musik i Göteborg{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        På Världsmusiklinjen möts en färgstark grupp individer med brett
        musikaliskt uttryck från olika delar av världen. Studenterna har fått
        möjligheten att tänka utanför sin egen musikaliska box och hitta nya
        sätt att uttrycka sig på, vilket kommer att vara uppenbart i denna
        konsert. Vare sig du vill lyssna till musik för att beröras på djupet
        eller för att ryckas med av danslust i kroppen så kommer denna
        upplevelse vara för dig!
        <br />
        <br />
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Systerpolskan"
    date="2019-01-25"
    img="img/arkiv/systerpolskan-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">Samarbete</div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.naverluren.se/">Folkdanslaget Näverluren</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Polska för nybörjare </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Maud Söderstedt</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Visor från Dalsland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Dalbris</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Systerpolskan </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Systerpolskan bildades år 2016 på initiativ av Benny Andersson. Samma
          år släpptes deras första album ”Randig kjortel”. Deras musik
          förknippas med medryckande melodier och mycket spelglädje. Gruppen
          består av flera av Upplands och Dalarnas bästa spelmän. Samarbetet med
          Benny Andersson har gett gruppen en publicitet som folkmusiker
          vanligtvis inte får. Gruppens medlemmar: Cajsa Ekstav, Cecilia
          Österholm och Erika Lindgren Liljestolpe från Uppland, samt Täpp Ida
          Almlöf och Verf Lena Egardt från Dalarna.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Per Gudmundson och Bengan Janson"
    date="2019-02-01"
    img="img/arkiv/per_gudmundsson_och_bengan_jansson-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs Internationella Folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Polska för nybörjare </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Maud Söderstedt</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Rättvik </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Dan Palmqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Per Gudmundson och Bengan Janson{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Per Gudmundson och Bengan Janson är båda välmeriterade folkmusiker.
          Fiol- och ibland säckpipespelaren Gudmundson har sedan sjuttiotalet
          medverkat på skivor tillsammans med bland andra Folk & Rackare, Kjell
          Westling och Kirsten Bråten Berg, medan dragspelaren Janson har ett
          illustert förflutet tillsammans med exempelvis Östen med resten, Kalle
          Moraeus, Lena Willemark och Ulf Wakenius. Bägge har spelat med Björn
          Ståbi och har, inte oväntat, också figurerat i den rymliga kretsen
          kring Ale Möller.
        </p>
        <div className="clear"></div>
        <div className="pSlotSmallL">I pausen</div>
        <div className="pSlotSmallR">
          <p>
            I pausen spelar{" "}
            <b>
              Lars-Gunnar Franzén och Dan Olsson samt (efter 22:30) Svenning och
              Gustav Widell
            </b>
            .
          </p>
        </div>
      </div>
    </div>
  </Concert>,
  <Concert
    title="Trio Törn"
    date="2019-02-08"
    img="img/arkiv/trio_torn-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Isa Holmgren - fördjupning i polskedans (kursstart). Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Visor om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Trio Törn </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Trio Törn, en trio som med instrumenten nyckelharpa, cello och fiol
          har skapat ett nytt och spännande sound. Tre starka personligheter med
          rötterna i den svenska folkmusiken som ständigt experimenterar med
          instrumentens möjligheter och som gång på gång provar traditionens
          gränser. När de tre tillsammans sätter ton uppstår en blandning av
          färgstarka melodier, fängslande harmonier, ett ohämmat sväng, groove
          och innerlighet. Detta är ett nyskapande musikaliskt möte med stor
          plats för spontana idéer och improvisation som resulterar i en
          originell helhet. Petrus Dillner – nyckelharpa; Klara Källström –
          cello; Olof Kennemark – fiol
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Isa Holmgren med flera.</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel och danskväll"
    date="2019-02-15"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="https://hisingensspelmanslag.wordpress.com/">
            Hisingens spelmanslag
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Bondpolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Värmland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Hans Grönlund</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Håkan Bengtsson och Kajsa Karlsson och sedan
          följer Höstrusk
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Rydvall/Mjelva"
    date="2019-02-22"
    img="img/arkiv/rydvall_mjelva-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Bagaregårdens spelmanslag
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Isa Holmgren - fördjupning i polskedans{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Medeltida ballader </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Rydvall/Mjelva </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Folkmusik framförd på nationalinstrumenten nyckelharpa och hardingfela
          av svensknorska duon Erik Rydvall och Olav Luksengård Mjelva med en
          fantastisk klangfärg. Erik Rydvall kommer från Västerbotten och är
          känd för sin virtuositet och energi. Olav Luksengård Mjelva från Röros
          är en av Norges mest kompetenta fiolspelare. Samspelet mellan dessa
          två unga musiker är makalöst. Låtarna är till stor del traditionella.
          Denna konsert- och danskväll är en del i release-turnén för den nya
          skivan "Vårvindar Friska".
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Dans spelmän</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll. Årsmöte"
    date="2019-03-01"
    img="img/arkiv/spel_och_dans2-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Lagmusikerna
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">
        Årsmöte. Årsmöteshandlingar finns <a href="arsmote2019.html">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Isa Holmgren - fördjupning i polskedans{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Isa Holmgren med flera och sedan följer Triol
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Mats Edén och Leif Stinnerbom"
    date="2019-03-08"
    img="img/arkiv/leif_och_mats-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Polskekurs med Håkan och Ingela Lindberg (kursstart). Mer information
        finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Spelstuga: Trulsevalsen efter Bernhard Karlsson Lerum med Torbjörn
        Olofsson. Alla instrument är välkomna men har du durspel ska du ha ett
        som är stämt i G/C.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Mats Edén och Leif Stinnerbom{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Det har gått några månvarv sedan det gamla radarparet Mats Edén och
          Leif Stinnerbom höll på som värst med Groupa. Ja, Mats är ju
          fortfarande aktiv där medan Leif mest håller till i Berättarladan med
          Västanå Teater i Sunne dessa dagar. Leif Stinnerbom och Mats Edén har
          under fyra decennier haft en enorm påverkan på den värmländska
          folkmusiken. Nu bjuder de än en gång upp till dans med det driviga och
          dansanta spel som är deras signum!
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kristians kapell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Bäckafall"
    date="2019-03-17"
    img="img/arkiv/backafall-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">
        Fiolkurs för vuxna nybörjare (kursstart). OBS! Fulltecknad. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Bäckafall </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Bäckafall är en nybakad duo bestående av Samuel Lundh, nyckelharpa och
        Hampus Grönberg, gitarr. Musiken är helt och hållet egna kompositioner
        kring vilka det improviseras en hel del. Från Irland till Haiti, från
        Mali till Älvdalen. Influerade av världen, med klangbotten i svensk
        spelmanstradition framför de egenkomponerad och traditionell musik.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bagaregårdens spelmanslag</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Stordanskväll med Mia Marin"
    date="2019-03-22"
    img="img/arkiv/mia_marin-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.skjortorochsarkar.se/">Skjortor och Särkar</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">Fiolkurs för vuxna nybörjare. </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;23:30</div>
      <div className="pSlotBigR">Stordanskväll </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Dans hela kvällen till följande spelmän och grupper:
        <br />
        <b>19:00&ndash;20:30 Mia Marin</b>
        <br />
        Mia Marin, femsträngad fiol, är mest känd som en fantastisk fiolspelman
        i ett flertal grupper såsom MP3 och NID. Hon är även en mycket bra
        dansspelman och vi kan bland annat se fram emot värmländska polskor av
        olika slag, kort etta, kort trea, triolpolskor och så vidare.
        <br />
        <b>20:30&ndash;21:00 Kråkstråk</b>
        <br />
        Kråkstråk är en folkmusikgrupp från Mölndal som spelar låtar från bland
        annat Halland och Västergötland.
        <br />
        <b>
          21:00&ndash;22:30 Mia Marin
          <br />
          22:30&ndash;23:00 Lars-Gunnar Franzén
        </b>
        <br />
        Lars-Gunnar spelar främst låtar från Värmland och Västerdalarna på sina
        blockflöjter.
        <br />
        <b>
          23:00&ndash;23:30 Spellista
          <br />
        </b>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Stina Brandin och Johanne Flottorp"
    date="2019-03-29"
    img="img/arkiv/stina_brandin-small.jpg"
    imgTwo="img/arkiv/johanne_flottorp-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Spelgruppen Mams & Paps
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">Fiolkurs för vuxna nybörjare. </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Västerdalarna </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lars-Gunnar Franzén</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Stina Brandin och Johanne Flottorp{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Ikväll blir det två solokonserter och därefter spelar Stina och
          Johanne var för sig till dans.
          <br />
          <br />
          Stina Brandin har studerat fiol och svensk folkmusik på Malungs
          folkhögskola, Ingesunds musikhögskola och på Kungliga Musikhögskolan i
          Stockholm. De två senaste åren har Stina fördjupat sig i hardingfela
          och norsk folkmusik och speciellt Setesdalsmusik.
          <br />
          <br />
          Johanne Flottorp från Åmli i Aust-Agder är en mångsidig musiker och
          solist på hardingfela. Hennes spelstil är både energisk och kraftfull
          kombinerat med det ömtåliga och ömma.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2019-04-05"
    img="img/arkiv/spelmansstaemma-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">Fiolkurs för vuxna nybörjare. </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:15&ndash;23:00</div>
      <div className="pSlotBigR">
        Spelmansstämma i hela huset med buskspel i stora salen och i kaféet.
        Allspel 20:00. Allspelslåtar finns{" "}
        <a href="html/allspel/allspel_vt2019.html">här.</a>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:30&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista i pelarsalen </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        20:30&ndash;21:00 Tageltrång
        <br />
        21:00&ndash;21:30 Joar Skorpen med vänner
        <br />
        21:30&ndash;22:00 Gorm
        <br />
        22:00&ndash;22:30 Spelmanslaget
        <br />
        22:30&ndash;23:00 Spellista
        <br />
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">21:00&ndash;21:30</div>
      <div className="pSlotBigR">Visstuga med Henrik Åberg </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Boot - Ola Bäckström, Samuel Andersson, Totte Matsson"
    date="2019-04-12"
    img="img/arkiv/boot-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">Fiolkurs för vuxna nybörjare. </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Polskor från Malung och Ore </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Bohuslän </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Malin Klittvall</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Boot - Ola Bäckström, Samuel Andersson, Totte Matsson{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        En trio med ett brett klangspektrum. Från skira klanger ur barockluta
        och viola dámore, till knotiga polskor på träfiol och mandora, allt i
        ett tätt samspel med dynamisk slagverk. Boot består av tre välkända
        musikanter som förekommer i flera grupper: Ola Bäckström (Swåp och
        Triptyk), Hållbus Totte Matsson (Hedningarna och Hurdy-Gurdy Project),
        Samuel Andersson (Godrun och Punsch) Tillsammans botaniserar de friskt i
        den vildvuxna låtvegetationen, och väljer gärna sådant som vuxit i
        Dalarna, och en hel del nykomponerat av medlemmarna i gruppen.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Västkustsallad</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Hallandsfolk och Västra låtverkstan"
    date="2019-04-26"
    img="img/arkiv/hallandsfolk-small.jpg"
    imgTwo="img/arkiv/vastra_latverkstan-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Lilian Håkansson & co
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Isa Holmgren - fördjupning i polskedans. Extratillfälle.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Hallandsfolk och Västra Låtverkstan{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Virvlande vital folkmusik från ungdomarna i Hallandsfolk och Västra
        Låtverkstan. Rockiga riff och traditionella låtar i en oslagbar
        kombination. En konsert- och danskväll med två svängiga ensembler där
        låtspel är den gemensamma nämnaren. Under ledning av Greger Siljebo,
        Anders Ådin och Jenny Gustafsson
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Lom södra</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Göteborg Folk Festival"
    date="2019-09-06"
    img="img/arkiv/gbg_folk_festival-small.jpg"
    imgTwo="img/arkiv/hemliga_gasten-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">18:30&ndash;01:00</div>
      <div className="pSlotBigR">Jam hela kvällen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;20:00</div>
      <div className="pSlotBigR">Dansworkshop med Daniel och Emelie </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;21:00</div>
      <div className="pSlotBigR">Konsert World Wide Orchestra </div>
      <div className="clear"></div>
      <div className="pSlotBigL">21:00&ndash;01:00</div>
      <div className="pSlotBigR">
        Musikkollektivet Hemliga gästen inleder dansspelet. Därefter spellista{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigR"> </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          För allra första gången, mellan 6 − 8 september 2019, arrangeras
          Göteborg Folk Festival, full med workshops, konserter, spel till dans,
          panelsamtal, jam och mer därtill! Göteborg Folk Festival är ett
          samarbete mellan föreningarna Klubb Ankaret, Folkmusiker mot Rasism,
          Folkklubb och Folkmusikkaféet Allégården. Festivalen inleds på
          Allégården med spel till dans, dansworkshop och konsert! På fredagen
          är det fritt inträde. För 150 kr får du 2 dagars entré - lördag och
          söndag, om du är medlem i FMK och/eller Klubb Ankaret. Se Göteborg
          Folk Festivals Facebooksida för mer info.
        </p>
        <a href="http://www.facebook.com/gbgfolkfestival">
          www.facebook.com/gbgfolkfestival
        </a>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Floating Sofa Quartet"
    date="2019-09-13"
    img="img/arkiv/floating_sofa_quartet-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Superduper
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Kadrilj </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Floating Sofa Quartet</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Floating Sofa Quartet </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Floating Sofa Quartet är en modern nordisk folkmusikgrupp bestående av
          fyra unga musiker från Danmark, Sverige och Finland. Med en unik
          kombination av instrument utför denna kvartett handgjord musik med
          doft av blöta tallar, salt havsbris och nybryggt kaffe. Deras
          repertoar är en spännande blandning av egna kompositioner och
          traditionella stycken. Mads Kjøller-Henningsen, Danmark – flöjt och
          svensk säckpipa; Leija Lautamaja, Finland – durspel och tramporgel;
          Clara Tesch, Danmark – fiol; Malte Zeberg, Sverige – basfiol
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Dan Olsson och Lars-Gunnar Franzén</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2019-09-20"
    img="img/arkiv/spel_och_dans1-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.skjortorochsarkar.se/">Skjortor och Särkar</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Delsbostintans visor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Robert Qvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Triol, följt av Perssons, följt av Tagel & co
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Ellinor och Leonor"
    date="2019-09-27"
    img="img/arkiv/ellinor_och_leonor-small.png"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Erik Mägi och Emma Rydberg samt Samuel Lundh - fördjupning
        i polskedans (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Trisspolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Glada visor om sorgeliga saker </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Ellinor och Leonor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Med fiol och femsträngad cello tar sig riksspelmannen Ellinor Fritz och
        cellisten Leonor Palazzo an låtar från Dalarnas bergslag på ett nytt,
        dansant och stilfullt vis. De väcker liv i låtskatter såsom polonäser
        från 1800-talets Falun, gammeldans från Dala-Floda samt pärlor efter
        spelmän från Hedemora och Säter.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kristians kapell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Breda gatan"
    date="2019-10-04"
    img="img/arkiv/breda_gatan-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Erik Mägi och Emma Rydberg samt Samuel Lundh - fördjupning
        i polskedans{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Polska från Skepplanda </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Västergötland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Annelie WesterLund</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Breda gatan </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          I maj 2019 såg ett nytt folkmusikband dagens ljus. Då släpptes Breda
          gatans första album. Trots debuten så är det inga nykomlingar som
          beger sig ut på vägarna. Kvartetten består av fyra starka
          personligheter med stor utstrålning och erfarenhet, som har slagit sig
          samman för att framföra visor med humor, svårmod och berättelsen i
          fokus. Bakom namnet hittar vi folksångerskorna Emma Härdelin och
          Johanna Bölja Hertzberg, fiolspelmannen Kjell-Erik Eriksson och Ola
          Hertzberg på nyckelharpor, så räkna med underskön sång och tätt
          samspel.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Fru Salt</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll. En del av Glenntown Irish Music Festival"
    date="2019-10-11"
    img="img/arkiv/glenntown-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Spelgruppen Mams å Paps
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Danskurs med Erik Mägi och Emma Rydberg samt Samuel Lundh - fördjupning
        i polskedans{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Irländsk set dance </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Clement McKay</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Irländska jigs och reels </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Fred Sörensen</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:45</div>
      <div className="pSlotBigR">
        Visstuga: Sånger på gaeliska och engelska{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Catherine MacHale Gunnarsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Höstrusk, sedan följer Gôtt.</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Mats Berglund och Jenny Gustafsson"
    date="2019-10-18"
    img="img/arkiv/mats-small.png"
    imgTwo="img/arkiv/jenny-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs internationella folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Hälsingedanser </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Med Kenneth Henriksson och Gunilla Lagesjö. Spelman: Håkan Bengtsson
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar från Västerdalarna och Värmland{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lars-Gunnar Franzén</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Mats Berglund och Jenny Gustafsson{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Mats Berglund har i många år varit en av de mest säregna rösterna på
          den svenska folkmusikscenen. Som dansspelman har han gjort sig
          oumbärlig som en av den värmländska musikens viktigaste ambassadörer.
          Med sin personliga spelstil är han idag en av landets mest
          igenkännbara spelmän. Jenny Gustafsson, riksspelman på fiol, spelar
          traditionella västgötalåtar med virtuositet och innerlighet i
          spännande arrangemang. 2013 startade hon Ornungastämman, en
          folkmusikfestival som genomförs i augusti varje år, ett projekt med
          både regional och kommunal förankring med stora visioner.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Västkustsallad</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Vågspel"
    date="2019-10-25"
    img="img/arkiv/isa-small.png"
    imgTwo="img/arkiv/vagspel-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är en del av:
          <br />
          <a href="http://kulturnatta.goteborg.se">Kulturnatta</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.skjortorochsarkar.se/">Skjortor och Särkar</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Danser från Balkan </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Katrin Rigstedt. Musik: Kopanitsa</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Trulsevalsen efter Bernhard Karlsson, Lerum.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Torbjörn Olofsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;20:00</div>
      <div className="pSlotBigR">Kafékonsert med Isa Holmgrens duo </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Isa Holmgren utforskar på röst det magiska svänget och melodierna i
        gränslandsmusiken. Med sina rötter i dansmusiken från Värmland blandar
        hon låtar från västra Värmland och gränslandet med egenskriven
        folkmusik. Under kvällens framträder hon tillsammans med den
        kanadensiska kontrabasisten Robert Alan Mackie.
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Vågspel </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Alice Klint och Kristin Kennemark är fiolduon som växte upp på
        västgötaslätten, på samma gata och med samma förväntningar på livet.
        Folkmusik var startpunkten för en livslång vänskap och ett musikaliskt
        samarbete, med både tradition och egenskrivna melodier i sin repertoar.
        Vågspel är duon som tar partypolskan till en ny nivå.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bagaregårdens spelmanslag</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Rim" date="2019-11-01" img="img/arkiv/rim-small.png">
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är en del av:
          <br />
          <a href="http://planeta.se">Planeta festival</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">
        Polskekurs med Håkan och Ingela Lindberg (kursstart). Mer information
        här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Vals </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Rebecka Nestlog</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Visor om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: RIM </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Rim presenterar ett drivande och delikat ljudlandskap där de kombinerar
        dansmusik med improvisation och egna kompositioner. I lekfulla samtal
        växlar musikerna mellan solistiska inslag och tätt samspel. Deras
        sceniska uttryck är allsidigt med rörelse, röster, dans och historier.
        Med sin närvaro på scenen och sitt kraftfulla musicerande vill Rim locka
        publiken ut på nya stigar. Rim är Hilde Fjerdingøy på durspel och sång,
        Sunniva Abelli på nyckelharpor och sång, Elin Jonsson på fiol, viola och
        sång och Jo Einar Jansen på fioler och sång.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Dans spelmän</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Nordic Fiddlers Bloc"
    date="2019-11-08"
    img="img/arkiv/nordic_fiddlers_bloc-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Black Nails
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        <del>Dansstuga: Polska för nybörjare</del>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          <del>Med Rebecka Nestlog</del>
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Värmland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Hans Grönlund</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">Konsert och dans: Nordic Fiddlers Bloc </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          The Nordic Fiddlers Bloc består av Olav Luksengård Mjelva (Norge),
          Anders Hall (Järvsö) och Kevin Henderson (Shetlandsöarna). Tre
          folkmusiker som har turnerat tillsammans sedan 2009 och har tagit sin
          unika mix av folkmusik till många delar av världen.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Mams & Paps</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Stordanskväll med Jonas Hjalmarsson och Fredrik Lundberg samt Erika Lindgren Liljenstolpe"
    date="2019-11-15"
    img="img/arkiv/fredrik_och_jonas-small.png"
    imgTwo="img/arkiv/erika-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Värmlandspolskor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Isa Holmgren</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Visstuga: Visor om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Dans: Stordanskväll med Jonas Hjalmarsson och Fredrik Lundberg samt
        Erika Lindgren Liljenstolpe{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          En helkväll för alla dansare – några av landets bästa dansspelmän
          spelar till dans i tre och en halv timme utan avbrott! Jonas och
          Fredrik spelar låtar från Dalarna och Värmland. Repertoaren hämtar de
          hos Gössa Anders, Ole Hjorth, Lejsme Per, Gunnar Orre, Magnus Olsson
          bland många andra. Erika förvaltar traditionen efter Viksta Lasse och
          hans brorson Bosse Larsson. I den repertoaren ingår Upplandslåtar men
          även Bingsjölåtar efter Hjort Anders. Erika spelar bl.a. i duon Erika
          och Cecilia och i grupperna Systerpolskan, Maskin och Spelstinor.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Världsmusiklinjen vid högskolan för scen och musik"
    date="2019-11-22"
    img="img/arkiv/varldsmusiklinjen-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Karins gäng
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:15&ndash;19:45</div>
      <div className="pSlotBigR">Polskekurs med Håkan och Ingela Lindberg </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">
        Dansstuga i snoa och polka, bland annat Kagge från Ragunda{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Daniel Wallenius och Emelie Mattsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Världsmusiklinjen vid högskolan för scen och musik{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Eleverna på Högskolan för scen och musik, Världsmusiklinjen, fördjupar
          sig i nationell och internationell folkmusik, men får också en inblick
          i icke västerländsk konstmusik. Spännvidden rör sig från allt mellan
          nordisk folkmusik till indisk konstmusik. Världsmusiklinjen bjuder
          denna kväll i olika konstellationer på folkmusik från hela världen. En
          svängig kväll utlovas!
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2019-11-29"
    img="img/arkiv/spel_och_dans2-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="https://hisingensspelmanslag.wordpress.com/">
            Hisingens spelmanslag
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Håkan Bengtsson och Kajsa Karlsson, därefter
          Tageltrång och sedan följer Spelmanslaget
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Lena Jonsson, Laura Cortese och Natalie Haas"
    date="2019-12-06"
    img="img/arkiv/lena_laura_och_natalie-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.naverluren.se/">Folkdanslaget Näverluren</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Schottis </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Konsert och dans: Lena Jonsson, Laura Cortese och Natalie Haas{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Laura Cortese, Natalie Haas och Lena Jonsson är alla välkända namn
          inom folkmusikvärlden, och de gör nu en unik turné i Sverige som trio.
          Dessa tre världsartister spelar vanligtvis med sina andra band (Laura
          Cortese & the Dance cards, Natalie Haas & Alasdair Fraser och Lena
          Jonsson Trio) men nu slår de sig samman i denna häftiga trio. Lauras
          kraftfulla sång och fiolspel, Natalies fantastiskt grooviga cellospel
          och Lenas slingriga fiolstämmor kommer att få lyssnaren att vilja
          dansa!
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Lom Södra</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Ymna och Mia Marin"
    date="2019-12-13"
    img="img/arkiv/ymna-small.png"
    imgTwo="img/arkiv/mia_marin-small.png"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://mellgrensblandning.se/">Mellgrens blandning</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:45&ndash;19:45</div>
      <div className="pSlotBigR">Dansstuga: Åtabakspolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;21:00</div>
      <div className="pSlotBigR">Luciakonsert med Ymna och Mia Marin </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Ymna är en kreativ och engagerad damkör som framför sin musik med en
          unik klang och lyhördhet för både tradition och samtid. Repertoaren är
          bred, men med fokus på nordisk folkmusik och på tonsättningar av
          kvinnor från tidig renässans till nutid. Körens vilja att både beröra,
          upplysa och ge musiken ett sammanhang har genom åren lett till många
          inspirerande samarbeten och angelägna projekt, som exempelvis
          skivinspelning av västsvensk julmusikt och skivinspelning med
          folkmusik av kvinnor från både arkiv och nutid. I kvällens
          luciakonsert uppträder Ymna tillsammans med Mia Marin.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">21:00&ndash;23:30</div>
      <div className="pSlotBigR">Spel till dans med Mia Marin </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Mia Marin spelade fantastisk dansmusik på vårens stordanskväll. Nu får
        vi ett nytt tillfälle att dansa till hennes spel. Mia är från Värmland,
        så vi kan se fram emot värmländska polskor av olika slag.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kråkstråk</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2018-01-19"
    img="img/arkiv/triol-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Spelgruppen Mams & Paps
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Introduktion till nybörjarkursen steg 2 i dans.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Vispolskor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lasse Jonson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Triol</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Norr om Stockholm"
    date="2018-01-26"
    img="img/arkiv/norr_om_stockholm-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Orsapolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Visor från Västsverige </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Dalbris</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Norr om Stockholm </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Hösten 2014 beslutade sig fioltrion Norr om Stockholm för att ta ett
          nytt grepp om Sveriges folkmusikskatt och två år senare släpptes deras
          debutalbum, med låtar enbart efter kvinnor. Med ett svängigt och nära
          spel, ett stort mått glädje och ett kryddmått av ilska gör Norr om
          Stockholm musiksverige lite bättre.
          <br />
          Norr om Stockholm består av: Stina Brandin - Boda kyrkby, Dalarna;
          Alva Granström - Umeå, Västerbotten; Magdalena Eriksson - Gottne,
          Ångermanland
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Lars-Gunnar Franzén och Dan Olsson</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2018-02-02"
    img="img/arkiv/hostrusk-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skjortorochsarkar.se/">
            Skjortor och Särkar
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Höstrusk och sedan följer Kråkstråk</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Skäggmanslaget"
    date="2018-02-09"
    img="img/arkiv/skaggmanslaget-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs Internationella Folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Rörospols </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar på durspel G-dur efter Skräddarn{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Torbjörn Olofsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Skäggmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        1975 gav Skäggmanslaget senast ut en skiva. Nu efter 42 år har gruppen
        kommit ut med den nya plattan ”Jämnt skägg och odödliga låtar.” Västra
        logen på Ransäters hembygdsgård var fullsmockad med folk på deras
        releasekonsert under Ransäterstämman i fjol.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Västkustsallad</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2018-02-16"
    img="img/arkiv/jp-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Polska och bakmes från Transtrand{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Malte Hermansson och Kerstin Harvenberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Klarinettlåtar från Bohuslän </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Niklas Robertsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Håkan B och Kajsa K och sedan följer HEIJK</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Triller" date="2018-02-23" img="img/arkiv/triller-small.jpg">
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Slängpolska och folklig menuett{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Jimmy Persson och Ingegärd Hägnesten. Musik: Polskebyrån</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Triller </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Triller är ett av Sveriges mest dansanta folkmusikband. Kvartetten
        spelar folkmusik på ett sällsamt fångande sätt fyllt av innerlighet, med
        en unik klangfärg, som ges av den originella sättningen klarinett,
        munspel, fiol, slagverk och gitarr.
        <br />
        Pontus Estling, Erik Ronström, Sheshti Johansson och Jens Linell är
        musiker med olika bakgrund som samlats kring sin strävan att låta den
        svenska folkmusiken andas, vara levande och utvecklas. Låtar ur gamla
        notböcker från 1700-talet har getts nytt liv igen samtidigt som egna
        kompositioner utgör en betydande del av musiken.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bagaregårdens spelmanslag</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Trio Wolski"
    date="2018-03-02"
    img="img/arkiv/wolski-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga med irländskt tema </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Clement McKay</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga med judiskt tema </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Trio Wolski </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Trio Wolski består av musikerna Hanna Blomberg, Petter Ferneman och
        Kristian Wolski, som träffats under sina studier på Musikhögskolan i
        Malmö. Musiken är en blandning av egna kompositioner och skandinavisk
        folkmusik. Med fiol, dragspel och cittern vill de skapa musik som
        ständigt tar nya former och kännetecknas av ett starkt rytmiskt driv.
        Med spelglädje, lyhördhet och upptäckarlust skapar de musik vi inte hört
        förut. I sommar släpper trion sitt första album och ni kommer vara bland
        de första som får höra smakprov från denna skiva.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Fru Salt</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll. Årsmöte"
    date="2018-03-09"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">
        Årsmöte. Agenda finns <a href="agenda_fmk_2018.pdf">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Östvärmländska låtar och visor{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Hans Grönlund</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Fredrik Persson och sedan följer kören Leila</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Maskin" date="2018-03-16" img="img/arkiv/maskin-small.jpg">
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.borasspelman.se">Borås spelmanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Polska med rörliga roller </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Erik Mägi</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Värmländska favoriter </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Karin Hed</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Maskin </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Maskin - Olov Johansson, Erika Lindgren Liljenstolpe och Robert Larsson
        har känt varandra länge och spelat mycket ihop genom åren. Debutalbumet
        Maskin släpptes dock först 2017, inspelat live för att fånga den där
        speciella känslan och närheten som bara uppstår i mötet med en publik.
        Trion enas i den gemensamma kärleken till det uppländska svänget som
        spelmännen och föregångarna Viksta-Lasse, Eric Sahlström och Curt
        Tallroth stod för. Deras främsta gemensamma förebild är Bosse Larsson,
        Roberts far och Viksta-Lasses brorson.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Mams & Paps</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2018-03-23"
    img="img/arkiv/lom_sodra-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkursen steg 2 dans (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Finnskogspols </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Peter och Marianne Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Sånger om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Kristians kapell och sedan följer{" "}
          <a href="https://www.facebook.com/Lom-S%C3%B6dra-969031713131835/">
            Lom södra
          </a>
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2018-04-06"
    img="img/arkiv/spelmansstaemma-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Spelmansstämma med buskspel i hela huset. Kvällen inleds med Allspel.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;20:00</div>
      <div className="pSlotBigR">
        Allspel i stora salen. Allspelslåtar finns{" "}
        <a href="html/allspel/allspel_vt2018.pdf">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;20:30</div>
      <div className="pSlotBigR">Visstuga: Skämtsamma och tveksamma visor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Henrik Åberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Buskspel i kaféet och i stora salen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Dans till spellista i pelarsalen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Buskspel i kaféet </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Dans till spellista i stora salen </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Ahlberg, Ek & Roswall"
    date="2018-04-13"
    img="img/arkiv/ahlberg_ek_roswall-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Gammalpolska från Föllinge </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Låtar efter Lejsme Per </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lars-Gunnar Franzén</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Ahlberg, Ek & Roswall </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Ahlberg, Ek & Roswall har etablerats som en av de främsta
        folkmusikgrupperna i Sverige, blev 2016 utsedda till Årets Grupp vid
        Folk- och Världsmusikgalan, och har rönt stora framgångar världen över.
        <br />
        Förutom kritikerrosade konserter och album har Ahlberg, Ek & Roswall
        hyllats för sitt oemotståndliga sväng till dans och flitigt anlitats som
        dansmusiker i samband med konserter, festivalframträdanden och
        workshops.
        <br />
        Emma Ahlberg Ek, femsträngad fiol; Daniel Ek, harpgitarr; Niklas
        Roswall, nyckelharpa och moraharpa
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar{" "}
          <b>Albin Broberg, Jerker Hans-Ers och Mårten Hillbom</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Lena Jonsson trio"
    date="2018-04-20"
    img="img/arkiv/lena_jonsson-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.naverluren.se/">Folkdanslaget Näverluren</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:00</div>
      <div className="pSlotBigR">
        Seminarium: Vem är Suranjana och vad är en tabla? Läs mer{" "}
        <a href="suranjana.html">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Slängpolska från övre Klarälvsdalen{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;20:00</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Lena Jonsson trio </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Lena Jonsson från Hälsingland gör solodebut med skivan Places. Under de
        senaste åren har hon turnerat över hela världen med sina band och med
        olika projekt. Musiken på Places är enbart egna kompositioner och är som
        en inblick i hennes resedagbok, där man får följa med på resor till alla
        världens hörn, filtrerat genom Lenas alldeles egna fiolspel.
        <br />
        Med på skivan och turnén har hon Erik Ronström, gitarr och Kristoffer
        Krydda Sundström, bas.
        <a href="http://www.lenajonsson.com/media/mp3/Gaspesie.mp3">
          <img src="img/ikoner/not.png" className="listen" alt="" /> Lyssna
        </a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Spelmanslaget</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Anders Lillebo trio"
    date="2018-04-27"
    img="img/arkiv/anders_lillebo-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skallsjofdl.nu/">Skallsjö Folkdanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Norskleitjen från Föllinge </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Anders Lillebo trio </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        Anders Lillebo är en av Norges främsta artister som spelar irländsk
        folkmusik. Han började sin musikaliska bana med att studera jazzpiano
        men har ägnat den senaste tiden alltmer mer åt olika slags folkmusik. År
        2017 släppte Anders sitt debutalbum Departure i samarbete med den
        berömda irländske producenten Jack Talty.
        <br />
        De övriga medlemmarna i Anders Lillebo trio är normannen Olav Christer
        Rossebø och irländaren Caoimhin Ó Fearghail. Tillsammans framför de
        irländsk traditionell folkmusik blandat med egna kompositioner och
        traditionell skandinavisk musik.
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Tageltrång</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Far och son: Pers Hans och Alexander Olsson samt Hans och Olof Kennemark"
    date="2017-09-15"
    img="img/arkiv/far_och_son-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skallsjofdl.nu/">Skallsjö Folkdanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Introduktion till nybörjarkursen. Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar från Rättvik med Jonas Päckos{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Far och son: Pers Hans och Alexander Olsson samt Hans
        och Olof Kennemark{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Far och son Kennemark i möte med far och son Olsson - det kan inte bli
          annat än grymt bra spelmansmusik! Pers Alexander och Olof Kennemark
          började spela ihop för några år sedan och efter ett tag vaknade tanken
          att även sammanföra fäderna. Och nu har det hänt!
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Triol</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Duo Jonsson Coudroy"
    date="2017-09-22"
    img="img/arkiv/duo_jonsson_coudroy-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Dansstuga: Introduktion till nybörjarkursen{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: En hälsingepolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Henriksson och Marie Hurtig. Spelman: Håkan Bengtsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Värmland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Hans Grönlund</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Duo Jonsson Coudroy </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Lena Jonsson är en skön fiolspelerska och riksspelman från Bollnäs i
          Hälsingland. Martin Coudroy är durspelsvirtuos från Bretagne i
          Frankrike. Duon har just släppt sin tredje skiva: Sur le Chemin. Här
          kommer ett smakprov från skivan:
          <a href="https://www.youtube.com/watch?v=xpLRBQaBJyQ">Vågvalsen</a>.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kristians kapell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2017-09-29"
    img="img/arkiv/spelmansstaemma-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.hisingensspelmanslag.se">Hisingens spelmanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Nybörjarkurs dans (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Spelmansstämma med buskspel i hela huset. Kvällen inleds med Allspel.
        Allspelslåtar finns{" "}
        <a href="html/allspel/allspel-ht2017-fmk.pdf">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;20:30</div>
      <div className="pSlotBigR">Visstuga: Visor från Västerdalarna </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Henrik Åberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;22:00</div>
      <div className="pSlotBigR">Buskspel i kaféet och i stora salen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;22:00</div>
      <div className="pSlotBigR">Dans till spellista i pelarsalen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Buskspel i kaféet </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Dans till spellista i stora salen </div>
      <div className="clear"></div>
    </div>
    <div className="pImgCol">
      <div className="pImg">
        <a href="img/ht17/spelmansstaemma.jpg">
          <img src="img/ht17/spelmansstaemma-small.jpg" alt="" />
        </a>
      </div>
    </div>
    <div className="clear"></div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-10-06"
    img="img/arkiv/mams_o_paps-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Spelgruppen Mams & Paps
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Värmländska favoriter </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Karin Hed</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Mams & Paps och sedan följer Höstrusk</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spelkurs med låtar från Svärdsjö, Enviken, Svartnäs och Bingsjö med Åke Wänn"
    date="2017-10-07"
  ></Concert>,
  <Concert
    title="Ellinor & Leonor"
    date="2017-10-13"
    img="img/arkiv/ellinor_och_leonor-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Vals </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Jimmy Persson och Ingegerd Hägnesten. Musik: Valsverket</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:30</div>
      <div className="pSlotBigR">Kafékonsert med Anna Johansson </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Det blir låtar från gränstrakterna mellan Värmland och Norge samt från
          Annas nya hemtrakter i Bergslagen
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Visstuga: Visor från Bohuslän, Dalsland och Värmland{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Dalbris</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Ellinor & Leonor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Med fiol och femsträngad cello tar sig riksspelmannen Ellinor Fritz
          och cellisten Leonor Palazzo an låtar från Dalarnas bergslag på ett
          nytt, dansant och stilfullt vis. De väcker liv i låtskatter såsom
          polonäser från 1800-talets Falun, gammeldans från Dala-Floda samt
          pärlor efter spelmän från Hedemora och Säter.
          <br />
          Kvällens program ingår i{" "}
          <a href="http://kulturnatta.goteborg.se/program">Kulturnatta.</a>
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Albin Ekman och Nils Blomster</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Lisa Rydberg & Lisa Eriksson Långbacka"
    date="2017-10-20"
    img="img/arkiv/Lisas_Fotograf_AnnaReet_Gillblad-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs Internationella Folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Hälsingepolskor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Henriksson och Marie Hurtig. Spelman: Håkan Bengtsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Visor om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Lisa Rydberg & Lisa Eriksson Långbacka{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          <a href="http://lisasmusik.se">Lisas</a> (Lisa Rydberg fiol och Lisa
          Eriksson Långbacka dragspel) med ny skiva bagaget i magiskt samspel
          med egna och traditionella låtar från främst Hälsingland och Dalarna.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bagaregårdens spelmanslag</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-10-27"
    img="img/arkiv/spel_och_dans-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skjortorochsarkar.se/">
            Skjortor och Särkar
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Fortsättningskurs dans (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av spelmanslaget och sedan följer Göteborgs
          spelmansförbund
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="GKN5" date="2017-11-03" img="img/arkiv/gkn5-small.jpg">
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är en del av:
          <br />
          <a href="http://planeta.se">Planeta-festivalen</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: GKN5 </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          GKN 5 (Guro Kvifte Nesheim kvintett) spelar låtar av spelmannen Salve
          Austenå från Tovdal i Åmli kommun i Norge samt egna låtar. De
          undersöker hur man kan bevara solokaraktären av Hardingfelaspelet i
          samspel. Deras sound kan beskrivas som en orkestrerad Hardingfela och
          grundtanken är att förstärka det som redan finns i låtarna.
          <br />
          Medlemmar: Guro Kvifte Nesheim − hardingfele/hardanger d’amore; Thomas
          Eriksson − cittern; Anna Malmström − Klarinett/ basklarinett; Jens
          Linell − Slagverk; Anna Gustavsson − Nyckelharpa
          <br />
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Eva Blanck och Jan Nilsson</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Världsmusiklinjen"
    date="2017-11-10"
    img="img/arkiv/vml-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Dalarna </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Dan Palmqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Världsmusiklinjen </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Världsmusiklinjen på Högskolan för scen och musik fördjupar sig i
          nationell och internationell folkmusik, men har också en inblick i
          icke västerländsk konstmusik. Det rör sig om allt från nordisk
          folkmusik till indisk konstmusik. Världsmusiklinjen bjuder denna kväll
          i olika konstellationer på folkmusik från hela världen. En svängig
          kväll utlovas!
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskurs med Orsatema med Ami Petersson Dregelid och Ellika Frisell. Dag 1."
    date="2017-11-11"
  ></Concert>,
  <Concert
    title="Spel- och danskurs med Orsatema med Ami Petersson Dregelid och Ellika Frisell. Dag 2."
    date="2017-11-12"
  ></Concert>,
  <Concert
    title="Stinnerbom, Stinnerbom & Dubé"
    date="2017-11-17"
    img="img/arkiv/stinnerbom_dube-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Bakmes </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Maud Söderstedt</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Fläckpolskor i G-dur </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Torbjörn Olofsson på durspel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Stinnerbom, Stinnerbom & Dubé{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Sophia och Magnus Stinnerbom, båda med rötterna stadigt förankrade i
          den svenska folkmusiken, och med otaliga spelningar i olika
          konstellationer bakom sig, tillsammans med den briljante kanadensiske
          kontrabasisten Sébastien Dubé. Sophia Stinnerbom − Viola d’amore,
          fiol; Magnus Stinnerbom −12-strängad och 6-strängad gitarr, mandola,
          Viola d’amore; Sébastien Dubé − 5-strängad kontra
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Ohlström, Ohlström & Widell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-11-24"
    img="img/arkiv/spel_och_dans2-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skjortorochsarkar.se/">
            Skjortor och Särkar
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små.{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Håkan Bengtsson och Kajsa Karlsson och sedan
          följer Kråkstråk
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Doggerland"
    date="2017-12-01"
    img="img/arkiv/doggerland-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Nya texter till gamla melodier </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Doggerland </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Doggerland är en engelsk-skandinavisk trio med sättningen sång,
          concertina, vevlira, gitarr och fiol och består av Richard Burgess,
          sång/concertina, Anders Ådin, gitarr/ vevlira och Jenny Gustafsson,
          fiol. Med Nordsjön som kuliss spelar trion folkmusik präglad av en
          ovanlig besättning, starka musikaliska tolkningar och smittande
          spelglädje. Mötet mellan Richards kraftfulla röst, Jennys karismatiska
          fiolspel och Anders rytmiska gitarr och vevlira hålls ihop av Richards
          underfundiga och humoristiska mellansnack.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Västkustsallad</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Mia Marin & Niklas Roswall"
    date="2017-12-08"
    img="img/arkiv/mia_och_niklas-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.borasspelman.se">Borås spelmanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Värmlandspolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Maud Söderstedt</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Visor med julankytning </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Henrik Åberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Mia Marin & Niklas Roswall{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Lyssna till två av den svenska folkmusikens främsta utövare på sina
          respektive instrument. Värmländska Mia Marin möter skånske Niklas
          Roswall, ett sprudlande och intensivt möte mellan fiol och
          nyckelharpa.
          <br />
          Duon utforskar sina instruments möjligheter och möts i ett unikt
          samspel som är både väl rotat i traditionen och framåtblickande.
          Låtarna de spelar gör sig lika väl på konsertscenen som på danslogen
          och de är båda även erfarna pedagoger.
          <br />
          Ett folkmusikaliskt möte som inte lämnar någon oberörd!
          <br />
          Mia Marin − fiol
          <br />
          Niklas Roswall − moraharpa och nyckelharpa
          <br />
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Anna Elofsson och Eva-Karin Gotting</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-01-20"
    img="img/arkiv/bokenaeset-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://http://www.skjortorochsarkar.se/">
            Skjortor och Särkar
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">
        Spelmanslaget (kursstart). Mer information finns här:{" "}
        <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Introduktion till nybörjarkursen dans (kursstart). Mer information finns
        här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spel och visstuga: Med Bokenäsets ådra </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Rörospols </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Marianne och Peter Nordqvist</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans med Bokenäsets Ådra </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Musik- och kulturföreningen Bokenäsets Ådra från Uddevalla med omnejd
          fyller 40 år och firar det genom att stå för hela dansspelet under
          denna kväll.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Jonas Hjalmarsson och Fredrik Lundberg"
    date="2017-01-27"
    img="img/arkiv/fredrik_och_jonas-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.polskedanslaget.se">Polskedanslaget</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Introduktion till nybörjarkurs i dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Låtar efter Lejsme Per </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lars-Gunnar Franzén</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Jonas Hjalmarsson och Fredrik Lundberg{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Två fioler med fokus på sväng och spel till dans. Huvudsakligen
          polskor från Värmland och Dalarna, men vals, schottis och polka ligger
          dom också varmt om hjärtat.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Jon-Olov Woxlin</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spelmansstämma"
    date="2017-02-03"
    img="img/arkiv/spelmansstaemma-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Introduktion till nybörjarkurs i dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;23:30</div>
      <div className="pSlotBigR">
        Spelmansstämma med buskspel i hela huset. Kvällen inleds med Allspel.
        Allspelslåtar finns <a href="html/allspel/allspel_fmk_vt17.pdf">här</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;20:30</div>
      <div className="pSlotBigR">Visstuga: Visor från när och fjärran </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Henrik Åberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:30&ndash;22:00</div>
      <div className="pSlotBigR">
        Buskspel i kaféet och i stora salen (till dans){" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00&ndash;23:00</div>
      <div className="pSlotBigR">Dans till spellista i pelarsalen </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Buskspel i kaféet </div>
      <div className="clear"></div>
      <div className="pSlotBigL">22:00&ndash;23:30</div>
      <div className="pSlotBigR">Dans till spellista i stora salen </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Christer och Kristina Samuelsson, Eric och Katarina Hammarström"
    date="2017-02-10"
    img="img/arkiv/hammarstrom_samuelsson-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs Internationella Folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans (kursstart) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:30</div>
      <div className="pSlotBigR">Dansstuga: Slängpolska på fläck </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Under ledning av Katarina Hammarström</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar från Bohuslän och Dalarna{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Julia Klingvall Ohlström</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Christer och Kristina Samuelsson, Eric och Katarina
        Hammarström{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Det blir mest slängpolskor från Östergötland, Småland och Skåne i ett
          under många år utvecklat spel till dans i nära samverkan med dansare.
          Christer, Kristina, Eric och Katarina är själv flitiga dansare.
          Katarina spelar cittra och de övriga fiol.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Triol</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Christer och Kristina Samuelsson, Eric och Katarina Hammarström"
    date="2017-02-10"
    img="img/arkiv/hammarstrom_samuelsson-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.balkandansarna.se">
            Göteborgs Internationella Folkdansklubb Balkandansarna
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans (kursstart) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:30&ndash;19:30</div>
      <div className="pSlotBigR">Dansstuga: Slängpolska på fläck </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Under ledning av Katarina Hammarström</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Låtar från Bohuslän och Dalarna{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Julia Klingvall Ohlström</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Christer och Kristina Samuelsson, Eric och Katarina
        Hammarström{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Det blir mest slängpolskor från Östergötland, Småland och Skåne i ett
          under många år utvecklat spel till dans i nära samverkan med dansare.
          Christer, Kristina, Eric och Katarina är själv flitiga dansare.
          Katarina spelar cittra och de övriga fiol.{" "}
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Triol</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-02-17"
    img="img/arkiv/lars-gunnar-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;18:30</div>
      <div className="pSlotBigR">Årsmöte </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Låtar från Bergslagen </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med riksspelman Lars-Henric Ohlström</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Lars-Gunnar Franzén och sedan följer Perssons
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Majornas 3:dje rote"
    date="2017-02-24"
    img="img/arkiv/majornas_3e_rote-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.naverluren.se/">Folkdanslaget Näverluren</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Visstuga: Visor från Bohuslän, Dalsland och Värmland{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Kenneth Dalbris</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Majornas 3:dje rote </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Majornas 3dje Rote har i sin nuvarande form funnits sen 1986. Namnet
          hittades på ett anslag i en portgång på Krukmakaregatan i Majorna. På
          skylten stod att kvarteret låg i ”Majornas 3dje Rote”. Alla i bandet
          bodde då i just detta kvarter och namnet var givet. På repertoiren
          finns både låtar med en klar cajunprägel, låtar i ”Dylan-anda”,
          skotska reels och låtar i vistradition. De flesta låtarna är
          komponerade av gruppens medlemmar. Göran Berg: Gitarr, Dobro, Sång,
          Lasse Gustafsson: Fiol, Durspel, Sång, Stefan Kirchhoff: Bas, Sång,
          Göran Premberg: Fiol, Sång, Thomas Sjöstrand: Slagverk, Sång
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Höstrusk</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-03-03"
    img="img/arkiv/gusten_brodin-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans (kursstart) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Polonesser och spelmansböcker </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med riksspelman Gusten Brodin</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Håkan Bengtsson och Kajsa Karlsson, sedan följer
          Gusten Brodin
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Magnus Gustafsson och Anders Svensson"
    date="2017-03-10"
    img="img/arkiv/magnus_gustafsson_och_anders_svensson-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://www.borasspelman.se">Borås spelmanslag</a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Mikael Rittri</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Magnus Gustafsson och Anders Svensson{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Magnus Gustafsson (Fiol, Lira, Säckpipa, Sång, Näverskalmeja,
          Mandolin) har skrivit en rad musikvetenskapliga och populärhistoriska
          böcker, är riksspelman och medlem i grupperna Sågskära och Höök. Hans
          forskning är bl.a. inriktad på polskans historia, medeltida ballader
          och populära melodiers vandringar. Magnus tilldelades Zonmärket i guld
          år 2015. Anders Svensson, inspirerad av sin far, började tidigt att
          spela fiol och fick ett gediget intresse för den svenska folkmusiken.
          Vid nio års ålder spelade han för första gången upp för Zornmärket. År
          1989 erhöll han Zornmärket i silver och titeln riksspelman. Anders är
          medlem i gruppen Sågskära. Han spelar företrädesvis smålandslåtar. Med
          den tekniska skickligheten hämtad från konstmusiken uttrycker han
          folkmusikens väsen.{" "}
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Bagaregårdens spelmanslag</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Spel- och danskväll"
    date="2017-03-17"
    img="img/arkiv/spel_o_dans-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          Spelgruppen Mams & Paps
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:00&ndash;19:45</div>
      <div className="pSlotBigR">
        Tillsammansgruppen: Sång- och danslekar för stora och små. Mer
        information finns här: <a href="kurser">Kurser</a>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Nybörjarkurs dans (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Karin Westerståhl och Lennart Ehn</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Spellistan inleds av Kråkstråk, sedan följer Höstrusk</p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Thuva Härdelin & Kristina Bergström"
    date="2017-03-24"
    img="img/arkiv/thuva_och_kristina-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Visor om medkänsla med känsla </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Moa Brynnel</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Thuva Härdelin & Kristina Bergström{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Historieberättande, humor och spelmansmusik på fiol och tramporgel med
          riksspelmannen Thuva Härdelin och tramporgelfantasten Kristina
          Bergström. Thuva Härdelin är folkmusiker och riksspelman med rötterna
          i Delsbo i Hälsingland. Hon kommer från en familj som spelat i många
          generationer och är fjärde generationens riksspelman. Under flera år
          hade tanken på ett helaftonsprogram med hälsingespelmannen Jon-Erik
          Halls låtar funnits i bakhuvudet, men det var först när
          tramporganissan Kristina Bergström efterlyste nya musikaliska
          utmaningar på Facebook som allt stämde. Att med tramporgeln som vapen
          ge sig på Halls magnifika fiolpolskor visade sig vara en utmaning som
          gav mersmak. Tramporgelns klangideal är ett barn av samma tid som
          Halls kompositioner och lyfter melodierna till oanade höjder.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Triol</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Väsen" date="2017-03-31" img="img/arkiv/vasen-small.jpg">
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Visstuga: Vispolskor </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Lasse Jonson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Väsen </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Olov Johansson, nyckelharpor, Mikael Marin, viola, Roger Tallroth,
          gitarr, bildar Väsen - en folkmusikensemble som lyckats sprida sin
          musik över hela världen. Tre fantastiska individuella musiker som
          hanterar sina akustiska instrument med stor känsla och kraft. På scen
          ger Väsen fritt spelrum åt fantasin och mellan låtarna bjuder de
          publiken och varandra på många skratt. Med hisnande virtuositet och
          flödande uppfinningsrikedom blir ingen Väsenkonsert den andra lik.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Eva Blanck och Jan Nilsson</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Knapparna från Boda: Britta, Karin, Brita och Maria"
    date="2017-04-07"
    img="img/arkiv/vknapparna-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotSmallL">
        <img
          src="img/ikoner/samarbete-15.png"
          className="collaboration"
          alt=""
        />{" "}
        Samarbete
      </div>
      <div className="pSlotSmallR">
        <p className="collaboration">
          Denna kväll är ett samarbete med:
          <br />
          <a href="http://gnds.org">
            GNDS &ndash; Göteborgs National Dans Sällskap
          </a>
        </p>{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">
        Fortsättningskurs dans. Denna gång kombinerad med dansstugan i
        Bodapolska{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Dansstuga: Bodapolska </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Håkan och Ingela Lindberg</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">
        Spelstuga: Det blir bland annat en fläckpolska efter "Skräddarn"{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Torbjörn Olofsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Konsert och dans: Knapparna från Boda: Britta, Karin, Brita och Maria{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Fyra spelmän med lång tradition inom Bodamusiken. Knapp Britta är född
          och uppvuxen i Boda och har spelat Bodapolskor i över 40 år. Kusinerna
          Knapp Karin, Brita och Maria är även de uppväxta med Bodalåtarna sedan
          barnsben. De har varit med i Boda spelmanslag i närmare 15 år. Alla är
          djupt förankrande i Boda-traditionen och har tillsammans utvecklat ett
          mycket intimt samspelt spel.
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen spelar <b>Kristians kapell</b>.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert title="Dagny" date="2017-04-21" img="img/arkiv/dagny-small.jpg">
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans </div>
      <div className="clear"></div>
      <div className="pSlotBigL">19:00&ndash;19:50</div>
      <div className="pSlotBigR">Spelstuga: Klarinettvänliga låtar </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>Med Niklas Robertsson</p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">Konsert och dans: Dagny </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Tre karaktärer som tillsammans svänger i sitt eget universum. Med
          gruppens unika instrumentering skapas klangvärldar där både grovt och
          grant har sin självklara plats. Krutvalser, polskor med baksug och
          drömska improvisationer ger gruppen ett eget sound som måste upplevas
          live! Sofia Andersson − saxofoner; Anna Malmström − klarinetter; Sara
          Nilsson − Fiol/viola
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallL">I pausen</div>
      <div className="pSlotSmallR">
        <p>
          I pausen trallar <b>Lei-la</b> till dans.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
  <Concert
    title="Martin Bagge och Mikaeli sångare"
    date="2017-04-28"
    img="img/arkiv/bellman-small.jpg"
  >
    <div className="pBody">
      <div className="pSlotBigL">17:30&ndash;19:15</div>
      <div className="pSlotBigR">Spelmanslaget (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">18:20&ndash;19:50</div>
      <div className="pSlotBigR">Fortsättningskurs dans (avslutning) </div>
      <div className="clear"></div>
      <div className="pSlotBigL">20:00</div>
      <div className="pSlotBigR">
        Föreställning: Martin Bagge och Mikaeli sångare{" "}
      </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          ”Är jag född så vil jag lefva / En dag på Bellmans tid” är en
          föreställning om Bellman och hans kumpaner från stor glädje till djup
          förtvivlan. Bellmans texter och musik tecknar ett levande porträtt av
          1700-talets Sverige. Martin Bagge är en välkänd trubadur och en av vår
          tids främsta Bellmantolkare. Mikaeli sångare är en kör som har ägnat
          mycket tid åt tidig musik. Sångerna är stiltroget arrangerade av
          körens ledare Mikael Paulsson.
          <br />
          <br />
          OBS! Till denna föreställning går det att förköpa biljetter. Pris
          150:- plus expeditionsavgift. Har man förköpt biljett behövs inget
          medlemskap.
          <br />
          <a href="https://billetto.se/en/e/ar-jag-fodd-sa-vil-jag-lefva-tickets-185443">
            Förköp
          </a>
        </p>
      </div>
      <div className="clear"></div>
      <div className="pSlotBigL">21:30&ndash;23:30</div>
      <div className="pSlotBigR">Dans till spellista </div>
      <div className="clear"></div>
      <div className="pSlotSmallR">
        <p>
          Spellistan inleds av Västkustsallad. Sedan följer Bellmansorkestern.
        </p>
      </div>
      <div className="clear"></div>
    </div>
  </Concert>,
];

class Arkiv extends Component {
  styles = {
    fontSize: 15,
    fontWeight: "bold",
  };

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      isFetching: false,
      latest: null,
    };
  }

  getArchive() {
    return ArkivKonserter;
  }

  sortArchive() {
    ArkivKonserter.sort((a, b) => {
      let dateDiff = a.props.date.localeCompare(b.props.date);
      if (dateDiff < 0) {
        return 1;
      } else if (dateDiff > 0) {
        return -1;
      }
      return 0;
    });
  }

  render() {
    this.sortArchive();

    let items = [];
    for (let i = 0; i < ArkivKonserter.length; i++) {
      let concert = new Concert(ArkivKonserter[i].props);
      items.push(concert.getArchiveOverview());
    }

    return (
      <center>
        {openingSection}
        <table className="programArchiveOverView">
          <tbody>{items}</tbody>
        </table>
      </center>
    );
  }
}

export default Arkiv;
